const types = {
  QUIZ_FETCH_DETAILS: 'QUIZ_FETCH_DETAILS',
  QUIZ_FETCH_ATTEMPTS: 'QUIZ_FETCH_ATTEMPTS',
  QUIZ_ENROLL: 'QUIZ_ENROLL',
  QUIZ_FETCH_SUBMITTED_QUESTIONS: 'QUIZ_FETCH_SUBMITTED_QUESTIONS',
  QUIZ_FETCH_QUESTIONS_WITH_ATTEMPTS: 'QUIZ_FETCH_QUESTIONS_WITH_ATTEMPTS',
  QUIZ_FETCH_STATIC_QUESTIONS: 'QUIZ_FETCH_STATIC_QUESTIONS',
  QUIZ_MODAL_TYPE: 'QUIZ_MODAL_TYPE',
  QUIZ_FETCH_QUESTION_REPORT_OPTIONS: 'QUIZ_FETCH_QUESTION_REPORT_OPTIONS',
  QUIZ_MARK_QUESTION: 'QUIZ_MARK_QUESTION',
  QUIZ_SUBMIT_QUESTION_RESPONSE: 'QUIZ_SUBMIT_QUESTION_RESPONSE',
  QUIZ_SEND_VIEWED_QUESTION: 'QUIZ_SEND_VIEWED_QUESTION',
  QUIZ_FINISH_SECTION: 'QUIZ_FINISH_SECTION',
  QUIZ_FINISH_QUIZ: 'QUIZ_FINISH_QUIZ',
  QUIZ_SUBMIT_BULK_ANSWERS: 'QUIZ_SUBMIT_BULK_ANSWERS',
  QUIZ_CLEAN_UP_AFTER_QUIZ_END: 'QUIZ_CLEAN_UP_AFTER_QUIZ_END',
  QUIZ_SET_CURRENT_QUESTION_TYPE: 'QUIZ_SET_CURRENT_QUESTION_TYPE',
  QUIZ_SET_CURRENT_SELECTED_ANSWER: 'QUIZ_SET_CURRENT_SELECTED_ANSWER',
  QUIZ_SET_SELECTED_LANGUAGE: 'QUIZ_SET_SELECTED_LANGUAGE',
  QUIZ_FETCH_SESSION_DETAILS: 'QUIZ_FETCH_SESSION_DETAILS',
  QUIZ_FETCH_SOLUTION_FILTER: 'QUIZ_FETCH_SOLUTION_FILTER',
  QUIZ_FETCH_PAGINATED_SOLUTIONS: 'QUIZ_FETCH_PAGINATED_SOLUTIONS',
  QUIZ_FETCH_SOLUTION_FEEDBACK_OPTIONS: 'QUIZ_FETCH_SOLUTION_FEEDBACK_OPTIONS',
  QUIZ_FETCH_VIDEO_SOLUTION_FEEDBACK_OPTIONS:
    'QUIZ_FETCH_VIDEO_SOLUTION_FEEDBACK_OPTIONS',
  QUIZ_SUBMIT_VIDEO_SOLUTION_FEEDBACK: 'QUIZ_SUBMIT_VIDEO_SOLUTION_FEEDBACK',
  QUIZ_FETCH_ANALYTICS_REPORT: 'QUIZ_FETCH_ANALYTICS_REPORT',
  QUIZ_FETCH_COMMON_REPORT: 'QUIZ_FETCH_COMMON_REPORT',
  QUIZ_FETCH_USER_RANK: 'QUIZ_FETCH_USER_RANK',
  QUIZ_FETCH_EVALUATED_SHEET: 'QUIZ_FETCH_EVALUATED_SHEET',
  QUIZ_FETCH_FEEDBACK_OPTIONS: 'QUIZ_FETCH_FEEDBACK_OPTIONS',
  QUIZ_REJECT_SUBJECTIVE_ANSWER_UPLOAD: 'QUIZ_REJECT_SUBJECTIVE_ANSWER_UPLOAD',
  QUIZ_UPLOAD_SUBJECTIVE_ANSWER: 'QUIZ_UPLOAD_SUBJECTIVE_ANSWER',
  QUIZ_FETCH_LEADERBOARD_ALL: 'QUIZ_FETCH_LEADERBOARD_ALL',
  QUIZ_FETCH_LEADERBOARD_LIVE: 'QUIZ_FETCH_LEADERBOARD_LIVE',
  QUIZ_LOADING: 'QUIZ_LOADING'
};

export default types;
