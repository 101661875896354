import mergeDeep from '@utils/mergeDeep';
import { batchGroupTypes } from '../constants';

const initialState = {
  data: {},
  batchDetails: {},
  trialClasses: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case batchGroupTypes.UPDATE_BATCHGROUPS:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };
    case `${batchGroupTypes.FETCH_BATCHGROUPS_INFO}_SUCCESS`: {
      return {
        ...state,
        batchDetails: {
          ...state.batchDetails,
          [action.key]: action.result
        }
      };
    }
    case `${batchGroupTypes.FETCH_BATCHGROUPS_TRIAL_CLASSES}_SUCCESS`:
      return {
        ...state,
        trialClasses: {
          ...state.trialClasses,
          [action.key]: action.result
        }
      };
    default:
      return state;
  }
};

export default reducer;
