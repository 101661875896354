const typeHrefMap = {
  welcome: '',
  testresultsready: '/quiz/[quizSlug]/[quizUID]',
  newcoursesthisweek: '/goal/[goalSlug]/[goalUID]/[type]',
  courserecommendation: '/goal/[goalSlug]/[goalUID]/onboarding',
  dailydigest: '/',
  freetrialclassrecommendation: '/course/[slug]/[courseUID] ',
  liveclassreminder: '/course/[slug]/[courseUID] ',
  quizreminder: '/quiz/[quizSlug]/[quizID]',
  testreminder: '/quiz/[quizSlug]/[quizID]',
  pricehike: ''
};

const getHref = (type) => {
  return typeHrefMap[type] || '';
};

const notificationHelper = {
  getHref
};

export default notificationHelper;
