import merge from 'lodash/merge';
import mergeDeep from '@utils/mergeDeep';
import {
  educatorTypes,
  searchTypes,
  authTypes,
  moderatorTypes,
  subscriptionTypes
} from '../constants';

const initialState = {
  data: {},
  profileInfo: {},
  profileStats: {},
  educatorProfileFilters: {},
  popularSearches: [],
  recentSearches: {},
  searchAutoComplete: [],
  learnerMilestones: {},
  subscriptionFeedbackCards: []
};

export const emptyRecentSearch = {
  isFetching: false,
  results: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case educatorTypes.UPDATE_USERS:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    case `${educatorTypes.FETCH_PROFILE_INFO}_SUCCESS`:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          [action.username]: action.result
        }
      };

    case `${educatorTypes.FETCH_PROFILE_STATS}_SUCCESS`:
      return {
        ...state,
        profileStats: {
          ...state.profileStats,
          [action.username]: action.result
        }
      };

    case `${educatorTypes.FETCH_FILTERED_COURSES}_SUCCESS`:
      return {
        ...state,
        educatorProfileFilters: {
          ...state.educatorProfileFilters,
          [action.filterKey]: {
            filters: action.result?.filters,
            sorts: action.result?.sorts
          }
        }
      };

    case `${educatorTypes.TOGGLE_FOLLOWING_STATUS}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.username]: merge(state.data[action.username], {
            isFollowing: action.follow
          })
        }
      };

    case `${searchTypes.FETCH_POPULAR_SEARCHES}_SUCCESS`:
      return {
        ...state,
        popularSearches: action.result
      };

    case `${searchTypes.FETCH_AUTOCOMPLETE}_SUCCESS`:
      return {
        ...state,
        searchAutoComplete: action.result
      };

    case `${educatorTypes.FETCH_LEARNER_MILESTONES}_SUCCESS`:
      return {
        ...state,
        learnerMilestones: {
          ...state.learnerMilestones,
          [action.username]: action.result
        }
      };

    case `${authTypes.FETCH_FEEDBACK_OPTIONS}_SUCCESS`:
      return {
        ...state,
        feedbackOptions: action.result
      };

    case `${moderatorTypes.EDIT_ACCESS}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.username]: {
            ...state.data[action.username],
            [action.key]: action.grant
          }
        }
      };

    case `${subscriptionTypes.FETCH_SUBSCRIPTION_FEEBACK_CARDS}_REQUEST`:
      return {
        ...state,
        subscriptionFeedbackCards: []
      };

    case `${subscriptionTypes.FETCH_SUBSCRIPTION_FEEBACK_CARDS}_SUCCESS`:
      return {
        ...state,
        subscriptionFeedbackCards: action.result?.data
      };

    default:
      return { ...state };
  }
};

export default reducer;
