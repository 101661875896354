/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-lines */
import { PIP_ERROR_CODE } from '@api/hooks/payments/usePartPaymentStatus';
import {
  staticNoCostEmiData,
  staticCheckoutCarousels,
  staticIconicCarousels,
  staticLiteCarousels
} from '@cont/Checkout/constants/carousels';
import { subscriptionTypes } from '../constants';

const initialState = {
  pricings: {},
  discountedPricings: {},
  discountedSubscription: {},
  referralError: null,
  emiPlans: null,
  order: null,
  paymentOngoing: false,
  paymentSuccess: false,
  paymentFailed: false,
  paymentError: null,
  purchaseSubscriptionResponse: null,
  paymentStatusResponse: null,
  purchasedSubscription: {},
  orderId: null,
  externalAppData: null,
  partPaymentStatus: null,
  bundleAddOns: {},
  bundleAddOnsWithRef: {},
  fetchingBundleAddOns: false,
  offerList: {},
  offerFaqs: [],
  bundleAddOnsRefError: null,
  externalAppActivatedData: null,
  bundleDetails: {},
  comboFeatures: null
};

const reducerMap = {
  [`${subscriptionTypes.FETCH_OFFER_LIST}_SUCCESS`]: (
    state,
    { result, uid }
  ) => {
    return {
      ...state,
      offerList: {
        ...state.offerList,
        [uid]: result
      }
    };
  },
  [`${subscriptionTypes.FETCH_OFFER_FAQS}_SUCCESS`]: (state, { result }) => {
    return {
      ...state,
      offerFaqs: result.faqs
    };
  },
  [`${subscriptionTypes.FETCH_CASH_PAYMENT_DATA}_SUCCESS`]: (state, action) => {
    const clashDetails =
      action.result.message && action.result.clash_details
        ? { clash_details: action.result.clash_details }
        : null;
    return {
      ...state,
      cashResponse: action.result.message
        ? clashDetails
        : {
            ...action.result,
            subscription_details: {
              ...action.result.subscription_details,
              value: action.result.goal
            }
          }
    };
  },
  [`${subscriptionTypes.ENROLL_ORDER}_REQUEST`]: (state) => {
    return {
      ...state,
      creatingOrder: true
    };
  },
  [`${subscriptionTypes.ENROLL_ORDER}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      order: action.result,
      creatingOrder: false,
      cashResponse: action.isCashPayment
        ? {
            ...action.result,
            subscription_details: {
              ...action.result.subscription_details,
              value: action.result.goal
            }
          }
        : null
    };
  },
  [`${subscriptionTypes.ENROLL_ORDER}_FAILURE`]: (state) => {
    return {
      ...state,
      creatingOrder: false
    };
  },
  [`${subscriptionTypes.ENROLL_INSTALLMENT_ORDER}_REQUEST`]: (state) => {
    return {
      ...state,
      creatingOrder: true
    };
  },
  [`${subscriptionTypes.ENROLL_INSTALLMENT_ORDER}_SUCCESS`]: (
    state,
    action
  ) => {
    return {
      ...state,
      creatingOrder: false,
      order: action.result
    };
  },
  [`${subscriptionTypes.ENROLL_INSTALLMENT_ORDER}_FAILURE`]: (state) => {
    return {
      ...state,
      creatingOrder: false
    };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${subscriptionTypes.FETCH_PRICINGS}_SUCCESS`:
      return {
        ...state,
        pricings: {
          ...state.pricings,
          [action.uid]: action.result
        }
      };

    case `${subscriptionTypes.VALIDATE_REFERRAL}_REQUEST`:
      return {
        ...state,
        referralError: null
      };

    case `${subscriptionTypes.VALIDATE_REFERRAL}_SUCCESS`:
      return {
        ...state,
        referralError: null,
        referralSuccessful: true,
        referralCodeApplied: action.referral,
        discountedPricings: {
          ...state.discountedPricings,
          [action.uid]: action.result
        }
      };

    case `${subscriptionTypes.VALIDATE_REFERRAL}_FAILURE`:
      return {
        ...state,
        referralError: action.error,
        discountedPricings: {
          ...state.discountedPricings,
          [action.uid]: null
        }
      };

    case `${subscriptionTypes.VALIDATE_REFERRAL_SUBSCRIPTION}_REQUEST`:
      return {
        ...state,
        referralError: null
      };

    case `${subscriptionTypes.VALIDATE_REFERRAL_SUBSCRIPTION}_SUCCESS`:
      return {
        ...state,
        referralError: null,
        referralSuccessful: true,
        referralCodeApplied: action.referral,
        discountedSubscription: {
          ...state.discountedSubscription,
          [action.uid]: action.result
        }
      };

    case `${subscriptionTypes.VALIDATE_REFERRAL_SUBSCRIPTION}_FAILURE`:
      return {
        ...state,
        referralError: action.error,
        discountedSubscription: {
          ...state.discountedSubscription,
          [action.uid]: null
        }
      };

    case `${subscriptionTypes.FETCH_EMI_PLANS}_SUCCESS`:
      return {
        ...state,
        emiPlans: action.result
      };

    case `${subscriptionTypes.FETCH_PART_PAYMENT_STATUS}_SUCCESS`:
      return {
        ...state,
        partPaymentStatus: {
          ...state.partPaymentStatus,
          [action.key]: action.result
        }
      };

    case `${subscriptionTypes.FETCH_PART_PAYMENT_STATUS}_FAILURE`:
      if (action.result?.error_code === PIP_ERROR_CODE) {
        return {
          ...state,
          partPaymentStatus: {
            ...state.partPaymentStatus,
            [action.key]: action.result
          }
        };
      }
      return state;

    case `${subscriptionTypes.PURCHASE_SUBSCRIPTION}_SUCCESS`:
      return {
        ...state,
        purchaseSubscriptionResponse: action.result
      };

    case `${subscriptionTypes.PAYMENT_STATUS_JUSPAY}_SUCCESS`:
      return {
        ...state,
        paymentStatusResponse: action.result
      };

    case subscriptionTypes.SAVE_PURCHASED_SUBSCRIPTION:
      return {
        ...state,
        purchasedSubscription: {
          ...state.purchasedSubscription,
          [action.goalOrComboId]: action.purchasedSubscription
        },
        orderId: action.orderId
      };

    case subscriptionTypes.CLEAR_ORDER:
      return {
        ...state,
        order: null
      };

    case `${subscriptionTypes.ACTIVATE_EXTERNAL_APP}_SUCCESS`:
      return {
        ...state,
        externalAppData: action.result
      };

    case `${subscriptionTypes.FETCH_BUNDLE_DETAILS}_SUCCESS`:
      return {
        ...state,
        bundleDetails: {
          ...state.bundleDetails,
          [action.uid]: action.result
        }
      };

    case `${subscriptionTypes.STOP_PART_PAYMENT_NUDGE}_SUCCESS`:
      return {
        ...state
      };

    case `${subscriptionTypes.SYNC_CODE_CHEF}_SUCCESS`:
      return {
        ...state,
        externalAppActivatedData: {
          ...action.result,
          user_subscription: {
            ...action.result.subscription,
            external_app_subscriptions: [
              {
                external_app: action.result.external_app
              }
            ]
          }
        }
      };

    case `${subscriptionTypes.FETCH_SUBSCRIPTION_STATIC_DATA}_SUCCESS`:
      return {
        ...state,
        staticContent: {
          [action.key]: { liteCarousels: staticLiteCarousels, ...action.result }
        }
      };

    case `${subscriptionTypes.FETCH_SUBSCRIPTION_STATIC_DATA}_FAILURE`:
      return {
        ...state,
        staticContent: {
          [action.key]: {
            carousels: staticCheckoutCarousels,
            iconicCarousels: staticIconicCarousels,
            liteCarousels: staticLiteCarousels,
            noCostEmi: staticNoCostEmiData
          }
        }
      };

    case 'SET_EXTERNAL_SUBSCRIPTION_DATA':
      return {
        ...state,
        purchaseSubscriptionResponse: {
          ...state.purchaseSubscriptionResponse,
          user_subscription: {
            external_app_subscriptions: [action.subscriptionData]
          }
        }
      };

    case `${subscriptionTypes.FETCH_COMBO_FEATURE_POINTS}_SUCCESS`:
      return {
        ...state,
        comboFeatures: action.result
      };

    case `${subscriptionTypes.FETCH_BATCH_GROUP_SUBSCRIPTION_INFO}_SUCCESS`:
      return {
        ...state,
        batchGroupSubscription: action.result
      };

    default: {
      const actionHandler = reducerMap[action.type];
      return actionHandler ? actionHandler(state, action) : state;
    }
  }
};

export default reducer;
