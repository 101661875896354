import { otherTypes, moderatorTypes } from '../constants';

const initialState = {
  faqs: [],
  isFaqsFetching: false,
  snackBarContent: null,
  legendsBanner: null,
  postData: null,
  s3SigninURL: [],
  sitemapData: null,
  iconicSubscriptionInfo: null,
  subscriptionFeatures: null,
  subscriptionHighlights: null,
  oneCarouselFeed: null,
  rootPageData: {},
  priceHikeInfo: [],
  constants: null,
  goalDisplayNames: {},
  nextPreviewMode: false,
  isScheduleDataForDppFetched: false,
  initialPropsRun: false
};

const others = (state = initialState, action) => {
  switch (action.type) {
    case `${otherTypes.FETCH_FAQS}_REQUEST`: {
      return {
        ...state,
        isFaqsFetching: true
      };
    }

    case `${otherTypes.FETCH_FAQS}_SUCCESS`: {
      return {
        ...state,
        faqs: action.result,
        isFaqsFetching: true
      };
    }

    case `${otherTypes.FETCH_FAQS}_FAILURE`: {
      return {
        ...state,
        isFaqsFetching: false
      };
    }

    case `${otherTypes.FETCH_S3_SIGNIN_URL}_SUCCESS`: {
      return {
        ...state,
        s3SigninURL: [...state.s3SigninURL, action.result]
      };
    }

    case `${otherTypes.FETCH_LEGENDS_BANNER}_SUCCESS`:
      return {
        ...state,
        legendsBanner: action.result
      };

    case `${moderatorTypes.FETCH_POST}_SUCCESS`:
      return { ...state, postData: action.result };

    case `${otherTypes.FETCH_SITEMAP}_SUCCESS`:
      return {
        ...state,
        sitemapData: action.result
      };

    case `${otherTypes.FETCH_ICONIC_SUBSCRIPTION_INFO}_SUCCESS`:
      return {
        ...state,
        iconicSubscriptionInfo: {
          ...state.iconicSubscriptionInfo,
          [action.iconicKey]: action.result
        }
      };

    case `${otherTypes.FETCH_SUBSCRIPTION_FEATURES}_SUCCESS`:
      return {
        ...state,
        subscriptionFeatures: action.result
      };

    case `${otherTypes.FETCH_SUBSCRIPTION_HIGHLIGHTS}_SUCCESS`:
      return {
        ...state,
        subscriptionHighlights: action.result
      };

    case `${otherTypes.FETCH_PRICE_HIKE_INFO}_SUCCESS`:
      return {
        ...state,
        priceHikeInfo: action.result
      };

    case `${otherTypes.FETCH_SUBSCRIPTION_FAQS}_SUCCESS`:
      return {
        ...state,
        iconicFAQs: action.result
      };
    case `${otherTypes.FETCH_ONE_CAROUSEL_FEED}_SUCCESS`:
      return {
        ...state,
        oneCarouselFeed: action.result
      };

    case `${otherTypes.FETCH_ROOT_PAGE_DATA}_SUCCESS`:
      return {
        ...state,
        rootPageData: action.result || {}
      };

    case `${otherTypes.FETCH_GOAL_DISPLAY_NAMES}_SUCCESS`:
      return {
        ...state,
        goalDisplayNames: action.result || {}
      };

    case `${otherTypes.SET_PREVIEW_MODE}`:
      return {
        ...state,
        nextPreviewMode: true
      };

    case `${otherTypes.FETCH_PRODIGY_DATA}_SUCCESS`:
      return {
        ...state,
        prodigyResults: action.result
      };

    case `${otherTypes.VERIFY_PRODIGY_DETAILS}_SUCCESS`:
      return {
        ...state,
        extraDetailsPresent: action.result
      };

    case `${otherTypes.SET_SAVED_COURSE}`: {
      let newEvents = state.prodigyResults.events;
      newEvents = newEvents.map((event) => {
        const updatedEvent = event;
        if (event.testSeriesUID === action.eventUID) {
          updatedEvent.isEnrolled = true;
        }
        return updatedEvent;
      });
      return {
        ...state,
        prodigyResults: {
          ...state.prodigyResults,
          events: newEvents
        }
      };
    }

    case `${otherTypes.SET_FETCH_STATE_OF_SCHEDULE_DATA_FOR_DPP}`:
      return {
        ...state,
        isScheduleDataForDppFetched: action.data
      };

    case `${otherTypes.SET_INITIAL_PROPS_RUN}`:
      return { ...state, initialPropsRun: true };

    default: {
      return state;
    }
  }
};

export default others;
