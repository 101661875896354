/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import merge from 'lodash/merge';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';

// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.

export default function paginate({
  updateReducer,
  actionType,
  removeDuplicates
}) {
  const types = [
    `${actionType}_REQUEST`,
    `${actionType}_SUCCESS`,
    `${actionType}_FAILURE`,
    'null',
    `${actionType}_RESET`
  ];
  const [requestType, successType, failureType, reset, resetType] = types;
  const initialState = {
    loaded: false,
    isFetching: false,
    next: undefined,
    total: 0,
    results: [],
    resetWithoutEmpty: false
  };
  function updatePagination(state = initialState, action) {
    const stateResults = state ? state.results : [];
    switch (action.type) {
      case requestType:
        return merge({}, state, {
          isFetching: true,
          results: action.resetWithoutEmpty ? [] : stateResults
        });
      case successType:
        let results = action.result
          ? action.result.results
            ? union(stateResults, action.result.results)
            : union(stateResults, [action.result])
          : stateResults;

        if (action.resetWithoutEmpty) results = action.result.results;
        if (removeDuplicates?.uniqKey) {
          results = uniqBy(results, removeDuplicates.uniqKey);
        }
        return merge({}, action.resetWithoutEmpty ? {} : state, {
          loaded: true,
          isFetching: false,
          results,
          next: action.result.next,
          total: action.result.count,
          resetWithoutEmpty: !!action.resetWithoutEmpty
        });
      case failureType:
        return merge({}, state, {
          isFetching: false
        });
      default:
        return state;
    }
  }

  return function updatePaginationByKey(state = {}, action = {}) {
    const { key } = action;
    switch (action.type) {
      case requestType:
      case successType:
      case failureType:
        if (action.resetWithoutEmpty) {
          return {
            ...state,
            [key]: {
              ...updatePagination(state[key], action),
              key
            }
          };
        }
        return merge({}, state, {
          [key]: {
            ...updatePagination(state[key], action),
            key
          }
        });
      case reset:
      case resetType:
        return {
          ...state,
          [key]: initialState
        };

      default:
        const _state = state;
        const _keyState = _state[key];
        if (updateReducer && _keyState) {
          const s = updateReducer(_keyState, action, initialState);
          _state[key] = s;
          return {
            ...state,
            [key]: s
          };
        }
        return state;
    }
  };
}
