import { careersTypes } from '../constants';

const initialState = {
  loading: true,
  error: false,
  data: ''
};

const careers = (state = initialState, action) => {
  switch (action.type) {
    case `${careersTypes.GET_CMS_DATA}_SUCCESS`:
      return {
        ...state,
        data: action.result,
        loading: false
      };
    case `${careersTypes.GET_JOB_DETAILS}_FAILURE`:
      return {
        ...state,
        error: true
      };
    default:
      return state;
  }
};

export default careers;
