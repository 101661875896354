/* eslint-disable max-lines */
import isEmpty from 'lodash/isEmpty';
import { DIFFICULTY_LEVEL_MAP, QUESTION_TYPE_MAP } from '@constants/quiz';
import { pluralize } from '@utils/textHelper';
import { QUESTION_COUNT_FILTER_KEY, STEPS_MAP } from './constants';
import { DIFFICULTY_CODES } from '../constants';

export const getErrorMessage = (
  totalQuestionRequired,
  totalQuestionsSelected,
  type
) => {
  const totalQuestionsNeededMore =
    totalQuestionRequired - totalQuestionsSelected;
  const totalQuestionsNeededLess =
    totalQuestionsSelected - totalQuestionRequired;

  if (totalQuestionsSelected > totalQuestionRequired) {
    if (type === 'section') {
      return `You need ${totalQuestionsNeededLess} less
      ${pluralize(
        totalQuestionsNeededLess,
        'question',
        's',
        true
      )} in this section`;
    }
    if (type === 'test') {
      return `Reduce ${totalQuestionsNeededLess}
        ${pluralize(
          totalQuestionsNeededLess,
          'question',
          's',
          true
        )} to proceed`;
    }
  }
  if (type === 'section') {
    return `You need ${totalQuestionsNeededMore} more
      ${pluralize(
        totalQuestionsNeededMore,
        'question',
        's',
        true
      )} in this section`;
  }
  return `Add  ${totalQuestionsNeededMore} more ${pluralize(
    totalQuestionsNeededMore,
    'question',
    's',
    true
  )} to proceed`;
};

export const getTotalQuestionsEnteredInSelectedConceptsForATopic = (
  selectedConcepts,
  topicUID
) => {
  return Object.keys(selectedConcepts[topicUID] ?? {})?.reduce(
    (acc, conceptUID) =>
      acc + (selectedConcepts[topicUID][conceptUID]?.totalQuestions ?? 0),
    0
  );
};

const checkCountMismatchInSelectedConceptsOfATopic = (config = {}) => {
  const errorMessages = {};

  Object.keys(config).forEach((configRank) => {
    const { selectedConcepts = {}, selectedTopics = {} } = config[configRank];

    const sumOfTotalQuestionsOfSelectedConceptsForATopic = Object.keys(
      selectedConcepts
    ).reduce((acc, topicUID) => {
      acc[topicUID] = getTotalQuestionsEnteredInSelectedConceptsForATopic(
        selectedConcepts,
        topicUID
      );
      return acc;
    }, {});

    Object.keys(selectedTopics).forEach((syllabusUID) => {
      Object.keys(selectedTopics[syllabusUID] ?? {}).forEach((topicUID) => {
        if (
          selectedTopics[syllabusUID][topicUID]?.totalQuestions !==
            sumOfTotalQuestionsOfSelectedConceptsForATopic[topicUID] &&
          !isEmpty(selectedConcepts[topicUID])
        ) {
          errorMessages[topicUID] = true;
        }
      });
    });
  });

  if (Object.keys(errorMessages).length > 0) {
    errorMessages.total = {
      message: 'Question count mismatch. Please recheck your concept selection'
    };
  }

  if (Object.keys(errorMessages).length === 0) {
    return { error: false };
  }

  return { error: true, message: errorMessages };
};

const checkCountMismatchInSelectedSubConceptsOfAConcept = ({
  selectedConceptsForATopic,
  config,
  currentSelectedTopic
}) => {
  const { rank } = currentSelectedTopic;
  const errorMessages = {};

  Object.keys(selectedConceptsForATopic).forEach((conceptUID) => {
    const totalQuestionSelectedForAConcept =
      selectedConceptsForATopic[conceptUID].totalQuestions;

    const selectedSubConceptsForAConcept =
      config[rank].selectedSubConcepts[conceptUID];

    if (Object.keys(selectedSubConceptsForAConcept ?? {}).length > 0) {
      const totalQuestionSelectedForAllSubConceptsInAConcept =
        Object.keys(selectedSubConceptsForAConcept).reduce(
          (acc, subConceptUID) => {
            const totalQuestionSelectedForOneSubConcept =
              selectedSubConceptsForAConcept[subConceptUID].totalQuestions;
            return acc + totalQuestionSelectedForOneSubConcept;
          },
          0
        ) ?? 0;

      if (
        totalQuestionSelectedForAllSubConceptsInAConcept !==
        totalQuestionSelectedForAConcept
      ) {
        errorMessages[conceptUID] = true;
      }
    }
  });
  if (Object.keys(errorMessages).length > 0) {
    errorMessages.total = {
      message:
        'Question count mismatch. Please recheck your sub-concept selection'
    };
    return { error: true, message: errorMessages };
  }

  return { error: false };
};

const checkCountMismatchInSyllabus = (config = {}) => {
  const errorMessages = {};
  let totalQuestionsSelectedForATest = 0;
  let totalQuestionsAllowedForATest = 0;

  Object.keys(config).forEach((configRank) => {
    const { syllabus, totalQuestions: totalAllowedQuestionsForSection } =
      config[configRank];

    totalQuestionsAllowedForATest += totalAllowedQuestionsForSection;

    const totalQuestionCounSelectedtForSection = Object.keys(
      syllabus ?? {}
    ).reduce(
      (acc, syllabusUID) => acc + (syllabus[syllabusUID].totalQuestions ?? 0),
      0
    );
    totalQuestionsSelectedForATest += totalQuestionCounSelectedtForSection;

    if (
      totalAllowedQuestionsForSection !== totalQuestionCounSelectedtForSection
    ) {
      errorMessages[configRank] = {
        message: getErrorMessage(
          totalAllowedQuestionsForSection,
          totalQuestionCounSelectedtForSection,
          'section'
        )
      };
    }
  });
  if (totalQuestionsSelectedForATest !== totalQuestionsAllowedForATest) {
    errorMessages.total = {
      message: getErrorMessage(
        totalQuestionsAllowedForATest,
        totalQuestionsSelectedForATest,
        'test'
      )
    };
  }

  if (Object.keys(errorMessages).length === 0) {
    return { error: false };
  }
  return { error: true, message: errorMessages };
};

const checkCountMismatchInTopic = ({
  selectedConceptsForATopic,
  currentSelectedTopic,
  config
}) => {
  const { syllabusUID, topicUID, rank } = currentSelectedTopic;
  const totalQuestionAllowedForATopic =
    config[rank].selectedTopics[syllabusUID][topicUID]?.totalQuestions;

  const totalQuestionsSelected = Object.keys(selectedConceptsForATopic).reduce(
    (acc, conceptUID) =>
      acc + selectedConceptsForATopic[conceptUID]?.totalQuestions,
    0
  );

  if (totalQuestionsSelected !== totalQuestionAllowedForATopic) {
    return {
      error: true,
      message: `Total questions don't add upto ${totalQuestionAllowedForATopic} yet`
    };
  }
  return {
    error: false
  };
};
export const CONFIG_VALIDATOR_MAP = {
  [STEPS_MAP.QUESTION_TYPE]: (config = {}, quizDetails) => {
    const hasDefaultSection =
      quizDetails.sections.length === 1 && quizDetails.sections[0].is_default;

    const configRanks = Object.keys(config);
    const configLength = configRanks.length;

    for (let i = 0; i < configLength; i += 1) {
      const currentRank = configRanks[i];
      const currentConfig = config[currentRank] ?? {};
      const currentSection = quizDetails.sections[i];

      const questionConfigs = currentConfig?.questionConfigs ?? [];
      const hasMaxAnswerable =
        currentSection.max_answerable_question !== 0 &&
        currentSection.max_answerable_question <
          currentSection.max_question_count;

      if (hasMaxAnswerable && questionConfigs.length > 1) {
        const { positiveMarks } = questionConfigs[0];
        const { negativeMarks } = questionConfigs[0];
        const allMCQConfigs = questionConfigs.filter(
          (questionConfig) =>
            questionConfig.questionType === QUESTION_TYPE_MAP.MCQ
        );

        const allSameMarks = questionConfigs.every(
          (questionConfig) =>
            questionConfig.positiveMarks === positiveMarks &&
            questionConfig.negativeMarks === negativeMarks
        );

        const { partialMarkingMultiplier } = allMCQConfigs?.[0] ?? {};

        const allSamePartialMarks = allMCQConfigs.every(
          (questionConfig) =>
            questionConfig.partialMarkingMultiplier === partialMarkingMultiplier
        );

        if (!allSameMarks || !allSamePartialMarks) {
          return {
            error: true,
            message: hasDefaultSection
              ? 'All question should have same config'
              : `Section ${i + 1} should have same config`
          };
        }
      }

      if (!questionConfigs.length) {
        return {
          error: true,
          message: hasDefaultSection
            ? 'Requires atleast one question type'
            : `Section ${i + 1} requires atleast one question type`
        };
      }

      const totalConfigQuestion = questionConfigs.reduce(
        (sum, questionConfig) => sum + questionConfig?.totalQuestions ?? 0,
        0
      );

      if (totalConfigQuestion !== currentConfig.totalQuestions) {
        return {
          error: true,
          message: hasDefaultSection
            ? 'Question count mismatch'
            : `Section ${i + 1} can have ${pluralize(
                currentConfig.totalQuestions,
                'question'
              )} only`
        };
      }
    }

    return {
      error: false
    };
  },
  [STEPS_MAP.SYLLABUS]: (config = {}) => {
    const { error, message } =
      checkCountMismatchInSelectedConceptsOfATopic(config);

    if (error) {
      return { error, message };
    }
    return checkCountMismatchInSyllabus(config);
  },
  CONCEPT_SELECTION: (config = {}, currentSelectedTopic = {}) => {
    const { topicUID, rank } = currentSelectedTopic;
    const selectedConceptsForATopic = config[rank].selectedConcepts[topicUID];
    const isAllSelected = isEmpty(selectedConceptsForATopic);

    if (isAllSelected) {
      return {
        error: false
      };
    }

    const { error, message } =
      checkCountMismatchInSelectedSubConceptsOfAConcept({
        selectedConceptsForATopic,
        config,
        currentSelectedTopic
      });

    if (error) {
      return { error, message };
    }

    return checkCountMismatchInTopic({
      selectedConceptsForATopic,
      config,
      currentSelectedTopic
    });
  },
  SUB_CONCEPT_SELECTION: (config = {}, currentSelectedConcept = {}) => {
    const { topicUID, conceptUID, rank } = currentSelectedConcept;

    if (isEmpty(config[rank].selectedSubConcepts[conceptUID])) {
      return {
        isError: false
      };
    }

    const totalQuestionAllowedForASubConcept =
      config[rank].selectedConcepts[topicUID]?.[conceptUID]?.totalQuestions;

    const totalQuestionsSelected = Object.keys(
      config[rank].selectedSubConcepts[conceptUID] ?? {}
    ).reduce(
      (acc, subConceptUID) =>
        acc +
        config[rank].selectedSubConcepts[conceptUID]?.[subConceptUID]
          ?.totalQuestions,
      0
    );

    if (totalQuestionsSelected !== totalQuestionAllowedForASubConcept) {
      return {
        isError: true,
        message: `Total questions don't add upto ${totalQuestionAllowedForASubConcept} yet`
      };
    }
    return {
      isError: false
    };
  }
};

const getProcessedSelectedDifficulties = (selectedDifficulties) => {
  if (!selectedDifficulties) {
    return [];
  }
  if (selectedDifficulties.includes(DIFFICULTY_LEVEL_MAP.ALL_DIFFICULTY)) {
    return DIFFICULTY_CODES;
  }
  return selectedDifficulties;
};

const processQuestionConfigRequest = (questionConfigs) => {
  return questionConfigs.map((questionConfig) => {
    const {
      questionType,
      totalQuestions,
      hasPassage,
      hasPartialMarking,
      totalOptions,
      partialMarkingMultiplier,
      positiveMarks,
      negativeMarks,
      questionSubType,
      hasQuestionSubType,
      totalPassages
    } = questionConfig;

    const isIntegerType = questionType === QUESTION_TYPE_MAP.INTEGER;

    return {
      type: questionType,
      max_question_count: totalQuestions,
      reading_comprehension: hasPassage,
      positive_marks: positiveMarks,
      negative_marks: negativeMarks,
      ...(!isIntegerType && {
        options: totalOptions
      }),
      ...(hasPartialMarking && {
        multiplier: partialMarkingMultiplier
      }),
      ...(hasPassage && {
        comprehension_count: totalPassages
      }),
      ...(hasQuestionSubType && { question_sub_type: questionSubType })
    };
  });
};

const processSyllabusRequest = (
  syllabus,
  selectedTopics,
  selectedConcepts,
  selectedSubConcepts
) => {
  const updatedSubConceptsWithChildren = Object.keys(
    selectedSubConcepts ?? {}
  ).reduce((acc, conceptUID) => {
    acc[conceptUID] = Object.keys(selectedSubConcepts[conceptUID] ?? {}).map(
      (subConceptUID) => {
        const { uid, totalQuestions } =
          selectedSubConcepts[conceptUID][subConceptUID];
        return { uid, max_question_count: totalQuestions, children: null };
      }
    );

    return acc;
  }, {});

  const updatedConceptsWithChildren = Object.keys(
    selectedConcepts ?? {}
  ).reduce((acc, topicUID) => {
    acc[topicUID] = Object.keys(selectedConcepts[topicUID] ?? {}).map(
      (conceptUID) => {
        const { uid, totalQuestions, selectedDifficulties } =
          selectedConcepts[topicUID][conceptUID];
        return {
          uid,
          max_question_count: totalQuestions,
          difficulty_level:
            getProcessedSelectedDifficulties(selectedDifficulties),
          children: updatedSubConceptsWithChildren[uid] ?? []
        };
      }
    );

    return acc;
  }, {});

  const updatedTopicsWithChildren = Object.keys(selectedTopics).reduce(
    (acc, syllabusUID) => {
      acc[syllabusUID] = Object.keys(selectedTopics[syllabusUID] ?? {}).map(
        (topicUID) => {
          const { uid, totalQuestions } = selectedTopics[syllabusUID][topicUID];
          return {
            uid,
            max_question_count: totalQuestions,
            children: updatedConceptsWithChildren[uid] ?? []
          };
        }
      );

      return acc;
    },
    {}
  );

  return Object.keys(syllabus).map((syllabusUID) => {
    const currentSyllabus = syllabus[syllabusUID];
    return {
      uid: currentSyllabus.uid,
      max_question_count: currentSyllabus.totalQuestions ?? 0,
      children: updatedTopicsWithChildren[currentSyllabus.uid] ?? []
    };
  });
};

export const processConfigForRequest = (
  configs = {},
  selectedQuestionCountFilterId
) => {
  const data = [];

  Object.keys(configs).forEach((rank) => {
    const currentConfig = configs[rank];

    data.push({
      uid: currentConfig.uid,
      rank: currentConfig.rank,
      pyq_flag: selectedQuestionCountFilterId === QUESTION_COUNT_FILTER_KEY.PYQ,
      max_question_count: currentConfig.totalQuestions,
      question_config: processQuestionConfigRequest(
        currentConfig.questionConfigs
      ),
      syllabus: processSyllabusRequest(
        currentConfig.syllabus ?? {},
        currentConfig.selectedTopics ?? {},
        currentConfig.selectedConcepts ?? {},
        currentConfig.selectedSubConcepts ?? {}
      )
    });
  });

  return data;
};

const processQuestionConfigResponse = (configs) =>
  configs.map((config) => ({
    questionType: config.type,
    totalQuestions: config.max_question_count,
    hasPassage: config.reading_comprehension,
    hasPartialMarking: config.multiplier > 0,
    totalOptions: config.options,
    partialMarkingMultiplier: config.multiplier,
    positiveMarks: config.positive_marks,
    negativeMarks: config.negative_marks,
    questionSubType: config.question_sub_type,
    hasQuestionSubType: !!config.question_sub_type,
    totalPassages: config.comprehension_count,
    totalQuestionsPerPassage:
      config.max_question_count / config.comprehension_count
  }));

export const getProcessedInitialSelectedDifficulty = (
  initialSelectedDifficulties = []
) => {
  if (!initialSelectedDifficulties || !initialSelectedDifficulties.length) {
    return DIFFICULTY_CODES;
  }
  return initialSelectedDifficulties;
};

const getProcessedResult = (topology, index, result) => {
  if (topology?.children === null || topology?.children?.length === 0) {
    return result;
  }
  const level = {};
  level[index] = {};
  let cumulativeResult = {};

  if (topology?.children) {
    level[index][topology.uid] = {};
    topology?.children.forEach((child) => {
      level[index][topology.uid][child.uid] = {
        uid: child.uid,
        totalQuestions: child.max_question_count,
        selectedDifficulties: getProcessedInitialSelectedDifficulty(
          child.difficulty_level
        )
      };
      cumulativeResult = getProcessedResult(child, index + 1, {
        ...result,
        ...cumulativeResult
      });
    });
  } else {
    if (!Array.isArray(topology)) {
      return result;
    }
    topology.forEach((child) => {
      level[index][child.uid] = {
        uid: child.uid,
        totalQuestions: child.max_question_count
      };
      cumulativeResult = getProcessedResult(child, index + 1, {
        ...result,
        ...cumulativeResult
      });
    });
  }
  return {
    ...cumulativeResult,
    [index]: { ...cumulativeResult[index], ...level[index] }
  };
};

const processSyllabusResponse = (topicGroups = []) => {
  const result = getProcessedResult(topicGroups, 0, {});
  const {
    0: syllabus = {},
    1: selectedTopics = {},
    2: selectedConcepts = {},
    3: selectedSubConcepts = {}
  } = result ?? {};
  return {
    syllabus,
    selectedTopics,
    selectedConcepts,
    selectedSubConcepts
  };
};

export const processConfigResponse = (configs = []) => {
  const data = {};
  configs.forEach((config) => {
    data[config.rank] = {
      rank: config.rank,
      uid: config.uid,
      pyq_flag: config.pyq_flag,
      totalQuestions: config.max_question_count,
      questionConfigs: processQuestionConfigResponse(
        config.question_config ?? []
      ),
      ...processSyllabusResponse(config.syllabus)
    };
  });

  return data;
};

export const getAccumulatedQuestionCountDifficultyWise = (
  incomingQuestionCountDifficultyWise = {},
  exisitingQuestionCountDifficultyWise = {}
) => {
  return Object.keys(incomingQuestionCountDifficultyWise).reduce(
    (acc, difficultyValue) => {
      acc[difficultyValue] =
        (exisitingQuestionCountDifficultyWise[difficultyValue] ?? 0) +
          incomingQuestionCountDifficultyWise[difficultyValue] ?? 0;
      return acc;
    },
    {}
  );
};

export const getTopologyUidsOfChildren = (syllabusUIDs = [], topologies) => {
  return syllabusUIDs.reduce((acc, val) => {
    acc.push(topologies[val]?.uid);
    topologies[val]?.children?.forEach((topic) => {
      acc.push(topic.uid);
    });
    return acc;
  }, []);
};

export const getAccumulatedQuestionAvailabilityCount = (
  incomingTopologiesQuestionCount,
  questionAvailabiltyCount,
  selectedFilterKey
) => {
  return incomingTopologiesQuestionCount.reduce((acc, value = {}) => {
    const existingTopology = questionAvailabiltyCount[value.topology_uid];
    if (existingTopology) {
      acc[value.topology_uid] = {
        ...existingTopology,
        [selectedFilterKey]: {
          ...(existingTopology[selectedFilterKey] ?? {}),
          ...getAccumulatedQuestionCountDifficultyWise(
            value?.question_counts_per_difficulty,
            existingTopology[selectedFilterKey]
          )
        }
      };
    } else {
      const initialValue = {
        topology_uid: value.topology_uid,
        [selectedFilterKey]: value.question_counts_per_difficulty
      };

      acc[value.topology_uid] = initialValue;
    }
    return acc;
  }, {});
};
