import { combineReducers } from 'redux';
import paginate from '@utils/paginate';
import {
  educatorTypes,
  courseTypes,
  plusCoursesTypes,
  itemTypes,
  topologyTypes,
  classTypes,
  searchTypes,
  preSubscribedGoal,
  batchTypes,
  batchGroupTypes,
  quizTypes,
  classroomTypes,
  doubtTypes,
  authTypes,
  moderatorTypes,
  savedTypes,
  templatesTypes,
  helpCenterTypes,
  driveTypes,
  learnerDoubtsAndSolutionsTypes,
  educatorLiveMentoringTypes,
  combatTypes,
  doubtsOnWeb2
} from '../constants';
import quizTakingTypes from '../constants/quizTaking';
import {
  updateCourseReducer,
  updateCommentReducer,
  updatePlusHomeFeedReducer,
  updateSavedReducer
} from './updateReducers';

const paginator = combineReducers({
  educatorLeaderboard: paginate({
    actionType: educatorTypes.FETCH_EDUCATOR_LEADERBOARD
  }),
  freeGoalCourses: paginate({
    actionType: courseTypes.FETCH_FREE_GOAL_COURSES
  }),
  continueLearning: paginate({
    actionType: courseTypes.FETCH_FREE_CONTINUE_LEARNING
  }),
  library: paginate({
    actionType: courseTypes.FETCH_MY_LIBRARY
  }),
  watchHistory: paginate({
    actionType: itemTypes.FETCH_WATCH_HISTORY
  }),
  plusCourseItems: paginate({
    actionType: plusCoursesTypes.FETCH_PLUS_COURSE_ITEM
  }),
  specialClassesFeed: paginate({
    actionType: courseTypes.FETCH_SPECIAL_CLASSES
  }),
  specialClassesTypeFeed: paginate({
    actionType: courseTypes.FETCH_SPECIAL_CLASSES_TYPE
  }),
  enrolledCourses: paginate({
    actionType: courseTypes.FETCH_ENROLLED_COURSES
  }),
  enrolledBatches: paginate({
    actionType: batchGroupTypes.FETCH_ENROLLED_BATCHES
  }),
  recommendedCourses: paginate({
    actionType: courseTypes.FETCH_RECOMMENDED_COURSES
  }),
  completedCourses: paginate({
    actionType: courseTypes.FETCH_COMPLETED_COURSES
  }),
  moreCourses: paginate({
    actionType: courseTypes.FETCH_MORE_COURSES
  }),
  testSeries: paginate({
    actionType: plusCoursesTypes.FETCH_TEST_SERIES
  }),
  plusHomePageFeed: paginate({
    actionType: plusCoursesTypes.FETCH_PLUS_HOME_FEED,
    updateReducer: updatePlusHomeFeedReducer
  }),
  topologyCourseFeed: paginate({
    actionType: topologyTypes.FETCH_TOPOLOGY_COURSE_FEED
  }),
  topologyCourses: paginate({
    actionType: topologyTypes.FETCH_TOPOLOGY_COURSES
  }),
  profileFeed: paginate({
    actionType: educatorTypes.FETCH_PROFILE_FEED
  }),
  seeAllTypeFeed: paginate({
    actionType: courseTypes.FETCH_SEE_ALL_FEED
  }),
  similarAndOtherCourses: paginate({
    actionType: classTypes.FETCH_SIMILAR_AND_OTHER_COURSES
  }),
  filteredCourses: paginate({
    actionType: educatorTypes.FETCH_FILTERED_COURSES
  }),
  educatorTestimonials: paginate({
    actionType: educatorTypes.FETCH_EDUCATOR_TESTIMONIALS
  }),
  courseItems: paginate({
    actionType: courseTypes.FETCH_FREE_COURSE_ITEM
  }),
  courseReviews: paginate({
    actionType: courseTypes.FETCH_COURSE_REVIEWS
  }),
  freeCoursesFeed: paginate({
    actionType: courseTypes.FETCH_FREE_COURSES_OF_A_GOAL
  }),
  searchResult: paginate({
    actionType: searchTypes.FETCH_SEARCH_RESULT
  }),
  preSubscribedBlocks: paginate({
    actionType: preSubscribedGoal.FETCH_PRESUBSCRIBED_BLOCKS,
    removeDuplicates: { uniqKey: 'feedrow' }
  }),
  topologyFreeCourseFeed: paginate({
    actionType: topologyTypes.FETCH_TOPOLOGY_OF_FREE_COURSE_FEED
  }),
  freeCoursesOfAConcept: paginate({
    actionType: topologyTypes.FETCH_FREE_COURSES_OF_A_CONCEPT
  }),
  searchList: paginate({
    actionType: searchTypes.FETCH_SEARCH_LIST
  }),
  learnerCourses: paginate({
    actionType: educatorTypes.FETCH_LEARNER_COURSES
  }),
  prevEnrolledCourses: paginate({
    actionType: courseTypes.FETCH_PREVIOUS_ENROLLED_COURSES
  }),
  courseUpdates: paginate({
    actionType: plusCoursesTypes.FETCH_COURSE_UPDATES,
    updateReducer: updateCourseReducer
  }),
  courseUpdateComments: paginate({
    actionType: courseTypes.FETCH_COMMENTS,
    updateReducer: updateCommentReducer
  }),
  learnerFollowing: paginate({
    actionType: educatorTypes.FETCH_LEARNER_FOLLOWING_LIST
  }),
  learnerLeaderboard: paginate({
    actionType: educatorTypes.FETCH_LEARNER_LEADERBOARD
  }),
  favouriteEducators: paginate({
    actionType: educatorTypes.FETCH_FAVOURITE_EDUCATORS
  }),
  batchSchedule: paginate({
    actionType: batchTypes.FETCH_SCHEDULE
  }),
  batchTestSeries: paginate({
    actionType: batchTypes.FETCH_TEST_SERIES
  }),
  conceptCourses: paginate({
    actionType: batchTypes.FETCH_COURSES_IN_CONCEPT
  }),
  itemsInCourse: paginate({
    actionType: batchTypes.FETCH_ITEMS_IN_COURSE
  }),
  batches: paginate({
    actionType: batchTypes.FETCH_BATCHES
  }),
  batchesGroup: paginate({
    actionType: batchGroupTypes.FETCH_BATCHES_GROUP
  }),
  batchUpdates: paginate({
    actionType: batchTypes.FETCH_BATCH_UPDATES
  }),
  quizSolutions: paginate({
    actionType: quizTakingTypes.QUIZ_FETCH_PAGINATED_SOLUTIONS
  }),
  combats: paginate({
    actionType: combatTypes.FETCH_PAGINATED_COMBATS
  }),
  quizLeaderboardLive: paginate({
    actionType: quizTakingTypes.QUIZ_FETCH_LEADERBOARD_LIVE
  }),
  quizLeaderboardAll: paginate({
    actionType: quizTakingTypes.QUIZ_FETCH_LEADERBOARD_ALL
  }),
  classroomClasses: paginate({
    actionType: classroomTypes.GET_CLASS
  }),
  topologyFeed: paginate({
    actionType: topologyTypes.FETCH_TOPOLOGY_FEED
  }),
  educatorDashboardCourses: paginate({
    actionType: educatorTypes.FETCH_EDUCATOR_DASHBOARD_COURSES
  }),
  eduScheduledClasses: paginate({
    actionType: educatorTypes.FETCH_EDUCATOR_SCHEDULED_CLASSES
  }),
  mySolutions: paginate({
    actionType: doubtTypes.FETCH_MY_SOLUTIONS
  }),

  driveContent: paginate({
    actionType: driveTypes.FETCH_DRIVE_CONTENT
  }),

  currentDriveSearch: paginate({
    actionType: driveTypes.SEARCH_FILES
  }),

  referralList: paginate({
    actionType: authTypes.FETCH_REFERRAL_LIST
  }),
  educators: paginate({
    actionType: moderatorTypes.FETCH_EDUCATORS
  }),
  modPlusCourses: paginate({
    actionType: moderatorTypes.FETCH_COURSES
  }),
  modLessons: paginate({
    actionType: moderatorTypes.FETCH_LESSONS
  }),
  eduLessonFeedback: paginate({
    actionType: educatorTypes.FETCH_EDU_LESSON_FEEDBACK
  }),
  testSeriesTemplates: paginate({
    actionType: templatesTypes.FETCH_TEMPLATES
  }),
  reviewTests: paginate({
    actionType: quizTypes.FETCH_REVIEWS
  }),
  articleFeed: paginate({
    actionType: helpCenterTypes.FETCH_ARTICLES
  }),
  tickets: paginate({
    actionType: helpCenterTypes.FETCH_TICKETS_HISTORY
  }),
  courseTemplates: paginate({
    actionType: educatorTypes.FETCH_TEMPLATES_OF_A_COURSE
  }),
  myEvaluations: paginate({
    actionType: quizTypes.FETCH_MY_EVALS
  }),
  evaluatedCopies: paginate({
    actionType: quizTypes.FETCH_EVALUATED_COPIES
  }),
  saved: paginate({
    actionType: savedTypes.FETCH_SAVED_ENTITY,
    updateReducer: updateSavedReducer
  }),
  myUploads: paginate({
    actionType: quizTypes.FETCH_MY_UPLOADS
  }),
  educatorLiveMentoringSessions: paginate({
    actionType: educatorLiveMentoringTypes.FETCH_SESSIONS
  }),
  courseFeedback: paginate({
    actionType: educatorTypes.FETCH_COURSE_FEEDBACK
  }),
  catalogueTypes: paginate({
    actionType: learnerDoubtsAndSolutionsTypes.FETCH_CATALOGUE_TYPES
  }),
  catalogueDetails: paginate({
    actionType: learnerDoubtsAndSolutionsTypes.FETCH_CATALOGUE_DETAILS
  }),
  catalogueItemDetails: paginate({
    actionType: learnerDoubtsAndSolutionsTypes.FETCH_QUESTIONS_LIST
  }),
  freeRelatedCourses: paginate({
    actionType: itemTypes.FETCH_FREE_RELATED_SPECIAL_ITEMS
  }),
  myDoubts: paginate({
    actionType: doubtsOnWeb2.GET_ALL_DOUBTS
  }),
  allSpecialClasses: paginate({
    actionType: courseTypes.GET_ALL_SPECIAL_CLASSES
  }),
  batchGroupFreeClasses: paginate({
    actionType: batchGroupTypes.FETCH_BATCH_GROUP_CLASSES
  })
});

export default paginator;
