/* eslint-disable max-lines */
import isEmpty from 'lodash/isEmpty';
import isEmptyUtil from '@utils/isEmpty';
import { testReviewStates } from '@constants/index';
import {
  getAccumulatedQuestionAvailabilityCount,
  processConfigResponse
} from '@educator/Container/TestCreation/Configuration/utils';
import { CREATE_QUIZ_TYPES } from '@educator/Container/TestCreation/constants';
import { QUESTION_COUNT_FILTER_OPTIONS } from '@educator/Container/TestCreation/Configuration/constants';
import { createQuizTypes } from '../constants';

const initialState = {
  // used to denote pending api/action
  isPendingAction: false,
  data: {},
  questionsBySectionUID: {},
  questionsPosByRank: {},
  reviewData: {},
  reviewNotfications: [],
  modal: {
    type: null,
    data: {}
  },
  autoConfig: {},
  topologies: {},
  topologiesQuestionsAvailabiltyCount: {},
  selectedQuestionCountFilter: QUESTION_COUNT_FILTER_OPTIONS[0],
  questionFeedbackOptions: {}
};

const modifyPendingState = (value) => (state) => ({
  ...state,
  isPendingAction: value
});

const removeProperty = (obj, property) => {
  const { [property]: _, ...rest } = obj;

  return rest;
};

const reducerMap = {
  [`${createQuizTypes.CREATE_QUIZ_FETCH_QUIZ_DEATILS}_SUCCESS`](state, action) {
    if (!action.shouldSave) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        [action.quizUID]: {
          ...action.result,
          start_time: new Date(action.result.start_time).getTime()
        }
      }
    };
  },
  [`${CREATE_QUIZ_TYPES.IMPORT_EXISTING_QUIZ}_SUCCESS`](state, action) {
    if (!action.shouldSave) {
      return state;
    }

    return {
      ...state,
      data: {
        ...state.data,
        [action.quizUID]: {
          ...action.result,
          start_time: new Date(action.result.start_time).getTime()
        }
      }
    };
  },

  [`${createQuizTypes.CREATE_QUIZ_FETCH_QUIZ_QUESTIONS}_SUCCESS`](
    state,
    action
  ) {
    const byRank = {};

    action.result.forEach(({ data }, index) => {
      byRank[data.questionRank] = index;
    });

    return {
      ...state,
      questionsPosByRank: {
        ...state.questionsPosByRank,
        [action.sectionUID]: byRank
      },
      questionsBySectionUID: {
        ...state.questionsBySectionUID,
        [action.sectionUID]: action.result
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_FETCH_REVIEW_DETAILS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      reviewData: { ...state.reviewData, [action.reviewUID]: action.result }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_FINISH_REVIEW}_SUCCESS`](state, action) {
    return {
      ...state,
      reviewData: {
        ...state.reviewData,
        [action.reviewUID]: {
          ...state.reviewData[action.reviewUID],
          state: testReviewStates.COMPLETED
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_UPDATE_SECTIONAL_QUESTION}`](state, action) {
    const { data, sectionUID, questionUID } = action;
    const sectionalQuestions = [
      ...(state.questionsBySectionUID?.[sectionUID] ?? [])
    ];

    const index = sectionalQuestions.findIndex(
      (question) => question?.data?.questionUID === questionUID
    );

    if (index < 0) {
      sectionalQuestions.push(data);
    } else {
      sectionalQuestions[index] = data;
    }

    return {
      ...state,
      questionsPosByRank: {
        ...state.questionsPosByRank,
        [sectionUID]: {
          ...state.questionsPosByRank[sectionUID],
          [data.data.questionRank]:
            index < 0 ? sectionalQuestions.length - 1 : index
        }
      },
      questionsBySectionUID: {
        ...state.questionsBySectionUID,
        [sectionUID]: sectionalQuestions
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_SAVE_QUIZ_AUTO_CONFIGS}_REQUEST`]:
    modifyPendingState(true),
  [`${createQuizTypes.CREATE_QUIZ_REPORT_CREATED_QUESTION}_REQUEST`]:
    modifyPendingState(true),
  [`${createQuizTypes.CREATE_QUIZ_DELETE_QUESTION}_REQUEST`]:
    modifyPendingState(true),
  [`${createQuizTypes.CREATE_QUIZ_REPLACE_QUESTION}_REQUEST`]:
    modifyPendingState(true),
  [`${createQuizTypes.CREATE_QUIZ_SAVE_QUIZ_AUTO_CONFIGS}_SUCCESS`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_REPORT_CREATED_QUESTION}_SUCCESS`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_REPLACE_QUESTION}_SUCCESS`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_DELETE_QUESTION}_SUCCESS`](state, action) {
    const { sectionUID, questionUID: selectedQuestionUID } = action;
    const filteredSectionalQuestions = state.questionsBySectionUID[
      sectionUID
    ].filter((question) => question?.data?.questionUID !== selectedQuestionUID);
    const byRank = {};
    filteredSectionalQuestions.forEach(({ data }, index) => {
      byRank[data.questionRank] = index;
    });

    return {
      ...state,
      isPendingAction: false,
      questionsPosByRank: {
        ...state.questionsPosByRank,
        [sectionUID]: byRank
      },
      questionsBySectionUID: {
        ...state.questionsBySectionUID,
        [sectionUID]: filteredSectionalQuestions
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_SAVE_QUIZ_AUTO_CONFIGS}_FAILURE`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_REPORT_CREATED_QUESTION}_FAILURE`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_DELETE_QUESTION}_FAILURE`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_REPLACE_QUESTION}_FAILURE`]:
    modifyPendingState(false),
  [`${createQuizTypes.CREATE_QUIZ_REORDER_QUESTION}_SUCCESS`](state, action) {
    const { questionsToSort, sectionUID } = action;

    const sectionalQuestions = [...state.questionsBySectionUID[sectionUID]];
    const sectionalQuestionsLength = sectionalQuestions.length;

    for (let i = 0; i < sectionalQuestionsLength; i += 1) {
      const { questionUID } = sectionalQuestions[i].data;

      if (questionsToSort[questionUID]) {
        sectionalQuestions[i].data.questionRank = questionsToSort[questionUID];
      }
    }

    const byRank = {};

    sectionalQuestions.forEach(({ data }, index) => {
      byRank[data.questionRank] = index;
    });

    return {
      ...state,
      questionsPosByRank: {
        ...state.questionsPosByRank,
        [sectionUID]: byRank
      },
      questionsBySectionUID: {
        ...state.questionsBySectionUID,
        [sectionUID]: sectionalQuestions
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_UPDATE_QUIZ_INSTRUCTIONS}`](state, action) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.data.quizUID]: {
          ...state.data[action.data.quizUID],
          instructions: action.data.instructions
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_MARK_PDF_UPLOADED}_SUCCESS`](state, action) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.quizUID]: {
          ...state.data[action.quizUID],
          pdf_uploaded: action.pdf_url
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_DELETE_SUBJECTIVE_PDF}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.quizUID]: {
          ...state.data[action.quizUID],
          pdf_uploaded: null
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_FETCH_REVIEW_NOTIFICATIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      reviewNotfications: action.result
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_SETUP_INITIAL_AUTO_CONFIGS}`](state, action) {
    return {
      ...state,
      autoConfig: { ...state.autoConfig, ...action.data }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_UPDATE_QUIZ_AUTO_CONFIGS}`](state, action) {
    const { rank, index, data } = action;

    const updatedQuestionConfigs = [...state.autoConfig[rank].questionConfigs];

    if (isEmptyUtil(index)) {
      updatedQuestionConfigs.push(data);
    } else {
      updatedQuestionConfigs[index] = data;
    }

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          questionConfigs: updatedQuestionConfigs
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_DELETE_QUIZ_AUTO_CONFIGS}`](state, action) {
    const { rank, index: pos } = action;
    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          questionConfigs: state.autoConfig[rank].questionConfigs.filter(
            (_, index) => index !== pos
          )
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_UPDATE_SELECTED_TOPICS}`](state, action) {
    const { rank, syllabusUID, topicUID, data = {} } = action;

    if (topicUID) {
      const { selectedTopic, selectedConcepts } = data;

      return {
        ...state,
        autoConfig: {
          ...state.autoConfig,
          [rank]: {
            ...state.autoConfig[rank],
            selectedTopics: {
              ...state.autoConfig[rank].selectedTopics,
              [syllabusUID]: {
                ...(isEmpty(selectedTopic)
                  ? removeProperty(
                      state.autoConfig[rank].selectedTopics[syllabusUID],
                      topicUID
                    )
                  : {
                      ...state.autoConfig[rank].selectedTopics[syllabusUID],
                      [topicUID]: selectedTopic
                    })
              }
            },
            selectedConcepts: {
              ...state.autoConfig[rank].selectedConcepts,
              [topicUID]: selectedConcepts
            }
          }
        }
      };
    }

    const { selectedTopics, selectedConcepts } = data;

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          selectedConcepts: {
            ...state.autoConfig[rank].selectedConcepts,
            ...selectedConcepts
          },
          selectedTopics: {
            ...state.autoConfig[rank].selectedTopics,
            [syllabusUID]: selectedTopics
          }
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_UPDATE_SELECTED_CONCEPTS}`](state, action) {
    const { rank, topicUID, conceptUID, data } = action;

    if (conceptUID) {
      const { selectedConcept, selectedSubConcepts } = data;

      return {
        ...state,
        autoConfig: {
          ...state.autoConfig,
          [rank]: {
            ...state.autoConfig[rank],
            selectedConcepts: {
              ...state.autoConfig[rank].selectedConcepts,
              [topicUID]: {
                ...(isEmpty(selectedConcept)
                  ? removeProperty(
                      state.autoConfig[rank].selectedConcepts[topicUID],
                      conceptUID
                    )
                  : {
                      ...state.autoConfig[rank].selectedConcepts[topicUID],
                      [conceptUID]: selectedConcept
                    })
              }
            },
            selectedSubConcepts: {
              ...state.autoConfig[rank].selectedSubConcepts,
              [conceptUID]: selectedSubConcepts
            }
          }
        }
      };
    }

    const { selectedConcepts, selectedSubConcepts } = data;

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          selectedSubConcepts: {
            ...state.autoConfig[rank].selectedSubConcepts,
            ...selectedSubConcepts
          },
          selectedConcepts: {
            ...state.autoConfig[rank].selectedConcepts,
            [topicUID]: selectedConcepts
          }
        }
      }
    };
  },
  [`${CREATE_QUIZ_TYPES.CREATE_QUIZ_UPDATE_SELECTED_SUB_CONCEPTS}`](
    state,
    action
  ) {
    const { rank, conceptUID, subConceptUID, data } = action;

    if (subConceptUID) {
      const { selectedSubConcept } = data;

      return {
        ...state,
        autoConfig: {
          ...state.autoConfig,
          [rank]: {
            ...state.autoConfig[rank],
            selectedSubConcepts: {
              ...state.autoConfig[rank].selectedSubConcepts,
              [conceptUID]: {
                ...(isEmpty(selectedSubConcept)
                  ? removeProperty(
                      state.autoConfig[rank].selectedSubConcepts[conceptUID],
                      subConceptUID
                    )
                  : {
                      ...state.autoConfig[rank].selectedSubConcepts[conceptUID],
                      [subConceptUID]: selectedSubConcept
                    })
              }
            }
          }
        }
      };
    }

    const { selectedSubConcepts } = data;

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          selectedSubConcepts: {
            ...state.autoConfig[rank].selectedSubConcepts,
            [conceptUID]: selectedSubConcepts
          }
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_UPDATE_SYLLABUS_QUESTION_COUNT}`](
    state,
    action
  ) {
    const { rank, syllabusUID, data } = action;

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          syllabus: {
            ...state.autoConfig[rank].syllabus,
            [syllabusUID]: {
              ...state.autoConfig[rank].syllabus[syllabusUID],
              totalQuestions: data
            }
          }
        }
      }
    };
  },

  [`${CREATE_QUIZ_TYPES.CREATE_QUIZ_UPDATE_QUESTION_COUNT}`](state, action) {
    const { rank, parentUID, childUID, key, count } = action;

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          [key]: {
            ...state.autoConfig[rank][key],
            [parentUID]: {
              ...state.autoConfig[rank][key][parentUID],
              [childUID]: {
                ...state.autoConfig[rank][key][parentUID][childUID],
                totalQuestions: count ?? 0
              }
            }
          }
        }
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_MODAL_TYPES}`](state, action) {
    const {
      data: { type, data }
    } = action;

    return {
      ...state,
      modal: {
        type,
        data
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_FETCH_TOPOLOGY}_SUCCESS`](state, action) {
    const {
      result: { topologies = [] }
    } = action;

    const data = {};

    topologies.forEach((topology) => {
      data[topology.uid] = topology;
    });

    return {
      ...state,
      topologies: {
        ...data
      }
    };
  },
  [`${CREATE_QUIZ_TYPES.FETCH_QUESTION_COUNT}_SUCCESS`](state, action) {
    const { result: incomingTopologiesQuestionCount = [], selectedFilterKey } =
      action;

    const accumulatedCountData = getAccumulatedQuestionAvailabilityCount(
      incomingTopologiesQuestionCount,
      state.topologiesQuestionsAvailabiltyCount,
      selectedFilterKey
    );
    return {
      ...state,
      topologiesQuestionsAvailabiltyCount: {
        ...state.topologiesQuestionsAvailabiltyCount,
        ...accumulatedCountData
      }
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_FETCH_QUIZ_AUTO_CONFIGS}_SUCCESS`](
    state,
    action
  ) {
    const { result } = action;

    return {
      ...state,
      autoConfig: processConfigResponse(result),
      selectedQuestionCountFilter: result[0]?.pyq_flag
        ? QUESTION_COUNT_FILTER_OPTIONS[1]
        : QUESTION_COUNT_FILTER_OPTIONS[0]
    };
  },
  [`${createQuizTypes.CREATE_QUIZ_FETCH_QUESTION_FEEDBACK_OPTIONS}_SUCCESS`](
    state,
    action
  ) {
    const { result } = action;

    return {
      ...state,
      questionFeedbackOptions: result
    };
  },

  [`${CREATE_QUIZ_TYPES.SET_PREVIOUS_SELECTED_STATE}`](state, action) {
    const {
      data: { type, item, existingKey, previousStateKey }
    } = action;

    const { uid, rank } = item;
    if (type === 'SET')
      return {
        ...state,
        [previousStateKey]: {
          ...state.autoConfig?.[rank]?.[existingKey]?.[uid]
        }
      };

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          [existingKey]: {
            ...state.autoConfig[rank][existingKey],
            [uid]: { ...state[previousStateKey] }
          }
        }
      }
    };
  },

  [`${CREATE_QUIZ_TYPES.ADD_DIFFICULTY_LEVELS_TO_CONCEPT}`](state, action) {
    const {
      data: { topicUID, conceptUID, selectedDifficulties, rank }
    } = action;

    return {
      ...state,
      autoConfig: {
        ...state.autoConfig,
        [rank]: {
          ...state.autoConfig[rank],
          selectedConcepts: {
            ...state.autoConfig[rank].selectedConcepts,
            [topicUID]: {
              ...state.autoConfig[rank].selectedConcepts[topicUID],
              [conceptUID]: {
                ...state.autoConfig[rank].selectedConcepts[topicUID][
                  conceptUID
                ],
                selectedDifficulties
              }
            }
          }
        }
      }
    };
  },

  [`${CREATE_QUIZ_TYPES.SET_QUESTION_COUNT_FILTER}`](state, action) {
    const { selectedFilter } = action;

    return {
      ...state,
      selectedQuestionCountFilter: selectedFilter
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
