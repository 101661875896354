import { applyMiddleware } from 'redux';
import thunkMiddleware from './thunkMiddleware';
import hostMiddleware from './hostMiddleware';
import callAPIMiddleware from './callAPIMiddleware';
import modelMiddleware from './modelMiddleware';

const withMiddleWare = applyMiddleware(
  thunkMiddleware,
  hostMiddleware,
  callAPIMiddleware,
  modelMiddleware
);

export default withMiddleWare;
