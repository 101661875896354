export const SUBJECTIVE_UPLOAD_STATUS = {
  PENDING: 1,
  DONE: 2,
  DECLINED: 3
};

export const WIDTH_MAX = 1366;
export const WIDTH_MIN = 1024;
export const HEADER_HEIGHT = 72;

export const MODAL_TYPES = {
  QUIZ_INSTRUCTION: 'QUIZ_INSTRUCTION',
  QUIZ_CALCULATOR: 'QUIZ_CALCULATOR',
  QUIZ_LANGUAGE_SELECTION: 'QUIZ_LANGUAGE_SELECTION',
  QUIZ_TIME_UP: 'QUIZ_TIME_UP',
  QUIZ_FINISH_CONFIRMATION: 'QUIZ_FINISH_CONFIRMATION',
  QUIZ_REPORT_QUESTION: 'QUIZ_REPORT_QUESTION',
  QUIZ_UPLOAD_SUBJECTIVE_ANSWER: 'QUIZ_UPLOAD_SUBJECTIVE_ANSWER',
  QUIZ_REJECT_UPLOAD_COMFIRMATION: 'QUIZ_REJECT_UPLOAD_COMFIRMATION',
  SOLUTION_REPORT_QUESTION: 'SOLUTION_REPORT_QUESTION',
  SOLUTION_REMOVE_SAVED_QUESTION: 'SOLUTION_REMOVE_SAVED_QUESTION',
  SOLUTION_VIDEO_FEEDBACK: 'SOLUTION_VIDEO_FEEDBACK',
  QUESTION_SOLUTION_VIDEO: 'QUESTION_SOLUTION_VIDEO',
  RESULT_LEADERBOARD: 'RESULT_LEADERBOARD',
  QUIZ_CLASS_SELECTION: 'QUIZ_CLASS_SELECTION',
  REVAMPED_INSTRUCTIONS_DRAWER: 'REVAMPED_INSTRUCTIONS_DRAWER',
  ATTEMPT_SUMMARY: 'SUMMARY_MODAL'
};

export const DEFAULT_SOLUTION_TYPE = { label: 'All answers', value: 0 };

export const SUBJECTIVE_SOLUTION_TYPE = [
  DEFAULT_SOLUTION_TYPE,
  { label: 'Unanswered questions', value: 3 },
  { label: 'Answered questions', value: 5 }
];

export const OBJECTIVE_SOLUTION_TYPE = [
  DEFAULT_SOLUTION_TYPE,
  { label: 'Correct answers', value: 1 },
  { label: 'Incorrect answers', value: 2 },
  { label: 'Unanswered questions', value: 3 }
];

export const SOLUTION_TYPE = {
  MODAL: 'modal-solution',
  USER: 'user-solution'
};

export const MIN_SUBJECTS = 3;

export const ENGLISH_LANGUAGE_PREFERENCE = {
  code: 1,
  displayName: 'English'
};

export const ATTEMPT_SUMMARY_LIST = [
  {
    id: 'TOTAL_QUESTIONS',
    name: 'Total questions',
    iconSrc: '/test-revamp/total-question-shape.png'
  },
  {
    id: 'ANSWERED',
    name: 'Answered',
    iconSrc: '/test-revamp/shape-3.png'
  },
  {
    id: 'UNANSWERED',
    name: 'Not answered',
    iconSrc: '/test-revamp/shape-2.png'
  },
  {
    id: 'MARKED',
    name: 'Marked for review',
    iconSrc: '/test-revamp/shape-4.png'
  },
  {
    id: 'MARKED_AND_ANSWERED',
    name: 'Marked & answered',
    iconSrc: '/test-revamp/shape-5.png'
  },
  {
    id: 'NOT_VISITED',
    name: 'Not Visited',
    iconSrc: '/test-revamp/shape-1.png'
  }
];

export const ATTEMPT_SUMMARY_TYPE = {
  SECTION: 'section',
  TEST: 'test',
  LAST_QUESTION: 'Exam Summary'
};

export const ATTEMPT_STATES = [
  {
    name: 'Not Visited',
    iconSrc: '/test-revamp/shape-1.png'
  },
  {
    name: 'Marked',
    iconSrc: '/test-revamp/shape-4.png'
  },
  {
    name: 'Answered',
    iconSrc: '/test-revamp/shape-3.png'
  },
  {
    name: 'Unanswered',
    iconSrc: '/test-revamp/shape-2.png'
  },
  {
    name: 'Marked & answered',
    iconSrc: '/test-revamp/shape-5.png'
  }
];

export const mediaQuery = {
  aboveTablet: '@media only screen and (min-width:1264px)',
  aboveLargeScreen: '@media only screen and (min-width:1744px)'
};
