export const staticCheckoutCarousels = [
  {
    image: 'https://static.uacdn.net/web-cms/daily_live_classes_be8fa5af21.svg',
    title: 'Structured courses',
    subTitle:
      'All our courses are structured in line with your exam syllabus to help you best prepare for it'
  },
  {
    image: 'https://static.uacdn.net/web-cms/chat_with_educator_6cb3c64761.svg',
    title: 'Interactive Live Classes',
    subTitle:
      'Chat with the educator, engage in discussions and ask your questions - all during a class'
  },
  {
    image: 'https://static.uacdn.net/web-cms/mock_test_quizes_521a5f770e.svg',
    title: 'Live test and quizzes',
    subTitle:
      'Take live Mock Tests curated in line with the exam pattern and stay on track with your preparation'
  }
];

export const staticIconicCarousels = [
  {
    image: 'https://static.uacdn.net/web-cms/Coach_cfcb2e2bde.svg',
    title: 'Personal Coach',
    subTitle:
      'One on one guidance from top exam experts as your personal coach, align your preparation strategy'
  },
  {
    image: 'https://static.uacdn.net/web-cms/Mains_727b2fe5dc.svg',
    title: 'Main Q&A Practice',
    subTitle:
      'Strengthen your mains answer writing through regular practice with evaluation and personalised feedback'
  },
  {
    image: 'https://static.uacdn.net/web-cms/daily_live_classes_1576e81058.svg',
    title: 'All Benefits of Plus',
    subTitle:
      'Live classes from top educators, mock tests & quizzes, structured batch courses in line with exam syllabus'
  }
];

export const staticLiteCarousels = [
  {
    image: 'https://static.uacdn.net/web-cms/mock_test_quizes_521a5f770e.svg',
    title: 'Access to curated test series',
    subTitle:
      'Curated test series that lets you evaluate your preparation before your examination'
  }
];

export const staticNoCostEmiData = {
  text: 'No cost EMI available on 6 months & above subscription plans',
  bgColor: 'rgb(193 185 172 / 8%)',
  icon: 'https://static.uacdn.net/web-cms/sale_discount_bf80e21263.png'
};
