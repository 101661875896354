import { playerTypes } from '../constants';

const initialState = {
  isPlayerOpen: false,
  playerLoaded: false,
  subjectiveTestEvalURL: '',
  urlMismatch: false,
  classData: {
    loading: false,
    loaded: false,
    data: null
  },
  doubtsBrowseUrl: '',
  lmpAddToCanvas: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case playerTypes.CLOSE_PLAYER:
      return {
        ...state,
        isPlayerOpen: false
      };

    case playerTypes.TOGGLE_URL_MISMATCH:
      return {
        ...state,
        urlMismatch: action.urlMismatch
      };

    case playerTypes.OPEN_PLAYER:
      return {
        ...state,
        isPlayerOpen: true
      };

    case `${playerTypes.SET_OBJECT_TYPE}_SUCCESS`:
      return {
        ...state,
        doubtSolvingObjectType: action.result.doubts_solution
      };

    case playerTypes.SET_PLAYER_LOADED:
      return {
        ...state,
        playerLoaded: true
      };
    case playerTypes.RESET_PLAYER_LOADED:
      return {
        ...state,
        playerLoaded: false
      };

    case `${playerTypes.FETCH_CLASS}_REQUEST`:
      return {
        ...state,
        isPlayerOpen: false,
        classData: {
          loading: true,
          loaded: false,
          data: null
        }
      };

    case `${playerTypes.FETCH_CLASS}_SUCCESS`:
      return {
        ...state,
        isPlayerOpen: true,
        classData: {
          loading: false,
          loaded: true,
          data: {
            ...action.result,
            author: action.result.author || action.result.programme.author
          }
        }
      };

    default:
      return state;
  }
};

export default reducer;
