import { programmeTypes } from '@constants/index';

const calculateSubconceptsCoveredPercentage = (
  coveredSubconcepts,
  totalSubconcepts
) => {
  const percentage = (coveredSubconcepts / totalSubconcepts) * 100;
  return Math.ceil(percentage);
};

export const getIsOfflineTestSeries = (programmeType) => {
  return programmeType === programmeTypes.HYBRID_TESTSERIES;
};

export default calculateSubconceptsCoveredPercentage;
