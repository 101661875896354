export const SECTION_TYPES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

export const sectionOptions = [
  { label: 'Enabled', name: 'section', value: SECTION_TYPES.ENABLED },
  { label: 'Disabled', name: 'section', value: SECTION_TYPES.DISABLED }
];

export const TEST_TYPES = {
  OBJECTIVE: 1,
  SUBJCETIVE: 3
};

export const testTypeOptionsMap = {
  [TEST_TYPES.OBJECTIVE]: {
    label: 'Objective',
    name: 'test-type',
    value: TEST_TYPES.OBJECTIVE
  },
  [TEST_TYPES.SUBJCETIVE]: {
    label: 'Subjective',
    name: 'test-type',
    value: TEST_TYPES.SUBJCETIVE
  }
};

export const testTypeOptions = Object.values(testTypeOptionsMap);

export const CALCULATOR_TYPE = {
  NONE: -1,
  BASIC: 1,
  SCIENTIFIC: 2
};

export const calculatorOptionsMap = {
  [CALCULATOR_TYPE.NONE]: {
    label: 'None',
    name: 'calculator-type',
    value: CALCULATOR_TYPE.NONE
  },
  [CALCULATOR_TYPE.BASIC]: {
    label: 'Basic',
    name: 'calculator-type',
    value: CALCULATOR_TYPE.BASIC
  },
  [CALCULATOR_TYPE.SCIENTIFIC]: {
    label: 'Scientific',
    name: 'calculator-type',
    value: CALCULATOR_TYPE.SCIENTIFIC
  }
};

export const calculatorOptions = Object.values(calculatorOptionsMap);

export const criteriasPositionMap = {
  criteria1: 0,
  criteria2: 1,
  criteria3: 2,
  criteria4: 3,
  criteria5: 4
};

export const generalInstructionsText = '<p>General instructions</p>';
