import mergeDeep from '@utils/mergeDeep';
import { topologyTypes, savedTypes, educatorTypes } from '../constants';

const initialState = {
  data: {},
  onboardingGoals: {},
  goalOnBoardingPreferences: {},
  areGoalOnBoardingPreferencesUpdated: {},
  savedGoalOnBoardingPreferences: [],
  goalLanguages: [],
  topologyChildren: {},
  topologyDetails: {},
  topologyExtraInfo: {},
  freeTopologyChildren: {},
  popularGoals: [],
  popularGoalsGroups: {},
  topologyMoreCoursesFilters: {},
  topologyNotes: {},
  savedCount: {},
  preSubscribedStaticContent: {},
  allLanguages: [],
  plusGoals: [],
  goalBenefits: {}
};

const reducerMap = {
  [`${topologyTypes.FETCH_SUBSCRIPTION_GOALS}_SUCCESS`]: (action) => {
    return {
      plusGoals: action.result
    };
  },
  [`${topologyTypes.FETCH_GOAL_BENEFITS}_SUCCESS`]: (
    { key, result },
    state
  ) => ({
    goalBenefits: {
      ...state.goalBenefits,
      [key]: result
    }
  }),
  [topologyTypes.SET_GLO_PREFERENCES_UPDATED]: (
    { goalUID, areUpdated },
    state
  ) => ({
    areGoalOnBoardingPreferencesUpdated: {
      ...state.areGoalOnBoardingPreferencesUpdated,
      [goalUID]: areUpdated
    }
  }),
  [`${topologyTypes.SAVE_PREFERENCE_FETCH_NEXT}_SUCCESS`]: (
    { result, goalUID },
    state
  ) => ({
    goalOnBoardingPreferences: {
      ...result
    },
    areGoalOnBoardingPreferencesUpdated: {
      ...state.areGoalOnBoardingPreferencesUpdated,
      [goalUID]: true
    }
  })
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case topologyTypes.UPDATE_TOPOLOGIES:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    case `${topologyTypes.FETCH_ONBOARDING_GOALS}_SUCCESS`:
      return {
        ...state,
        onboardingGoals: action.result
      };

    case `${topologyTypes.FETCH_GOAL_LANGUAGES}_SUCCESS`:
      return {
        ...state,
        goalLanguages: action.result
      };

    case `${topologyTypes.FETCH_GOAL_LANGUAGES}_FAILURE`:
      return {
        ...state,
        goalLanguages: null
      };

    case `${topologyTypes.FETCH_TOPOLOGY_CHILDREN}_SUCCESS`:
      return {
        ...state,
        topologyChildren: {
          ...state.topologyChildren,
          [action.topologyUid]: action.result
        }
      };

    case `${topologyTypes.FETCH_TOPOLOGY_INFO}_SUCCESS`:
      return {
        ...state,
        topologyDetails: {
          ...state.topologyDetails,
          [action.topologyUid]: action.result
        }
      };

    case `${topologyTypes.FETCH_TOPOLOGY_EXTRA_INFO}_SUCCESS`:
      return {
        ...state,
        topologyExtraInfo: {
          ...state.topologyExtraInfo,
          [action.topologyUid]: action.result
        }
      };

    case `${topologyTypes.FETCH_TOPOLOGY_CHILDREN_OF_FREE_COURSE}_SUCCESS`:
      return {
        ...state,
        freeTopologyChildren: {
          ...state.freeTopologyChildren,
          [action.topologyUid]: action.result
        }
      };

    case `${topologyTypes.FETCH_NEW_SYLLABUS_FEED}_SUCCESS`:
      return {
        ...state,
        newSyllabusData: action.data
      };

    case `${topologyTypes.FETCH_POPULAR_GOALS_GROUPS}_SUCCESS`:
      return {
        ...state,
        popularGoalsGroups: action.result
      };

    case `${topologyTypes.SET_GOAL_LANGUAGE}_SUCCESS`: {
      const { goalUid, languageText } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [goalUid]: { ...state.data[goalUid], language: languageText }
        }
      };
    }
    case `${topologyTypes.FETCH_NOTES}_SUCCESS`: {
      const { topologyUid, result } = action;
      return {
        ...state,
        topologyNotes: {
          ...state.topologyNotes,
          [topologyUid]: result
        }
      };
    }
    case `${savedTypes.FETCH_SAVED_COUNT}_SUCCESS`: {
      const { goalUid, result } = action;
      return {
        ...state,
        savedCount: {
          ...state.savedCount,
          [goalUid]: result
        }
      };
    }
    case `${educatorTypes.FETCH_ALL_LANGUAGES}_SUCCESS`:
      return { ...state, allLanguages: action.result };

    case `${topologyTypes.FETCH_PRESUBSCRIBED_STATIC_CONTENT}_SUCCESS`: {
      const { goalUid } = action.result || {};
      return {
        ...state,
        preSubscribedStaticContent: {
          ...state.preSubscribedStaticContent,
          [goalUid]: action.result
        }
      };
    }

    case `${topologyTypes.FETCH_POST_SUBSCRIPTION_ONBOARDING_PREFERENCES}_REQUEST`: {
      return {
        ...state,
        goalOnBoardingPreferences: {
          ...action.data
        }
      };
    }
    case `${topologyTypes.FETCH_POST_SUBSCRIPTION_ONBOARDING_PREFERENCES}_SUCCESS`: {
      return {
        ...state,
        goalOnBoardingPreferences: {
          ...action.result
        }
      };
    }
    case `${topologyTypes.FETCH_POST_SUBSCRIPTION_ONBOARDING_PREFERENCES}_FAILURE`: {
      return {
        ...state,
        goalOnBoardingPreferences: {
          ...action.result
        }
      };
    }
    case `${topologyTypes.FETCH_ALL_ONBOARDING_PREFERENCE_OF_A_USER}_SUCCESS`: {
      return {
        ...state,
        savedGoalOnBoardingPreferences: [...action.result]
      };
    }
    case `${topologyTypes.FETCH_GOAL_SYNONYMS}_SUCCESS`: {
      return {
        ...state,
        goalSynonyms: {
          ...state.goalSynonyms,
          ...action.result.data
        }
      };
    }
    default:
      // eslint-disable-next-line no-case-declarations
      const actionHandler = reducerMap[action.type];
      return actionHandler
        ? {
            ...state,
            ...actionHandler(action, state)
          }
        : state;
  }
};

export default reducer;
