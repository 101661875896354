import { getPreviewMode } from '@selectors/others';
import { getHost, HOST_TYPE } from '@utils/api-helper/host';

const hostMiddleware =
  ({ getState }) =>
  (next) =>
  (action = {}) => {
    const {
      endpoint,
      hostType = HOST_TYPE.DEFAULT,
      useAuthToken = true,
      ...rest
    } = action;

    if (!endpoint) {
      return next(action);
    }

    try {
      const updatedURL = new URL(endpoint);

      return next({
        ...rest,
        useAuthToken,
        endpoint: updatedURL.href
      });
    } catch (error) {
      const host = getHost(hostType);
      let updatedURL = `${host}${endpoint}`;

      if (hostType === HOST_TYPE.CMS) {
        const nextPreviewMode = getPreviewMode(getState());

        if (nextPreviewMode) {
          updatedURL += '?_publicationState=preview';
        }
      }

      return next({
        ...rest,
        endpoint: updatedURL,
        useAuthToken: hostType !== HOST_TYPE.CMS ? useAuthToken : false
      });
    }
  };

export default hostMiddleware;
