import { summitTypes } from '../constants';

const isRejected = (promise) => promise.status === 'rejected' && promise.reason;

const initialState = {
  info: {},
  events: [],
  schedule: [],
  isRegistered: false,
  speakers: [],
  faqs: [],
  staticTexts: {},
  topicGroupColorMap: {},
  metaData: {}
};

const reducerMap = {
  [`${summitTypes.FETCH_CONTENT}_SUCCESS`](state, { result }) {
    return {
      ...state,
      info: isRejected(result.info) ? {} : result.info,
      events: isRejected(result.events) ? [] : result.events,
      schedule: isRejected(result.schedule) ? [] : result.schedule,
      speakers: isRejected(result.speakers) ? [] : result.speakers,
      faqs: isRejected(result.faqs) ? [] : result.faqs,
      staticTexts: isRejected(result.texts) ? {} : result.texts,
      topicGroupColorMap: isRejected(result.topicGroupColorMap)
        ? {}
        : result.topicGroupColorMap,
      metaData: isRejected(result.metaData) ? {} : result.metaData
    };
  },
  [`${summitTypes.REGISTER}_SUCCESS`](state) {
    return {
      ...state,
      isRegistered: true
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
