import { quizTypes } from '../constants';

const initialState = {
  quizData: {},
  leaderboard: {},
  attempts: {},
  sessionData: {},
  quizRank: null,
  isFetching: false,
  isSubmitting: false,
  mappedGoals: [],
  dashboardFilter: 'pending',
  dashboardLanguage: null,
  availableLanguages: [],
  sortingOrder: { label: 'Recent first', value: 'recent_first' },
  selectedGoal: null,
  evalDashStats: {},
  evalQuestions: [],
  testSeries: {},
  isEditing: false,
  uploadDashboardGoals: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${quizTypes.FETCH_MY_QUIZ_ATTEMPTS}_SUCCESS`:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          [action.quizUid]: action.result
        }
      };
    // quiz creation -->

    case `${quizTypes.FETCH_REVIEW_STATS}_SUCCESS`:
      return {
        ...state,
        reviewStats: {
          ...state.reviewStats,
          [action.goalUID]: action.result
        }
      };

    case `${quizTypes.FETCH_DASHBOARD_GOALS}_SUCCESS`:
      return { ...state, mappedGoals: action.result };

    case quizTypes.UPDATE_DASHBOARD_FILTERS:
      return {
        ...state,
        dashboardFilter: action.state || state.dashboardFilter,
        selectedGoal: action.goal || state.selectedGoal,
        sortingOrder: action.sort || state.sortingOrder,
        dashboardLanguage: action.language || state.dashboardLanguage
      };

    case `${quizTypes.FETCH_EVAL_DASH_STATS}_SUCCESS`:
      return {
        ...state,
        evalDashStats: {
          ...state.evalDashStats,
          [action.goalUID]: action.result
        }
      };

    case `${quizTypes.FETCH_EVAL_DASH_GOALS}_SUCCESS`:
      return {
        ...state,
        mappedGoals: action.result.goals,
        availableLanguages: action.result.languages
      };

    case `${quizTypes.FETCH_TEST_SERIES_DATA}_SUCCESS`:
      return {
        ...state,
        testSeries: {
          ...state.testSeries,
          [action.testSeriesKey]: action.result
        }
      };

    case `${quizTypes.FETCH_GTP_TEST_SERIES_DATA}_SUCCESS`:
      return {
        ...state,
        gtpTestSeries: action.result
      };

    case `${quizTypes.SUBMIT_EVALUATION_SCORE}_SUCCESS`:
      return { ...state, isEditing: false };

    case `${quizTypes.FETCH_QUIZ_UPLOAD_DASHBOARD_GOALS}_SUCCESS`:
      return {
        ...state,
        uploadDashboardGoals: action.result
      };

    default:
      return state;
  }
};

export default reducer;
