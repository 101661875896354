import { mentorAssignedStatus } from '@constants/index';
import { iconicOnboardingTypes } from '../constants';

const initialState = {
  iconicInfo: {},
  userDetails: {},
  mentorDetails: {},
  mentorAssignedStatus: 0
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${iconicOnboardingTypes.FETCH_ICONIC_ONBOARDING_INFO}_SUCCESS`:
      return {
        ...state,
        iconicInfo: action.result
      };

    case `${iconicOnboardingTypes.FETCH_ICONIC_ONBOARDING_INFO}_FAILURE`:
      return {
        ...state,
        iconicInfo: []
      };

    case `${iconicOnboardingTypes.FETCH_USER_DETAILS}_SUCCESS`:
      return {
        ...state,
        userDetails: action.result
      };

    case `${iconicOnboardingTypes.BOOK_WELCOME_SESSION}_SUCCESS`:
      return {
        ...state,
        mentorDetails: action.result,
        mentorAssignedStatus: mentorAssignedStatus.MENTOR_ASSIGNED
      };

    case `${iconicOnboardingTypes.BOOK_WELCOME_SESSION}_FAILURE`:
      return {
        ...state,
        mentorDetails: {},
        mentorAssignedStatus: mentorAssignedStatus.MENTOR_READY_SOON
      };

    case `${iconicOnboardingTypes.FETCH_USER_DETAILS}_FAILURE`:
      return {
        ...state,
        userDetails: {}
      };

    case `${iconicOnboardingTypes.UPDATE_USER_DETAILS}_SUCCESS`:
      return state;

    case `${iconicOnboardingTypes.UPDATE_USER_DETAILS}_FAILURE`:
      return {
        ...state,
        userDetails: action.result
      };

    default:
      return state;
  }
};
export default reducer;
