/* eslint-disable max-lines */
import { emptyList } from '@constants/empty';
import {
  itemTypes,
  programmeTypes,
  topologyLevels,
  CourseLanguage,
  courseStates,
  TEST_TYPES,
  programmeSubTypes
} from '@constants/index';
import actionTypes from '@educator/Composite/DashBoard/DynamicBanners/constants';
import {
  ALL_DIFFICULTY_VALUE,
  difficultyValues,
  DPP_STATE
} from '@educator/Container/AutoDPP/constants';

import {
  COMPUTER_BASED,
  COURSE_TYPE_OFFLINE,
  COURSE_TYPE_ONLINE,
  EDUCATOR_TYPE_OPTIONS,
  PAPER_BASED,
  testModeOptions
} from '@educator/Container/CreatePlusCourse/constants';

import {
  getInitialselectedSubConceptsStates,
  getInitialSelectedDifficulty,
  removeSubConcept,
  getTotalSubConceptCount,
  getSubConceptsWithRank,
  updateQuestionOnShuffle,
  addFilterDifficulty,
  getSubConceptListNormalized,
  toggleIsSelected,
  patchSubConceptCountInfo,
  getSelectedDifficultyCount
} from '@educator/Container/AutoDPP/utils';
import { getIsOfflineTestSeries } from '@educator/Container/InputFields/utils';
import {
  COURSE_TYPE_ONLINE_SPECIAL_CLASS,
  COURSE_TYPE_ONLINE_SPECIAL_CLASS_WITH_EXTERNAL
} from '@educator/Container/CreateSpecialClass/constants';
import { educatorTypes } from '../constants';

const initialState = {
  isStep2Complete: false,
  isSpecialClass: false,
  programmeType: '',
  courseState: '',
  isSubmitting: false,
  isFreeTest: false,
  isCombat: false,
  testType: null,
  onBoardingPreferences: {},
  title: '',
  description: '',
  preview: '',
  selectedClasses: [],
  thumbnail: '',
  courseUID: '',
  forPlus: false,
  allLanguages: [],
  selectedLanguages: [],
  selectedGoal: [],
  visibleFrom: '',
  selectedTopics: [],
  selectedTopicGroups: [],
  selectedExams: [],
  selectedEducators: [],
  sessions: [],
  speciaClassDuration: '',
  spclClassUID: '',
  spclClassSlug: '',
  spclClassError: {},
  courseTypes: [],
  courseType: 0,
  liveAt: new Date().getTime(),
  coAuthors: [],
  deleting: false,
  createEditError: {},
  createEditSessionError: {},
  filter: {},
  editSessionError: {},
  newSession: {
    title: '',
    liveAt: '',
    videoId: '',
    selectedConcepts: [],
    selectedSubConcepts: [],
    selectedEducator: null,
    selectedCoAuthor: null,
    state: 0,
    isLoading: false,
    isSessionOffline: COURSE_TYPE_ONLINE,
    room: null,
    venue: '',
    mode: '',
    selectedTA: null
  },
  liveClassData: {},
  courseDetails: {
    rescheduleDetails: {
      date: '',
      time: '',
      reasonCode: [],
      remarks: '',
      policy: null
    },
    discussionForum: {}
  },
  goliveDetails: null,
  feedBackOptionsForATemplateCourse: {},
  clashSessions: [],
  areSessionsClashing: false,
  schedule: {
    startsFrom: '',
    time: '',
    daysOfWeek: [0, 0, 0, 0, 0, 0, 0],
    duration: ''
  },
  communityShareData: {},
  communityDetails: {},
  communityOnboarding: {},
  faqLevels: [],
  introlLevels: [],
  levelConfig: [],
  metaInfo: {},
  goalUidStats: '',
  levelInfo: {},
  levelStats: {},
  levelWiseEducators: {},
  schedulerAwarenessInfo: {},
  bannersInfo: null,
  dpp: {
    coursePageLink: '',
    isLoading: true,
    isShuffling: false,
    hasUnsavedChanges: false,
    questions: [],
    questionOrder: [],
    feedback_options: {},
    reportInfo: {},
    data: {},
    isSharedToCommunity: false,
    isFeedbackSubmitted: false
  },
  isSessionEditable: false,
  courseTypeOnlineOrOffline: COURSE_TYPE_ONLINE,
  offlineCities: [],
  offlineCenters: [],
  offlineSelectedCity: [],
  offlineSelectedCenter: [],
  rooms: {},
  selectedRooms: {},
  autoscheduleInfo: { selectedRoom: null },
  isOfflineCourse: false,
  isOfflineTestSeries: false,
  isTAPowered: false,
  allSpecialClassTypes: [],
  selectedSpecialClassType: [],
  selectedSubConcepts: []
};

const getSession = ({ value, rank, type }) => {
  if (!value) return null;
  const liveAt = new Date(
    type === itemTypes.POST ? value.live_class.live_at : value.start_time
  ).getTime();
  const classType =
    type === itemTypes.POST ? value.live_class.class_type : null;
  const videoId =
    type === itemTypes.POST ? value.live_class.video_library_video_uid : null;
  return {
    name: value.title,
    uid: value.uid,
    author: { ...value.author, ...value.author.author_details },
    rank,
    type,
    classType,
    videoId,
    quizSecondaryType: value.quiz_secondary_type,
    state: value.state || value.live_class.state,
    thumbnail: value.live_class?.post_thumbnail,
    duration: value.duration || value.live_class.duration,
    scheduledLiveAt: liveAt,
    liveAt,
    selectedConcepts: value.topologies,
    contentState: value.content_state || null,
    creationType: value.creation_type || null,
    quizType: value.quiz_type,
    availableLanguage: value.available_language || [],
    educatorSlides:
      value.live_class?.educator_slides || value.live_class?.drive_details,
    learnerNotes: value.live_class?.learner_notes,
    practice_quiz: value.live_class?.practice_quiz,
    room: value.live_class?.room,
    offlineQuizDetails: value.offline_quiz_details,
    isOfflineTest: value.is_offline,
    teachingAssistant: value.live_class?.teaching_assistant
  };
};

const getCourseOfflineProps = (result) => {
  const programme = result.programme || result.collection?.programme;
  let extraPropsForOfflineCourse = { isOfflineCourse: false };

  if (programme.programme_type === programmeTypes.HYBRID_COURSE) {
    const { city } = programme.centre;

    extraPropsForOfflineCourse = {
      centre: programme.centre,
      isOfflineCourse: true,
      offlineSelectedCenter: [programme.centre],
      offlineSelectedCity: [
        { uid: city.uid, label: city.name, name: city.name }
      ],
      courseTypeOnlineOrOffline: COURSE_TYPE_OFFLINE
    };
  }
  if (getIsOfflineTestSeries(programme.programme_type)) {
    extraPropsForOfflineCourse = {
      isOfflineTestSeries: true,
      courseTypeOnlineOrOffline: COURSE_TYPE_OFFLINE
    };
  }

  return extraPropsForOfflineCourse;
};

const getCollectionData = (result) => {
  const programme = result.programme || result.collection?.programme;
  const forPlus = result.for_plus ?? result.session?.value?.for_plus;
  const languages = (result.languages || result.collection?.languages).map(
    (lang) => ({
      ...lang,
      code: lang.id
    })
  );
  const { value = {} } = result.items?.[0] || result.session || {};
  const isSpecial = !!(
    forPlus &&
    !programme.for_subscription &&
    (programme.programme_type === programmeTypes.COURSE ||
      programme.programme_type === programmeTypes.ONLINE_WITH_EXTERNAL_STREAM)
  );
  const isFreeTest =
    programme.programme_type === programmeTypes.TESTSERIES &&
    !programme.for_subscription;
  const autoscheduleInfo = {
    isAutoScheduleDone: result.programme?.is_autoschedule_enable
      ? !!result.programme?.autoschedule_duration
      : true,
    isAutoScheduleEnabled: result.programme?.is_autoschedule_enable,
    autoscheduleFrequency: result.programme?.autoschedule_frequency,
    autoscheduleDuration: result.programme?.autoschedule_duration,
    autoscheduleLiveAt: result.programme?.autoschedule_live_at,
    autoScheduleRoomUID: programme.autoschedule_room_uid,
    selectedRoom: programme.autoschedule_room_uid
  };
  const newSession = {
    ...initialState.newSession,
    isSessionOffline: [
      programmeTypes.HYBRID_COURSE,
      programmeTypes.HYBRID_TESTSERIES
    ].includes(programme.programme_type)
      ? COURSE_TYPE_OFFLINE
      : COURSE_TYPE_ONLINE,
    mode: getIsOfflineTestSeries(programme.programme_type) ? COMPUTER_BASED : ''
  };
  const extraPropsForOfflineCourse = getCourseOfflineProps(result);
  const selectedClasses = result.classes || result.collection?.classes;
  const sessionValue = result?.session?.value || result?.items?.[0]?.value;
  const specialClassTA = sessionValue?.live_class?.teaching_assistant ?? {};

  return {
    programmeType: programme.programme_type,
    spclClassSlug: programme.slug,
    isCombat: programme.programme_type === programmeTypes.CONTEST,
    spclClassUID: value.uid,
    speciaClassDuration: value.live_class?.duration,
    videoURL: value.live_class?.video_url,
    selectedClasses,
    isStep2Complete: selectedClasses.length > 0,
    forPlus,
    isSpecial,
    isSpecialClass: isSpecial,
    canAddRecordingClasses: result.is_video_library_tagging_allowed,
    courseUID: result.uid || result.collection?.uid,
    thumbnail: result.thumbnail || result.collection?.thumbnail,
    selectedGoal: [
      {
        ...programme.goal,
        goal_name: programme.goal?.name,
        goal_uid: programme.goal?.uid
      }
    ],
    isAutoScheduleEnabled: result.is_autoschedule_enable,
    courseType: result.course_type || result.collection?.course_type,
    selectedTopics: result.topics || result.collection?.topics,
    selectedTopicGroups: result.collection?.topic_groups,
    selectedExams: result.exams || result.collection?.exams,
    preview: result.intro_video || result.programme?.intro_video,
    slug: programme.slug,
    autoscheduleInfo,
    selectedLanguages:
      // ? languages
      languages.filter(
        (lang) => lang.language_type === CourseLanguage.SPOKEN_LANGUAGE
      ),
    contentSlideLanguages: languages.filter(
      (lang) => lang.language_type === CourseLanguage.CONTENT_SLIDE_LANGUAGES
    ),
    selectedEducatorType: EDUCATOR_TYPE_OPTIONS.filter(
      (option) => option.id === programme.educator_type
    ),
    isFreeTest,
    courseState: programme.state,
    templateUID: programme.template_id,
    visibleFrom: new Date(programme.visible_at).getTime(),
    liveAt: new Date(value.live_class?.live_at).getTime(),
    registrationClosesOn: new Date(programme.applications_close_at).getTime(),
    description: (
      result.description || result.collection?.description
    ).substring(
      0,
      programme.programme_type !== programmeTypes.TESTSERIES ? 500 : 2000
    ),
    slides: {
      withAnnotations: value.live_class?.slides_pdf?.with_annotation,
      withoutAnnotations: value.live_class?.slides_pdf?.no_annotation
    },
    selectedEducators: programme.authors.map((author) => ({
      ...author,
      ...author.author_details,
      name: `${author.first_name} ${author.last_name}`
    })),
    sessions: (result.items || result.classes)
      ?.map(getSession)
      .filter((item) => item),
    isSessionEditable: !!programme.is_editable,
    isCompleted: programme.state === courseStates.COMPLETED,
    testType: TEST_TYPES.find(
      ({ value: subtype }) => subtype === programme.programme_subtype
    ),
    classType: value.live_class?.class_type,
    courseTypeSpecialClass:
      programme.programme_type === programmeTypes.ONLINE_WITH_EXTERNAL_STREAM
        ? COURSE_TYPE_ONLINE_SPECIAL_CLASS_WITH_EXTERNAL
        : COURSE_TYPE_ONLINE_SPECIAL_CLASS,
    videoId: value?.live_class?.video_library_video_uid,
    ...extraPropsForOfflineCourse,
    newSession,
    selectedTAForSpecialClass: specialClassTA.uid
      ? {
          avatar: specialClassTA.avatar,
          id: specialClassTA.username,
          name: `${specialClassTA.first_name} ${specialClassTA.last_name}`,
          uid: specialClassTA.uid,
          username: specialClassTA.username
        }
      : null,
    title: isSpecial ? value.title : result.name || result.collection?.name,
    isTAPowered: programme.programme_subtype === programmeSubTypes.TA_COURSE,
    isSellable: programme.is_sellable,
    testFrequency: programme.test_frequency,
    brochure: programme.brochure,
    selectedSpecialClassType: [sessionValue?.special_class_type],
    selectedSubConcepts: sessionValue?.topologies?.filter(
      (item) => item.level === topologyLevels.SUBCONCEPT
    )
  };
};

const reorderSessions = (sessions, rank, new_rank) => {
  const sessionsCopy = [...sessions];
  const fromIndex = rank - 1;
  const toIndex = new_rank - 1;
  const currSession = sessionsCopy[fromIndex];
  sessionsCopy.splice(fromIndex, 1);
  sessionsCopy.splice(toIndex, 0, currSession);
  return sessionsCopy.map((session, index) => {
    return { ...session, rank: index + 1 };
  });
};

const reducerMap = {
  [`${educatorTypes.GET_RESCHEDULE_INFO}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      courseDetails: {
        ...state.courseDetails,
        rescheduleDetails: {
          ...state.courseDetails.rescheduleDetails,
          policy: action.result
        }
      }
    };
  },
  [`${educatorTypes.RESET_RESCHEDULE_REQUEST_WARNING}`]: (state) => {
    return {
      ...state,
      courseDetails: {
        ...state.courseDetails,
        rescheduleDetails: {
          ...state.courseDetails.rescheduleDetails,
          policy: null
        }
      }
    };
  },
  [`${educatorTypes.GET_FAQ_LEVELS}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      faqLevels: JSON.parse(action.result.description).educatorLevels
    };
  },
  [`${educatorTypes.GET_LEVELS_INTRO}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      introlLevels: JSON.parse(action.result.description).web
    };
  },
  [`${educatorTypes.GET_LEVEL_CONFIG}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      levelConfig: action.result
    };
  },
  [`${educatorTypes.GET_META_INFO}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      metaInfo: action.result
    };
  },
  [`${educatorTypes.GET_DPP_REMINDER_CARD_STATUS}_SUCCESS`]: (
    state,
    action
  ) => {
    return {
      ...state,
      dppReminderStatus: action.result.dpp_feature_show_reminder
    };
  },
  [`${educatorTypes.GET_LEVEL_INFO}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      levelInfo: action.result
    };
  },
  [`${educatorTypes.UPDATE_LEVEL_INFO}`]: (state, data) => {
    return {
      ...state,
      levelInfo: data.payload
    };
  },
  [`${educatorTypes.GET_LEVEL_STATS}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      levelStats: action.result
    };
  },
  [`${educatorTypes.GET_LEVEL_WISE_EDUCATOR}_REQUEST`]: (state, action) => {
    return {
      ...state,
      levelWiseEducators: action.resetResults
        ? { isFetching: true }
        : { ...state.levelWiseEducators, isFetching: true }
    };
  },
  [`${educatorTypes.GET_LEVEL_WISE_EDUCATOR}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      levelWiseEducators: {
        ...state.levelWiseEducators,
        next: action.result.next,
        isFetching: false,
        results: state.levelWiseEducators.results
          ? [...state.levelWiseEducators.results, ...action.result.results]
          : [...action.result.results]
      }
    };
  },
  [`${educatorTypes.UPDATE_GOALS_FOR_STATS}`]: (state, data) => {
    return {
      ...state,
      goalUidStats: data.payload
    };
  },
  [`${educatorTypes.FETCH_COMMUNITY_SHARE_CARD_INFO}_SUCCESS`]: (
    state,
    action
  ) => {
    return {
      ...state,
      communityShareData: JSON.parse(action.result.description)
    };
  },
  [`${educatorTypes.FETCH_COMMUNITY_DETAILS}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      communityDetails: action.result
    };
  },
  [`${educatorTypes.FETCH_EDUCATOR_SCHEDULER_AWARENESS_INFO}_SUCCESS`]: (
    state,
    action
  ) => {
    return {
      ...state,
      schedulerAwarenessInfo: JSON.parse(action.result.description)
    };
  },
  [`${educatorTypes.EDUCATOR_MODAL_TYPE}`](state, action) {
    const {
      data: { type, data }
    } = action;

    return {
      ...state,
      modal: {
        type,
        data
      }
    };
  },
  [`${educatorTypes.FETCH_DPP_QUESTIONS}_REQUEST`](state) {
    return {
      ...state,
      dpp: { ...state.dpp, isLoading: true }
    };
  },
  [`${educatorTypes.FETCH_DPP_QUESTIONS}_SUCCESS`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        questions: action.result,
        isLoading: false,
        questionOrder: action.result
      }
    };
  },
  [`${educatorTypes.FETCH_DPP_QUESTIONS}_FAILURE`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        questions: [],
        isLoading: false,
        hasUnsavedChanges: false
      }
    };
  },
  [`${educatorTypes.UPDATE_DPP_QUESTION_ON_SHUFFLE}_REQUEST`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        isShuffling: true,
        questions: updateQuestionOnShuffle(true, state.dpp.questions),
        questionOrder: updateQuestionOnShuffle(true, state.dpp.questionOrder)
      }
    };
  },
  [`${educatorTypes.UPDATE_DPP_QUESTION_ON_SHUFFLE}_SUCCESS`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        isShuffling: false,
        questions: updateQuestionOnShuffle(
          false,
          state.dpp.questions,
          action.result,
          action.questionUID
        ),
        questionOrder: updateQuestionOnShuffle(
          false,
          state.dpp.questionOrder,
          action.result,
          action.questionUID
        )
      }
    };
  },
  [`${educatorTypes.UPDATE_DPP_QUESTION_ON_SHUFFLE}_FAILURE`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        isShuffling: false,
        questions: updateQuestionOnShuffle(false, state.dpp.questions),
        questionOrder: updateQuestionOnShuffle(false, state.dpp.questionOrder)
      }
    };
  },
  [`${educatorTypes.FETCH_DPP_QUIZ_INFO}_SUCCESS`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...action.result,
          filters: {
            ...action.result.filters,
            sub_concepts_filter: getSubConceptsWithRank(
              action.result.filters.sub_concepts_filter
            )
          },
          selectedFilters: {
            selectedCount:
              action.result.filters.number_of_questions.selected_value,
            selectedSubConcepts: getInitialselectedSubConceptsStates(
              action.result.filters.sub_concepts_filter
            ),
            selectedDifficulty: getInitialSelectedDifficulty(
              action.result.filters.difficulty
            ),
            totalSubConceptCount: getTotalSubConceptCount(
              action.result.filters.sub_concepts_filter
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.SET_SUBCONCEPT_NORMALIZED_DATA}`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          filters: {
            ...state.dpp.data.filters,
            ...getSubConceptListNormalized(
              state.dpp.data.filters.sub_concepts_filter
            ),
            isDataNormalized: true
          }
        }
      }
    };
  },
  [`${educatorTypes.UPDATE_DPP_FILTER_QUESTION_NUMBER}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedCount: action.value
          }
        }
      }
    };
  },
  [`${educatorTypes.ADD_DPP_FILTER_DIFFICULTY}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedDifficulty: addFilterDifficulty(
              action.selectedCode,
              state.dpp.data.selectedFilters.selectedDifficulty
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.REMOVE_DPP_FILTER_DIFFICULTY}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedDifficulty:
              state.dpp.data.selectedFilters.selectedDifficulty.filter(
                (difficultyCode) =>
                  difficultyCode !== action.selectedCode &&
                  difficultyCode !== ALL_DIFFICULTY_VALUE
              )
          }
        }
      }
    };
  },
  [`${educatorTypes.SELECT_ALL_DIFFICULTIES}`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedDifficulty: difficultyValues
          }
        }
      }
    };
  },
  [`${educatorTypes.REMOVE_ALL_DIFFICULTIES}`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedDifficulty: emptyList
          }
        }
      }
    };
  },
  [`${educatorTypes.ADD_DPP_SUBCONCEPT}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedSubConcepts: {
              ...state.dpp.data.selectedFilters.selectedSubConcepts,
              [action.data.id]: {
                rank: action.data.rank,
                name: action.data.name
              }
            }
          }
        }
      }
    };
  },
  [`${educatorTypes.ADD_SUBCONCEPT_BY_UID}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          filters: {
            ...state.dpp.data.filters,
            subConceptMap: toggleIsSelected(
              state.dpp.data.filters.subConceptMap,
              action.uid,
              true
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.REMOVE_SUBCONCEPT_BY_UID}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          filters: {
            ...state.dpp.data.filters,
            subConceptMap: toggleIsSelected(
              state.dpp.data.filters.subConceptMap,
              action.uid,
              false
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.REMOVE_DPP_SUBCONCEPT}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          selectedFilters: {
            ...state.dpp.data.selectedFilters,
            selectedSubConcepts: removeSubConcept(
              state.dpp.data.selectedFilters.selectedSubConcepts,
              action.data.id
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.RESET_FILTER}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          filters: {
            ...state.dpp.data.filters,
            sub_concepts_filter: [...state.dpp.data.filters.sub_concepts_filter]
          },
          selectedFilters: {
            ...action.data
          }
        }
      }
    };
  },
  [`${educatorTypes.UPDATE_DPP_QUESTION_ORDER}`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        questionOrder: action.questionOrder,
        hasUnsavedChanges: true
      }
    };
  },
  [`${educatorTypes.SAVE_DRAFT_DPP_QUESTION}_SUCCESS`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        questions: state.dpp.questionOrder,
        hasUnsavedChanges: false
      }
    };
  },
  [`${educatorTypes.RESET_DPP_QUESTION_ORDER}`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        questionOrder: state.dpp.questions,
        hasUnsavedChanges: false
      }
    };
  },
  [`${educatorTypes.FETCH_DPP_REPORT_QUESTION_INFO}_SUCCESS`](state, action) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        reportInfo: action.result
      }
    };
  },
  [`${educatorTypes.PUBLISH_DPP_QUIZ}_SUCCESS`](state) {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          state: DPP_STATE.PUBLISHED
        }
      }
    };
  },
  [`${educatorTypes.FETCH_COMMUNITY_ONBOARDING}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      communityOnboarding: JSON.parse(action.result.description)
    };
  },
  [`${educatorTypes.SET_COURSE_PAGE_LINK}`]: (state, action) => {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        coursePageLink: action.link
      }
    };
  },
  [`${educatorTypes.FETCH_DPP_SUBCONCEPT_COUNT}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          filters: {
            ...state.dpp.data.filters,
            subConceptMap: patchSubConceptCountInfo(
              state.dpp.data.filters.subConceptMap,
              action.result
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.SET_SELECTED_DIFFICULTY_COUNT}`]: (state, action) => {
    return {
      ...state,
      dpp: {
        ...state.dpp,
        data: {
          ...state.dpp.data,
          filters: {
            ...state.dpp.data.filters,
            selectedDifficultyCount: getSelectedDifficultyCount(
              action.selectedList
            )
          }
        }
      }
    };
  },
  [`${educatorTypes.SHARE_DPP_IN_COMMUNITY}_REQUEST`]: (state) => {
    return {
      ...state,
      dpp: { ...state.dpp, isSharedToCommunity: true }
    };
  },
  [`${educatorTypes.SUBMIT_DPP_PRACTICE_FEEDBACK}_SUCCESS`]: (state) => {
    return {
      ...state,
      dpp: { ...state.dpp, isFeedbackSubmitted: true }
    };
  },
  [`${educatorTypes.SET_TEST_TYPE}`]: (state, action) => {
    return {
      ...state,
      testType: action.option
    };
  },
  [`${educatorTypes.SET_TEST_FREQUENCY}`]: (state, action) => {
    return {
      ...state,
      testFrequency: action.option
    };
  },
  [`${educatorTypes.SET_BROCHURE}`]: (state, action) => {
    return {
      ...state,
      brochure: action.option
    };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${educatorTypes.FETCH_EDUCATOR_LEADERBOARD}_REQUEST`:
      return { ...state, isSubmitting: action.resetWithoutEmpty };

    case educatorTypes.UPDATE_SESSION_CONCEPTS:
    case educatorTypes.UPDATE_SESSION_VALUE:
    case educatorTypes.SET_SESSION_EDUCATOR:
      return {
        ...state,
        newSession: { ...state.newSession, [action.id]: action.value }
      };
    case educatorTypes.SET_TA_EDUCATOR_SC:
      return {
        ...state,
        selectedTAForSpecialClass: action.value
      };
    case educatorTypes.UPDATE_COURSE_AUTOCOMPLETE_FIELDS:
    case educatorTypes.UPDATE_COURSE_DROPDOWN_FIELDS:
    case educatorTypes.UPDATE_COURSE_DETAILS:
    case educatorTypes.UPDATE_CHECK_BOX:
    case educatorTypes.UPDATE_RADIO_BOX:
      return {
        ...state,
        [action.id]: action.isSingleValue ? [action.value] : action.value
      };

    case educatorTypes.UPDATE_RADIO_BOX_SESSION:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          [action.id]: action.value
        }
      };
    case educatorTypes.UPDATE_TA_POWERED_STATE:
      return {
        ...state,
        isTAPowered: !state.isTAPowered
      };
    case educatorTypes.UPDATE_VENUE_FOR_SESSION:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          [action.id]: action.value
        }
      };
    case educatorTypes.UPDATE_SESSION_DROPDOWN_FIELDS:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          [action.id]: action.value
        }
      };

    case educatorTypes.UPDATE_COURSE_DETAILS_DATA:
      return {
        ...state,
        ...action.value
      };

    case educatorTypes.REMOVE_CONCEPT:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          [action.key]: state.newSession[action.key].filter(
            (option) => option.uid !== action.value.uid
          )
        }
      };

    case educatorTypes.REMOVE_TAG:
      return {
        ...state,
        [action.id]: state[action.id]?.filter(
          (option) => option[action.key] !== action.value[action.key]
        )
      };

    case `${educatorTypes.EDIT_SESSION}_REQUEST`:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          isLoading: true
        }
      };
    case `${educatorTypes.EDIT_SESSION}_SUCCESS`:
    case `${educatorTypes.EDIT_SESSION}_FAILURE`:
      return {
        ...state,
        isSubmitting: false,
        editSessionError: action.error,
        newSession: {
          ...state.newSession,
          isLoading: false,
          isSessionOffline:
            state.isOfflineCourse || state.isOfflineTestSeries
              ? COURSE_TYPE_OFFLINE
              : COURSE_TYPE_ONLINE,
          mode: state.isOfflineTestSeries ? COMPUTER_BASED : ''
        }
      };

    case `${educatorTypes.STATE_CHANGE}_SUCCESS`:
      return {
        ...state,
        courseState: action.result.programme.state,
        isSubmitting: false
      };

    case educatorTypes.SETUP_EDIT_SESSION: {
      const { value } = action;
      const { room, offlineQuizDetails, isOfflineTest } = value || {};
      const { mode, venue } = isOfflineTest ? offlineQuizDetails : {};
      let offlineCourseExtraProps = {};

      if (value?.uid) {
        if (room?.uid || isOfflineTest) {
          offlineCourseExtraProps = {
            isSessionOffline: COURSE_TYPE_OFFLINE,
            room,
            mode:
              mode === testModeOptions[COMPUTER_BASED]
                ? COMPUTER_BASED
                : PAPER_BASED,
            venue
          };
        } else {
          offlineCourseExtraProps = {
            isSessionOffline: COURSE_TYPE_ONLINE,
            room
          };
        }
      } else {
        offlineCourseExtraProps = {
          isSessionOffline: COURSE_TYPE_OFFLINE
        };
      }
      return {
        ...state,
        newSession: value
          ? {
              ...state.newSession,
              ...value,
              title: value.name || value.title,
              selectedEducator: value.author
                ? {
                    ...value.author,
                    name: `${value.author.first_name} ${value.author.last_name}`
                  }
                : null,
              selectedTA: value?.teachingAssistant
                ? {
                    ...value?.teachingAssistant,
                    name: `${value?.teachingAssistant?.first_name} ${value?.teachingAssistant?.last_name}`
                  }
                : null,
              duration: value.duration,
              liveAt: value.liveAt,
              type: value.type,
              uid: value.uid,
              selectedConcepts: value.selectedConcepts.filter(
                (concept) => concept.level === topologyLevels.CONCEPT
              ),
              selectedSubConcepts: value.selectedConcepts.filter(
                (concept) => concept.level === topologyLevels.SUBCONCEPT
              ),
              state: value.state,
              ...offlineCourseExtraProps
            }
          : {
              ...initialState.newSession,
              isSessionOffline:
                state.isOfflineCourse || state.isOfflineTestSeries
                  ? COURSE_TYPE_OFFLINE
                  : COURSE_TYPE_ONLINE,
              mode: state.isOfflineTestSeries ? COMPUTER_BASED : ''
            }
      };
    }

    case `${educatorTypes.FETCH_ALL_LANGUAGES}_SUCCESS`:
    case `${educatorTypes.FETCH_CO_AUTHOR}_SUCCESS`:
    case `${educatorTypes.FETCH_COURSE_TYPES}_SUCCESS`:
    case educatorTypes.SET_LESSON_REFS:
    case educatorTypes.SET_COURSE_TYPE:
    case `${educatorTypes.FETCH_GOLIVE_DETAILS}_SUCCESS`:
    case `${educatorTypes.FETCH_EDU_LIVE_CLASS_URL}_SUCCESS`:
    case `${educatorTypes.GET_FEEDBACK_FOR_COURSE_TEMPLATE}_SUCCESS`:
    case educatorTypes.SET_CREATE_EDIT_SESSION_ERROR_FLAG:
    case educatorTypes.CLEAR_COURSE_UID:
      return {
        ...state,
        [action.key]: action.result
      };

    case `${educatorTypes.GET_ON_BOARDING_PREFERENCES}_SUCCESS`:
      return {
        ...state,
        onBoardingPreferences: {
          ...state.onBoardingPreferences,
          [action.goalUID]: action.result
        }
      };

    case `${educatorTypes.DELETE_ITEM}_REQUEST`:
      return { ...state, deleting: true };

    case `${educatorTypes.DELETE_ITEM}_SUCCESS`:
      return {
        ...state,
        deleting: false,
        sessions: state.sessions.filter(
          (session) => session.uid !== action.session.uid
        )
      };

    case `${educatorTypes.ADD_ITEM}_REQUEST`:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          isLoading: true
        }
      };

    case `${educatorTypes.ADD_ITEM}_SUCCESS`:
      return {
        ...state,
        isSubmitting: false,
        newSession: {
          ...initialState.newSession,
          isSessionOffline:
            state.isOfflineCourse || state.isOfflineTestSeries
              ? COURSE_TYPE_OFFLINE
              : COURSE_TYPE_ONLINE,
          mode: state.isOfflineTestSeries ? COMPUTER_BASED : ''
        }
      };

    case `${educatorTypes.ADD_ITEM}_FAILURE`:
      return {
        ...state,
        newSession: {
          ...state.newSession,
          isLoading: false
        },
        createEditSessionError: action.error
      };

    case `${educatorTypes.DELETE_ITEM}_FAILURE`:
      return { ...state, deleting: false };

    case `${educatorTypes.REORDER_COURSE_SESSIONS}_REQUEST`:
      return {
        ...state,
        sessions: reorderSessions(state.sessions, action.rank, action.new_rank)
      };

    case `${educatorTypes.REORDER_COURSE_SESSIONS}_FAILURE`:
      return {
        ...state,
        sessions: reorderSessions(state.sessions, action.new_rank, action.rank)
      };

    case `${educatorTypes.CREATE_PLUS_COURSE}_FAILURE`:
    case `${educatorTypes.EDIT_PLUS_COURSE}_FAILURE`:
    case `${educatorTypes.CREATE_TEST_SERIES_COURSE}_FAILURE`:
    case `${educatorTypes.EDIT_SPECIAL_CLASS}_FAILURE`:
    case `${educatorTypes.CREATE_SPECIAL_CLASS}_FAILURE`:
    case `${educatorTypes.EDIT_TEST_SERIES_COURSE}_FAILURE`:
    case `${educatorTypes.EDIT_TEMPLATE_COURSE}_FAILURE`:
    case `${educatorTypes.ADD_TEMPLATE_COURSE}_FAILURE`:
    case `${educatorTypes.ADD_TEMPLATE_COURSE}_SUCCESS`:
    case `${educatorTypes.STATE_CHANGE}_FAILURE`:
      return {
        ...state,
        isSubmitting: false,
        createEditError: action.error
      };

    case `${educatorTypes.CREATE_PLUS_COURSE}_SUCCESS`:
    case `${educatorTypes.FETCH_COURSE_DETAILS}_SUCCESS`:
    case `${educatorTypes.EDIT_PLUS_COURSE}_SUCCESS`:
    case `${educatorTypes.CREATE_TEST_SERIES_COURSE}_SUCCESS`:
    case `${educatorTypes.EDIT_TEST_SERIES_COURSE}_SUCCESS`:
      return {
        ...state,
        isSubmitting: false,
        createEditError: false,
        ...getCollectionData(action.result)
      };

    case `${educatorTypes.AUTO_SCHEDULE_COURSE_SESSIONS}_SUCCESS`:
      return {
        ...state,
        isSubmitting: false,
        createEditError: false,
        autoscheduleInfo: {
          isAutoScheduleDone: true,
          isAutoScheduleEnabled: true,
          autoscheduleLiveAt: action.ISODate,
          autoscheduleDuration: action.schedule.duration,
          autoscheduleFrequency: action.schedule.days,
          selectedRoom: action.autoschedule_room_uid
        },
        clashSessions: [],
        sessions: action.result.map(getSession)
      };

    case `${educatorTypes.FETCH_TEMPLATE_DETAILS}_SUCCESS`:
      return {
        ...state,
        selectedGoal: [action.result.goal],
        selectedTopics: action.result.topics,
        selectedExams: action.result.exams,
        selectedTopicGroups: action.result.topicGroups
      };

    case `${educatorTypes.EDIT_SPECIAL_CLASS}_SUCCESS`:
    case `${educatorTypes.CREATE_SPECIAL_CLASS}_SUCCESS`:
      // case `${educatorTypes.ADD_TEMPLATE_COURSE}_SUCCESS`:
      return {
        ...state,
        // ...action.result,
        ...getCollectionData(action.result),
        isSubmitting: false,
        createEditError: false
      };

    case `${educatorTypes.FETCH_TEMPLATE_COURSE_DETAILS}_SUCCESS`:
    case educatorTypes.SETUP_TEMPLATE_DATA_IN_EDU_COURSE:
    case educatorTypes.EDIT_TEMPLATE_COURSE_LESSON_WITHOUT_UID:
      return {
        ...state,
        ...action.result
      };

    case `${educatorTypes.STATE_CHANGE}_REQUEST`:
    case `${educatorTypes.CREATE_PLUS_COURSE}_REQUEST`:
    case `${educatorTypes.EDIT_TEST_SERIES_COURSE}_REQUEST`:
    case `${educatorTypes.EDIT_PLUS_COURSE}_REQUEST`:
    case `${educatorTypes.CREATE_TEST_SERIES_COURSE}_REQUEST`:
    case `${educatorTypes.EDIT_SPECIAL_CLASS}_REQUEST`:
    case `${educatorTypes.CREATE_SPECIAL_CLASS}_REQUEST`:
    case `${educatorTypes.UPLOAD_THUMBNAIL}_REQUEST`:
    case `${educatorTypes.REMOVE_BROCHURE}_REQUEST`:
    case `${educatorTypes.UPLOAD_BROCHURE}_REQUEST`:
    case `${educatorTypes.CREATE_LIVE_SUPPORT_TICKET}_REQUEST`:
    case `${educatorTypes.ADD_TEMPLATE_COURSE}_REQUEST`:
    case `${educatorTypes.EDIT_TEMPLATE_COURSE}_REQUEST`:
    case `${educatorTypes.FETCH_COURSE_RATINGS}_REQUEST`:
    case `${educatorTypes.AUTO_SCHEDULE_COURSE_SESSIONS}_REQUEST`:
      return {
        ...state,
        isSubmitting: true
      };

    case `${educatorTypes.UPLOAD_THUMBNAIL}_SUCCESS`:
      return {
        ...state,
        thumbnail: action.result.thumbnail_image,
        isSubmitting: false
      };

    case `${educatorTypes.REMOVE_BROCHURE}_SUCCESS`:
      return {
        ...state,
        brochure: null,
        isSubmitting: false
      };

    case `${educatorTypes.FETCH_EDUCATOR_LEADERBOARD}_SUCCESS`:
    case `${educatorTypes.FETCH_EDUCATOR_LEADERBOARD}_FAILURE`:
    case `${educatorTypes.UPLOAD_THUMBNAIL}_FAILURE`:
    case `${educatorTypes.REMOVE_BROCHURE}_FAILURE`:
    case `${educatorTypes.CREATE_LIVE_SUPPORT_TICKET}_SUCCESS`:
    case `${educatorTypes.CREATE_LIVE_SUPPORT_TICKET}_FAILURE`:
    case `${educatorTypes.FETCH_COURSE_RATINGS}_FAILURE`:
    case `${educatorTypes.AUTO_SCHEDULE_COURSE_SESSIONS}_FAILURE`:
      return {
        ...state,
        isSubmitting: false
      };
    case `${educatorTypes.EDIT_TEMPLATE_COURSE}_SUCCESS`:
      return {
        ...state,
        isSubmitting: false,
        ...getCollectionData(action.result.collection)
      };
    case educatorTypes.CLEAR_ADD_ITEM_FORM:
      return {
        ...state,
        isSubmitting: false,
        newSession: initialState.newSession
      };

    case educatorTypes.CLEAR_COURSE_FIELDS:
      return { ...initialState };

    case `${educatorTypes.FETCH_COURSE_STATS}_SUCCESS`:
      return {
        ...state,
        courseDetails: { ...state.courseDetails, ...action.result }
      };

    case `${educatorTypes.FETCH_COURSE_RATINGS}_SUCCESS`:
      return {
        ...state,
        isSubmitting: false,
        courseDetails: {
          ...state.courseDetails,
          courseMetrics: action.result
        }
      };

    case `${educatorTypes.FETCH_LESSON_METRICS}_SUCCESS`:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          lessonMetrics: {
            ...state.courseDetails.lessonMetrics,
            [action.lessonUID]: action.result
          },
          isLessonMetricsFetched: true
        }
      };

    case educatorTypes.UPDATE_RESCHEDULE_TEXT_FIELD:
    case educatorTypes.UPDATE_RESCHEDULE_DROPDOWN_FIELDS:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          rescheduleDetails: {
            ...state.courseDetails.rescheduleDetails,
            [action.id]: action.multipleValues
              ? [...state[action.id], action.value]
              : action.value
          }
        }
      };

    case `${educatorTypes.RESCHEDULE_CLASS}_SUCCESS`:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          rescheduleDetails: initialState.courseDetails.rescheduleDetails
        }
      };

    case educatorTypes.UPDATE_DISCUSSION_FORUM_TEXT:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          discussionForum: {
            ...state.courseDetails.discussionForum,
            text: action.value
          }
        }
      };

    case educatorTypes.SET_FILTER:
      return {
        ...state,
        filter: { ...state.filter, [action.data.courseType]: action.data.value }
      };

    case educatorTypes.UPDATE_TEMPLATE_COURSE_FIELDS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          [action.id]: action.value
        }
      };

    case educatorTypes.UPDATE_SCHEDULE_WEEK_DAY: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          daysOfWeek: action.value
        }
      };
    }

    case educatorTypes.FILL_DURATION_IN_SCHEDULE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          duration: state.sessionDuration
        }
      };

    case educatorTypes.DELETE_TEMPLATE_LESSONS_FROM_COURSE:
      return {
        ...state,
        sessions: action.sessions,
        liveClasses: action.liveClasses,
        quizzes: action.quizzes
      };

    case educatorTypes.SET_CLASH_SESSIONS_OF_A_COURSE:
      return {
        ...state,
        clashSessions: action.data,
        areSessionsClashing: true
      };

    case educatorTypes.SET_AUTO_SCHEDULE_CLASHES: {
      return {
        ...state,
        clashSessions: action.clashes,
        sessions: action.items.map(getSession),
        autoscheduleInfo: {
          isAutoScheduleDone: true,
          isAutoScheduleEnabled: true,
          autoscheduleLiveAt: action.ISODate,
          autoscheduleDuration: action.schedule.duration,
          autoscheduleFrequency: action.schedule.days
        }
      };
    }

    case educatorTypes.REMOVE_CLASH_SESSIONS:
      return {
        ...state,
        clashSessions: [],
        areSessionsClashing: false
      };

    case educatorTypes.REMOVE_SESSION_EDIT_ERROR:
      return {
        ...state,
        editSessionError: {}
      };

    case `${educatorTypes.SUBMIT_FEEDBACK_FOR_COURSE_TEMPLATE}_REQUEST`:
      return {
        ...state,
        isSubmittingFeedback: true,
        submitFeedbackFailed: false
      };

    case `${educatorTypes.SUBMIT_FEEDBACK_FOR_COURSE_TEMPLATE}_SUCCESS`:
      return {
        ...state,
        isSubmittingFeedback: false,
        submitFeedbackFailed: false
      };

    case `${educatorTypes.SUBMIT_FEEDBACK_FOR_COURSE_TEMPLATE}_FAILURE`:
      return {
        ...state,
        isSubmittingFeedback: false,
        submitFeedbackFailed: true
      };

    case `${educatorTypes.SET_AUTO_DPP_KNOW_MORE_CARD_STATUS}_SUCCESS`:
      return {
        ...state,
        metaInfo: {
          ...state.metaInfo,
          home_feed_blocks: {
            ...state.metaInfo.home_feed_blocks,
            dpp_intro_card: { is_visible: false }
          }
        }
      };

    case `${educatorTypes.FETCH_DPP_FEEDBACK_OPTIONS}_SUCCESS`:
      return {
        ...state,
        dpp: {
          ...state.dpp,
          feedback_options: action.result
        }
      };
    case `${actionTypes.FETCH_BANNERS_INFO}_SUCCESS`:
      return {
        ...state,
        bannersInfo: JSON.parse(action.result.description)
      };
    case `${educatorTypes.FETCH_ALL_OFFLINE_CITIES}_SUCCESS`:
      return {
        ...state,
        offlineCities: action.result
      };
    case `${educatorTypes.FETCH_ALL_OFFLINE_CENTRES}_SUCCESS`:
      return {
        ...state,
        offlineCenters: action.result
      };
    case `${educatorTypes.FETCH_ALL_ROOMS}_SUCCESS`:
      return {
        ...state,
        rooms: {
          ...state.rooms,
          [action.centreUID]: action.result
        }
      };
    case educatorTypes.CHANGE_ROOM_OF_A_COURSE:
      return {
        ...state,
        autoscheduleInfo: {
          ...state.autoscheduleInfo,
          autoScheduleRoomUID: action.value.uid
        }
      };

    case `${educatorTypes.FETCH_TA_LIVE_CLASS_URL}_SUCCESS`:
      return {
        ...state,
        [action.key]: { ...action.result, isTA: true }
      };

    case educatorTypes.UPDATE_SUB_CONCEPTS:
      return {
        ...state,
        [action.id]: action.value
      };

    case `${educatorTypes.FETCH_SPECIAL_CLASS_TYPES}_SUCCESS`:
      return {
        ...state,
        [action.key]: action.result
      };

    case educatorTypes.REMOVE_SUB_CONCEPTS:
      return {
        ...state,
        [action.key]: state[action.key].filter(
          (option) => option.uid !== action.value.uid
        )
      };

    default: {
      const actionHandler = reducerMap[action.type];
      return actionHandler ? actionHandler(state, action) : state;
    }
  }
};

export default reducer;
