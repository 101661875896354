import { rankPredictor } from '../constants';

const initialState = {
  gateSuggestions: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${rankPredictor.GET_RANK_PREDICTOR_SUGGESTIONS}_SUCCESS`:
      return {
        ...state,
        gateSuggestions: action.result
      };
    default: {
      return state;
    }
  }
};

export default reducer;
