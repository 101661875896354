import { ANSWER_STATUS } from '@constants/quiz';
import {
  MAX_QUESTIONS,
  MIN_QUESTIONS,
  PRACTICE_TYPE,
  practiceActionTypes
} from '@cont/Practice/constants';
import { practiceSessionTypes, savedTypes } from '../constants';

const initialState = {
  selectedQuestionCount: 10,
  isSkipped: false,
  hasSubmitted: false,
  isSubmitting: false,
  questionSaved: false,
  hintSeen: false,
  submittedFeedbackType: null,
  questionTimeTaken: 0,
  isLastQuestion: false,
  topologies: {},
  levelDetails: {},
  currentSession: {},
  currentQuestion: {},
  currentQuestionResult: {},
  sessionReport: {},
  nextQuestion: {},
  selectedTopic: {},
  selectedAnswer: [],
  filters: {},
  questions: {
    right: {},
    wrong: {},
    skipped: {}
  },
  feedbackOptions: {},
  reminderByUID: {},
  modal: {
    type: null,
    data: {}
  },
  availableLanguagesByGoalUID: {}
};

const fetchPracticeSuccess = (state, action) => {
  const { latest_session_attempts = [], ...rest } = action.result;
  const questions = {
    right: {},
    wrong: {},
    skipped: {}
  };

  latest_session_attempts.forEach(({ status, rank, uid }) => {
    if (status === ANSWER_STATUS.CORRECT_ATTEMPT) {
      questions.right[rank] = uid;
    } else if (status === ANSWER_STATUS.INCORRECT_ATTEMPT) {
      questions.wrong[rank] = uid;
    } else if (status === ANSWER_STATUS.SKIPPED) {
      questions.skipped[rank] = uid;
    }
  });

  return {
    ...state,
    questions,
    currentSession: rest
  };
};

const reducerMap = {
  [`${practiceSessionTypes.PRACTICE_MODAL_TYPE}`](state, action) {
    const {
      data: { type, data }
    } = action;

    return {
      ...state,
      modal: {
        type,
        data
      }
    };
  },
  [`${practiceSessionTypes.FETCH_PRACTICE_TOPOLOGIES}_SUCCESS`](state, action) {
    return {
      ...state,
      topologies: {
        ...state.topologies,
        [action.uid]: action.result
      }
    };
  },
  [`${practiceSessionTypes.FETCH_PRACTICE_AVAILABLE_LANGUAGES}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      availableLanguagesByGoalUID: {
        [action.goalUID]: action.result
      }
    };
  },
  [`${practiceSessionTypes.SET_LANGUAGE_PREFERENCE}_SUCCESS`](state, action) {
    return {
      ...state,
      availableLanguagesByGoalUID: {
        [action.goalUID]: {
          ...state.availableLanguagesByGoalUID[action.goalUID],
          user_preference: action.languageCode
        }
      }
    };
  },
  [`${practiceSessionTypes.FETCH_LEARNER_LEVEL_DETAILS}_SUCCESS`](
    state,
    action
  ) {
    const selectedQuestionCount =
      action.result?.streak_threshold >= MIN_QUESTIONS &&
      action.result?.streak_threshold <= MAX_QUESTIONS
        ? action.result?.streak_threshold
        : 10;

    return {
      ...state,
      selectedQuestionCount,
      levelDetails: {
        ...state.levelDetails,
        [action.goalUID]: action.result
      }
    };
  },
  [`${practiceSessionTypes.FETCH_PRACTICE_SESSION}_SUCCESS`]:
    fetchPracticeSuccess,
  [`${practiceSessionTypes.FETCH_CLASS_PRACTICE_SESSION}_SUCCESS`]:
    fetchPracticeSuccess,
  [`${practiceSessionTypes.GET_NEXT_PRACTICE_QUESTION}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      isLastQuestion: action.result.is_last_question,
      questionSaved: action.result.question.is_saved,
      currentQuestion: action.result.question
    };
  },
  [`${practiceSessionTypes.SUBMIT_PRACTICE_SESSION_ANSWER}_REQUEST`](state) {
    return {
      ...state,
      isSubmitting: true
    };
  },
  [`${practiceSessionTypes.SUBMIT_PRACTICE_SESSION_ANSWER}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      isSkipped: action.isSkipped,
      isSubmitting: false,
      hasSubmitted: true,
      nextQuestion: action.result.next_question,
      currentQuestionResult: action.result.present_question_data,
      sessionReport: action.result.next_question.session_report,
      questionTimeTaken: action.timeTaken,
      questions: {
        right: {
          ...state.questions.right,
          ...(!action.isSkipped &&
            action.result.present_question_data.session_details
              .is_correct_attempt && {
              [state.currentQuestion.question_rank]: state.currentQuestion.uid
            })
        },
        wrong: {
          ...state.questions.wrong,
          ...(!action.isSkipped &&
            !action.result.present_question_data.session_details
              .is_correct_attempt && {
              [state.currentQuestion.question_rank]: state.currentQuestion.uid
            })
        },
        skipped: {
          ...state.questions.skipped,
          ...(action.isSkipped && {
            [state.currentQuestion.question_rank]: state.currentQuestion.uid
          })
        }
      }
    };
  },
  [`${practiceSessionTypes.SUBMIT_PRACTICE_SESSION_ANSWER}_FAILURE`](state) {
    return {
      ...state,
      isSubmitting: false
    };
  },
  [`${practiceSessionTypes.SETUP_NEXT_PRACTICE_QUESTION}`](state) {
    return {
      ...state,
      isSkipped: false,
      isSubmitting: false,
      questionTimeTaken: 0,
      hasSubmitted: false,
      isLastQuestion: state.nextQuestion.is_last_question,
      questionSaved: state.nextQuestion.question.is_saved,
      hintSeen: false,
      submittedFeedbackType: null,
      currentQuestion: state.nextQuestion.question,
      selectedAnswer: [],
      nextQuestion: {},
      currentQuestionResult: {},
      sessionReport: {}
    };
  },
  [`${practiceSessionTypes.FINISH_PRACTICE_SESSION}_SUCCESS`](state, action) {
    return {
      ...state,
      sessionReport: action.result.session_report
    };
  },
  [`${practiceSessionTypes.SET_SELECTED_PRACTICE}`](state, action) {
    const { [state.selectedTopic.uid]: currentTopology, ...rest } =
      state.topologies;

    return {
      ...state,
      topologies: {
        ...rest
      },
      selectedTopic: action.data
    };
  },
  [`${practiceSessionTypes.SET_PRACTICE_ANSWERS}`](state, action) {
    return {
      ...state,
      selectedAnswer: action.data
    };
  },
  [`${practiceSessionTypes.SET_PRACTICE_SEEN_HINT}`](state) {
    return {
      ...state,
      hintSeen: true
    };
  },
  [`${practiceSessionTypes.FINISH_PRACTICE_QUESTION_REVIEW}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      submittedFeedbackType: action.review
    };
  },
  [`${savedTypes.REMOVE_SAVED_ENTITY}_SUCCESS`](state, action) {
    const { objectUid } = action;
    if (!objectUid) return state;

    return {
      ...state,
      questionSaved: false
    };
  },
  [`${savedTypes.SAVE_ENTITY}_SUCCESS`](state, action) {
    const { objectUid } = action;
    if (!objectUid) return state;

    return {
      ...state,
      questionSaved: true
    };
  },
  [`${practiceSessionTypes.RESET_PRACTICE_SESSION}`](state) {
    return {
      ...initialState,
      currentSession: state.currentSession,
      selectedQuestionCount: state.selectedQuestionCount,
      topologies: {
        ...state.topologies
      },
      levelDetails: {
        ...state.levelDetails
      },
      reminderByUID: {
        ...state.reminderByUID
      }
    };
  },
  [`${practiceSessionTypes.CREATE_PRACTICE_SESSION}_SUCCESS`](state, action) {
    if (action.practiceType === PRACTICE_TYPE.INCORRECT)
      return {
        ...state,
        currentSession: {
          ...state.currentSession,
          latest_session_no_of_questions: action.result.no_of_questions,
          latest_session_uid: action.result.uid
        }
      };
    return state;
  },
  [`${practiceSessionTypes.SET_PRACTICE_QUESTION_COUNT}`](state, action) {
    return {
      ...state,
      selectedQuestionCount: action.data
    };
  },
  [`${practiceSessionTypes.FETCH_PRACTICE_PYQ_FILTERS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.uid]: action.result
      }
    };
  },
  [`${practiceSessionTypes.FETCH_PRACTICE_FEEDBACK_OPTIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      feedbackOptions: action.result
    };
  },
  [`${practiceActionTypes.SET_REMINDER_DEFAULT_DATA}`](state, action) {
    return {
      ...state,
      reminderByUID: {
        ...state.reminderByUID,
        [action.goalUID]: {
          ...(state.reminderByUID[action.goalUID] || {}),
          daily_goal: action.data.dailyGoal,
          daily_reminder_at: action.data.dailyRemainderAt
        }
      }
    };
  },
  [`${practiceSessionTypes.FETCH_PRACTICE_REMINDER}_SUCCESS`](state, action) {
    const { goal_uid, ...rest } = action.result;

    return {
      ...state,
      reminderByUID: {
        ...state.reminderByUID,
        [action.goalUID]: {
          ...(state.reminderByUID[action.goalUID] || {}),
          ...rest
        }
      }
    };
  },
  [`${practiceSessionTypes.DISMISS_PRACTICE_REMINDER}_SUCCESS`](state, action) {
    const { is_dismissible, is_displayable } = action.result;

    return {
      ...state,
      reminderByUID: {
        ...state.reminderByUID,
        [action.goalUID]: {
          ...(state.reminderByUID[action.goalUID] || {}),
          is_dismissible,
          is_displayable
        }
      }
    };
  },
  [`${practiceSessionTypes.SUBMIT_PRACTICE_REMINDER}_SUCCESS`](state, action) {
    const { goal_uid, ...rest } = action.result;

    return {
      ...state,
      reminderByUID: {
        ...state.reminderByUID,
        [action.goalUID]: {
          ...(state.reminderByUID[action.goalUID] || {}),
          ...rest
        }
      }
    };
  },
  [`${practiceSessionTypes.DELETE_PRACTICE_REMINDER}_SUCCESS`](state, action) {
    return {
      ...state,
      reminderByUID: {
        ...state.reminderByUID,
        [action.goalUID]: {
          ...(state.reminderByUID[action.goalUID] || {}),
          is_active: false
        }
      }
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
