import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import withMiddleWare from './middleware';
import rootReducer from './reducers';

const devToolsEnable =
  process.env.isDev ||
  process.env.APP_ENV === 'staging' ||
  process.env.ENVIRONMENT === 'gamma';

const withDevTools = devToolsEnable
  ? composeWithDevTools(withMiddleWare)
  : withMiddleWare;

let store;

export const getStore = (initialState) => {
  // If client-side, return the initialized instance.
  if (store && typeof window !== 'undefined') return store;
  // If server-side, always return a new store instance.
  store = createStore(rootReducer, initialState, withDevTools);
  return store;
};

export default getStore;
