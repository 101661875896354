/* eslint-disable max-lines */
/* eslint-disable no-underscore-dangle */
import {
  TEST_TYPES,
  SECTION_TYPES
} from '@educator/Container/TestSeriesTemplates/Edit/constants';
import mergeDeep from '@utils/mergeDeep';
import { templatesTypes, educatorTypes } from '../constants';

const initialState = {
  isSectionEnabled: false,
  isSubjectiveTestType: false,
  templates: {},
  testSeriesForm: {},
  testSeriesErrors: {},

  data: {},
  addEditTemplate: {
    quizStates: [],
    courseTypes: [],
    newSession: {},
    selectedEducators: [],
    selectedGoal: [],
    liveClasses: [],
    quizzes: [],
    selectedTopicGroups: [],
    selectedTopics: [],
    selectedExams: [],
    selectedClasses: [],
    onBoardingPreferences: {}
  },
  addEditTemplateCourse: {
    allLanguages: [],
    selectedLanguages: [],
    contentSlideLanguages: []
  }
};

const setSubmitting = (action, state, isSubmitting) => ({
  ...state,
  addEditTemplate: {
    ...state.addEditTemplate,
    isSubmitting
  }
});

const createQuiz = ({ result }, state, isSubmitting) => ({
  ...state,
  addEditTemplate: {
    ...state.addEditTemplate,
    newSession: {
      ...state.addEditTemplate.newSession,
      quizUID: result.uid
    },
    isSubmitting
  }
});

const setTestState = ({ result }, state) => {
  const quizStates = {};
  result.forEach((item) => {
    quizStates[item.uid] = item.content_state;
  });
  return {
    ...state,
    addEditTemplate: { ...state.addEditTemplate, quizStates }
  };
};

const updateEducatorSlides = ({ data, uid }, state) => {
  const newState = state;
  newState.addEditTemplate.liveClasses.forEach((liveClass) => {
    if (liveClass.educatorSlides?.uid === uid) {
      // eslint-disable-next-line no-param-reassign
      liveClass.educatorSlides = data;
      // eslint-disable-next-line no-param-reassign
      liveClass.educator_slides = data;
    }
  });
  return {
    ...state,
    addEditTemplate: {
      ...state.addEditTemplate,
      liveClasses: newState.addEditTemplate.liveClasses
    }
  };
};

const reducerMap = {
  [`${templatesTypes.CREATE_QUIZ_IN_TEMPLATE}_SUCCESS`]: createQuiz,
  [`${templatesTypes.CREATE_QUIZ_IN_TEMPLATE}_FAILURE`]: setSubmitting,
  [`${templatesTypes.CREATE_QUIZ_IN_TEMPLATE}_REQUEST`]: setSubmitting,
  [`${templatesTypes.CREATE_TEST_FOR_TEMPLATE}_REQUEST`]: setSubmitting,
  [`${templatesTypes.CREATE_TEST_FOR_TEMPLATE}_FAILURE`]: setSubmitting,
  [`${templatesTypes.CREATE_TEST_FOR_TEMPLATE}_SUCCESS`]: setSubmitting,
  [`${templatesTypes.EDIT_QUIZ_IN_TEMPLATE}_SUCCESS`]: setSubmitting,
  [`${templatesTypes.EDIT_QUIZ_IN_TEMPLATE}_FAILURE`]: setSubmitting,
  [`${templatesTypes.EDIT_QUIZ_IN_TEMPLATE}_REQUEST`]: setSubmitting,
  [`${templatesTypes.FETCH_TEST_STATE}_SUCCESS`]: setTestState,
  [`${templatesTypes.UPDATE_EDUCATOR_SLIDES}`]: updateEducatorSlides
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${templatesTypes.FETCH_TEMPLATE}_SUCCESS`:
      return {
        ...state,
        isSectionEnabled: !!action.result.results[0]?._source.section?.length,
        isSubjectiveTestType: !!(
          action.result.results[0]?._source['test-type'] ===
          TEST_TYPES.SUBJCETIVE
        ),
        testSeriesForm: {
          ...state.testSeriesForm,
          ...(action.result.results[0]?._source || {})
        }
      };

    case templatesTypes.RESET_TEMPLATE_FORM:
      return {
        ...state,
        isSectionEnabled: false,
        isSubjectiveTestType: false,
        testSeriesForm: action.data
      };

    case templatesTypes.UPDATE_TEMPLATE_SETTINGS:
      if (action.name === 'goal') {
        return {
          ...state,
          testSeriesForm: {
            ...state.testSeriesForm,
            [action.name]: {
              name: action.value.name,
              uid: action.value.uid
            }
          }
        };
      }
      if (action.name === 'test-type') {
        return {
          ...state,
          isSubjectiveTestType: TEST_TYPES.SUBJCETIVE === action.value,
          testSeriesForm: {
            ...state.testSeriesForm,
            [action.name]: action.value
          }
        };
      }
      if (action.name === 'section') {
        return {
          ...state,
          isSectionEnabled: action.value === SECTION_TYPES.ENABLED
        };
      }
      return {
        ...state,
        testSeriesForm: {
          ...state.testSeriesForm,
          [action.name]: action.value
        }
      };

    case templatesTypes.UPDATE_TEMPLATE_DETAILS: {
      if (action.name === 'languages') {
        const languages = action.isAddType
          ? action.value.map((language) => ({
              id: language.id,
              name: language.name
            }))
          : (state.testSeriesForm?.details.languages || []).filter(
              (language) => language.id !== action.value.id
            );
        return {
          ...state,
          testSeriesForm: {
            ...state.testSeriesForm,
            details: {
              ...(state.testSeriesForm?.details || {}),
              languages
            }
          }
        };
      }
      if (action.name === 'subject') {
        const subjects = action.isAddType
          ? action.value.map((subject) => ({
              uid: subject.uid,
              name: subject.name
            }))
          : (state.testSeriesForm?.details.subject || []).filter(
              (subject) => subject.uid !== action.value.uid
            );
        return {
          ...state,
          testSeriesForm: {
            ...state.testSeriesForm,
            details: {
              ...(state.testSeriesForm?.details || {}),
              subject: subjects
            }
          }
        };
      }
      return {
        ...state,
        testSeriesForm: {
          ...state.testSeriesForm,
          details: {
            ...(state.testSeriesForm?.details || {}),
            [action.name]: action.value
          }
        }
      };
    }

    case templatesTypes.ADD_OR_UPDATE_TEMPLATE_SECTION: {
      const sections = action.isAddType
        ? [...(state.testSeriesForm?.section || []), action.value]
        : (state.testSeriesForm?.section || []).map((section, index) =>
            index === action.position ? action.value : section
          );
      return {
        ...state,
        testSeriesForm: {
          ...state.testSeriesForm,
          section: sections
        }
      };
    }

    case templatesTypes.UPDATE_TEMPLATE_EVALUATION_CRITERIA: {
      const criteria = [...(state.testSeriesForm['evaluation-criteria'] || [])];
      criteria[action.position] = action.value;
      return {
        ...state,
        testSeriesForm: {
          ...state.testSeriesForm,
          'evaluation-criteria': criteria
        }
      };
    }

    case templatesTypes.UPDATE_TEMPLATE_INSTRUCTIONS: {
      return {
        ...state,
        testSeriesForm: {
          ...state.testSeriesForm,
          instructions: action.value
        }
      };
    }

    case templatesTypes.SET_TEMPLATE_ERRORS: {
      return {
        ...state,
        testSeriesErrors: action.errors
      };
    }

    case `${templatesTypes.FETCH_TEST_TEMPLATE_DETAILS}_SUCCESS`: {
      return {
        ...state,
        templates: { ...state.templates, [action.templateUID]: action.result }
      };
    }

    case educatorTypes.UPDATE_TEMPLATES:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    case `${educatorTypes.FETCH_TEMPLATE_DETAILS}_SUCCESS`:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          ...action.result,
          selectedGoal: [action.result.goal],
          selectedTopics: action.result.topics,
          selectedExams: action.result.exams,
          selectedTopicGroups: action.result.topicGroups,
          courseTypes: state.addEditTemplate.courseTypes,
          newSession: state.addEditTemplate.newSession,
          selectedClasses: action.result.selectedClasses
        }
      };

    case educatorTypes.UPDATE_COURSE_AUTOCOMPLETE_FIELDS:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          [action.id]: action.isSingleValue ? [action.value] : action.value
        }
      };

    case educatorTypes.REMOVE_TAG:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          [action.id]:
            state.addEditTemplate[action.id]?.filter(
              (option) => option[action.key] !== action.value[action.key]
            ) || []
        }
      };

    case educatorTypes.REMOVE_CONCEPT:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          newSession: {
            ...state.addEditTemplate.newSession,
            [action.key]:
              state.addEditTemplate.newSession?.[action.key]?.filter(
                (option) => option.uid !== action.value.uid
              ) || []
          }
        }
      };

    case educatorTypes.SET_COURSE_TYPE:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          [action.key]: action.result
        }
      };

    case `${educatorTypes.FETCH_COURSE_TYPES}_SUCCESS`:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          [action.key]: action.result
        },
        [action.key]: action.result
      };

    case educatorTypes.UPDATE_COURSE_DETAILS:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          [action.id]: action.value
        }
      };

    case educatorTypes.UPDATE_SESSION_VALUE:
    case educatorTypes.UPDATE_SESSION_CONCEPTS:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          newSession: {
            ...state.addEditTemplate.newSession,
            [action.id]: action.value
          }
        }
      };

    case educatorTypes.ADD_TEMPLATE_LESSON:
      return {
        ...state,
        addEditTemplate: action.data
      };

    case educatorTypes.CLEAR_TEMPLATE_SESSION:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          quizUID: '',
          newSession: {
            title: '',
            duration: '',
            selectedConcepts: []
          }
        }
      };
    case educatorTypes.CLEAR_TEMPLATES_STATE:
      return {
        ...initialState
      };

    case educatorTypes.SET_TEMPLATE_LESSON_DURATION:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          newSession: {
            ...state.addEditTemplate.newSession,
            duration: action.lessonDuration
          }
        }
      };

    case educatorTypes.CLEAR_TEMPLATE_DATA:
      return {
        ...state,
        addEditTemplate: {
          ...initialState.addEditTemplate
        }
      };

    case educatorTypes.SETUP_TEMPLATE_EDIT_SESSION:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          newSession: action.value
            ? {
                ...state.addEditTemplate.newSession,
                title: action.value.title,
                duration: action.value.duration,
                selectedSubConcepts: action.value.concepts || [],
                type: action.value.type,
                rank: action.value.rank,
                index: action.value.index,
                educatorSlides:
                  action.value.educatorSlides ||
                  action.value.educator_slides ||
                  action.value.drive_details,
                learnerNotes:
                  action.value.learnerNotes || action.value.learner_notes,
                hasPracticeQuiz: !!(
                  action.value.hasPracticeQuiz ||
                  action.value.practice_quiz?.uid
                ),
                questions:
                  action.value.questions ||
                  action.value.practice_quiz?.max_question_count,
                quizUID:
                  action.value.quizUID ||
                  action.value.practice_quiz?.uid ||
                  action.value.template_quiz?.uid
              }
            : initialState.addEditTemplate.newSession
        }
      };

    case `${educatorTypes.SAVE_TEMPLATE}_SUCCESS`:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          ...action.result,
          courseTypes: state.addEditTemplate.courseTypes,
          selectedGoal: [action.result.goal],
          selectedTopics: action.result.topics,
          selectedExams: action.result.exams,
          selectedTopicGroups: action.result.topicGroups,
          newSession: state.addEditTemplate.newSession,
          selectedClasses: action.result.selectedClasses
        }
      };

    case `${educatorTypes.FETCH_COURSE_TEMPLATE_FILTERS}_SUCCESS`:
      return {
        ...state,
        ...action.result
      };

    case educatorTypes.SET_LESSON_REFS:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          [action.key]: action.result
        }
      };

    case educatorTypes.SET_DRAG_INFO:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          dragInfo: action.data
        }
      };
    case educatorTypes.EDIT_TEMPLATE_COURSE_LESSON_WITHOUT_UID:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          ...action.result
        }
      };

    case educatorTypes.DELETE_TEMPLATE_LESSONS:
    case educatorTypes.SET_TEMPLATE_REORDERED_QUIZ_LESSONS:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          liveClasses: action.data.liveClasses,
          quizzes: action.data.quizzes
        }
      };

    case `${educatorTypes.GET_ON_BOARDING_PREFERENCES}_SUCCESS`:
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          onBoardingPreferences: action.result
        }
      };

    case templatesTypes.CLEAR_TEMPLATE_FIELDS: {
      const data = {};
      action.ids.forEach((id) => {
        data[id] = [];
      });
      const quizzes = action.isContentOp
        ? state.addEditTemplate.quizzes.map((quiz) => ({
            ...quiz,
            concepts: []
          }))
        : state.addEditTemplate.quizzes;
      return {
        ...state,
        addEditTemplate: {
          ...state.addEditTemplate,
          ...data,
          liveClasses: state.addEditTemplate.liveClasses.map((liveClass) => ({
            ...liveClass,
            concepts: []
          })),
          quizzes
        }
      };
    }

    default: {
      const actionHandler = reducerMap[action.type];
      const type = action.type.split('_');
      const actionType = type[type.length - 1];
      // last param is the isLoading state variable
      return actionHandler
        ? {
            ...state,
            ...actionHandler(action, state, actionType === 'REQUEST')
          }
        : state;
    }
  }
};

export default reducer;
