import { createReducer } from '@utils/reducerHelper';
import { learnerDoubtsAndSolutionsTypes } from '@actions-constants/index';
import { apiStatus } from '@constants/index';

const initialState = {
  entityDetails: {},
  questionMetadata: {}
};

const reducerMap = {
  // Fetching Catalogue Details
  [`${learnerDoubtsAndSolutionsTypes.FETCH_CATALOGUE_ENTITY_DETAILS}_${apiStatus.SUCCESS}`]:
    (action, state) => {
      return {
        ...state,
        entityDetails: {
          ...state.entityDetails,
          [action.entityUID]: action.result
        }
      };
    },
  [`${learnerDoubtsAndSolutionsTypes.FETCH_QUESTION_META_DATA}_${apiStatus.SUCCESS}`]:
    ({ questionUID, result }, state) => ({
      ...state,
      questionMetadata: {
        ...state.questionMetadata,
        [questionUID]: result
      }
    })
};

const doubtsAndSolutionsReducer = createReducer(reducerMap, initialState);

export default doubtsAndSolutionsReducer;
