import shuffle from 'lodash/shuffle';
import { freePracticeSessionTypes } from '@actions-constants/index';

const initialState = {
  topologies: [],
  topics: {},
  concept: {},
  totalGTPPracticeQuestionCount: 0
};

const shuffleAnswers = (questions) =>
  questions.map((question) => ({
    ...question,
    answers: shuffle(question.answers)
  }));

const reducerMap = {
  [`${freePracticeSessionTypes.FETCH_FREE_PRACTICE_TOPOLOGIES}_SUCCESS`](
    state,
    action
  ) {
    const topologies = action.isGTP ? action.result.data : action.result;
    return {
      ...state,
      topologies,
      totalGTPPracticeQuestionCount: action.result?.goal_question_count || 0
    };
  },
  [`${freePracticeSessionTypes.FETCH_FREE_PRACTICE_TOPOLOGY_LIST}_SUCCESS`](
    state,
    action
  ) {
    const topics = action.isGTP ? action.result.data : action.result;
    return {
      ...state,
      topics,
      totalGTPPracticeQuestionCount: action.result?.goal_question_count || 0
    };
  },
  [`${freePracticeSessionTypes.FETCH_FREE_PRACTICE_QUESTIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      questions: {
        ...action.result,
        questions_data: shuffleAnswers(action.result.questions_data)
      }
    };
  }
};

function freePracticeSession(state = initialState, action) {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
}

export default freePracticeSession;
