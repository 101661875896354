import { PartnerType } from '@educator/Onboard/constants';
import { educatorOnboardingTypes } from '../constants';

const initialState = {
  partner_type: PartnerType.EDUCATOR,
  phone: '',
  onboardingState: null,
  highest_education: null,
  bio: '',
  experience: null,
  billingDetails: null,
  ifscDetails: null
};

const setUserStatus = (state, data) => {
  const { partner_type, phone, state: onboardingState } = data.result;
  return {
    ...state,
    onboardingState,
    partner_type,
    phone
  };
};

const getUserDetails = (state, data) => {
  const { partner_type, bio, experience, highest_education } =
    data.result.user_data;
  const { state: onboardingState } = data.result;
  return {
    ...state,
    partner_type,
    bio,
    experience,
    highest_education,
    onboardingState
  };
};

const setAcademicsDetails = (state, data) => {
  const { highest_education } = data.payload;
  return {
    ...state,
    highest_education
  };
};

const setExperienceDetails = (state, data) => {
  const { bio, experience } = data.payload;
  return {
    ...state,
    bio,
    experience
  };
};

const setBillingDetails = (state, data) => {
  const billingDetails = data.payload;
  return {
    ...state,
    billingDetails
  };
};

const reducerMap = {
  [`${educatorOnboardingTypes.GET_ONBOARD_USER_STATUS}_SUCCESS`]: setUserStatus,
  [`${educatorOnboardingTypes.GET_APPLICATION_DETAILS}_SUCCESS`]:
    getUserDetails,
  [`${educatorOnboardingTypes.SET_ACADEMIC_DETAILS}`]: setAcademicsDetails,
  [`${educatorOnboardingTypes.SET_EXPERIENCE_DETAILS}`]: setExperienceDetails,
  [`${educatorOnboardingTypes.SET_BILLING_DETAILS}`]: setBillingDetails
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];

  if (action.type === `${educatorOnboardingTypes.GET_BILLING_INFO}_SUCCESS`) {
    return {
      ...state,
      billingDetails: action.result
    };
  }

  if (action.type === `${educatorOnboardingTypes.GET_IFSC_DETAILS}_SUCCESS`) {
    return {
      ...state,
      ifscDetails: action.result
    };
  }
  const type = action.type.split('_');
  const actionType = type[type.length - 1];
  // last param is the isLoading state variable
  return actionHandler
    ? actionHandler(state, action, actionType === 'REQUEST')
    : state;
};

export default reducer;
