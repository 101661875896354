import { combatTypes } from '../constants';

const initialState = {
  upcoming: {},
  staticData: {},
  onboardingPreferences: {},
  details: {},
  contestRating: {},
  myRating: {},
  enrolled: {},
  modal: {
    type: null,
    data: {}
  }
};

const reducerMap = {
  [`${combatTypes.FETCH_CONTEST_INFO}_SUCCESS`](state, { goalUID, result }) {
    return {
      ...state,
      staticData: {
        ...state.staticData,
        [goalUID]: result
      }
    };
  },
  [`${combatTypes.FETCH_CONTEST_RATING}_SUCCESS`](state, { goalUID, result }) {
    return {
      ...state,
      contestRating: {
        ...state.contestRating,
        [goalUID]: result
      }
    };
  },
  [`${combatTypes.FETCH_MY_RATING}_SUCCESS`](state, { goalUID, result }) {
    return {
      ...state,
      myRating: {
        ...state.myRating,
        [goalUID]: result
      }
    };
  },
  [`${combatTypes.FETCH_UPCOMING_COMBATS_INFO}_SUCCESS`](
    state,
    { goalUID, result }
  ) {
    return {
      ...state,
      upcoming: {
        ...state.upcoming,
        [goalUID]: result
      }
    };
  },
  [`${combatTypes.ENROLL_FOR_CONTEST}_SUCCESS`](state, { contestUID }) {
    return {
      ...state,
      enrolled: {
        ...state.enrolled,
        [contestUID]: true
      }
    };
  },
  [`${combatTypes.FETCH_CONTEST_ONBOARDING_PREFERENCES}_SUCCESS`](
    state,
    { goalUID, result }
  ) {
    return {
      ...state,
      onboardingPreferences: {
        ...state.onboardingPreferences,
        [goalUID]: result
      }
    };
  },
  [`${combatTypes.FETCH_COMBAT_DETAILS}_SUCCESS`](
    state,
    { combatUID, result }
  ) {
    return {
      ...state,
      details: {
        ...state.details,
        [combatUID]: result
      }
    };
  },
  [`${combatTypes.COMBAT_MODAL_TYPE}`](state, action) {
    const {
      data: { type, data }
    } = action;

    return {
      ...state,
      modal: {
        type,
        data
      }
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
