/* eslint-disable one-var, no-unused-vars */
import mergeDeep from '@utils/mergeDeep';
import { skipDoubtTopologyLevels } from '@constants/index';
import { doubtTypes } from '../constants';

const initialState = {
  data: {},
  noDoubt: false,
  modalChoices: { noIssues: [], skipOptions: [] },
  assignedDoubt: {},
  doubtStats: null,
  homeTopology: [],
  topicGroups: [],
  conceptsList: [],
  topics: [],
  selectedHomeTopology: null,
  isSubmitting: false,
  newSolution: null,
  cycleEarnings: {},
  fetchingEarnings: false,
  campaignRules: {},
  incentiveRules: [],
  showTimer: false,
  submitSolutionTimer: {}
};

const fetchTopologyListSuccess = (state, { level, result }) => {
  const map = {
    [skipDoubtTopologyLevels.TOPIC_GROUP]: 'topicGroups',
    [skipDoubtTopologyLevels.CONCEPT]: 'conceptsList',
    [skipDoubtTopologyLevels.TOPIC]: 'topics'
  };
  if (!map[level]) return {};
  return {
    [map[level]]: result
  };
};

const setIsSubmitting = (state, action, isSubmitting) => ({
  isSubmitting
});

const resetTopology = (state, action, isSubmitting) => ({
  isSubmitting,
  topicGroups: [],
  conceptsList: []
});

const reducerMap = {
  [doubtTypes.UPDATE_DOUBTS]: (state, action) => ({
    data: mergeDeep(action.data, state.data, 2)
  }),
  [`${doubtTypes.FETCH_INCENTIVE_RULES}_SUCCESS`]: (
    state,
    action,
    isSubmitting
  ) => ({
    isSubmitting,
    incentiveRules: action.result
  }),
  [`${doubtTypes.FETCH_CYCLE_EARNINGS}_FAILURE`]: (
    state,
    action,
    fetchingEarnings
  ) => ({
    fetchingEarnings
  }),
  [`${doubtTypes.FETCH_CYCLE_EARNINGS}_REQUEST`]: (
    state,
    action,
    fetchingEarnings
  ) => ({
    fetchingEarnings
  }),
  [`${doubtTypes.FETCH_CYCLE_EARNINGS}_SUCCESS`]: (
    state,
    action,
    isSubmitting
  ) => ({
    fetchingEarnings: isSubmitting,
    cycleEarnings: {
      ...state.cycleEarnings,
      [action.key]: action.result
    }
  }),
  [`${doubtTypes.FETCH_CAMPAIGN_RULES}_SUCCESS`]: (
    state,
    action,
    isSubmitting
  ) => ({
    campaignRules: {
      ...state.campaignRules,
      [action.campaignUID]: action.result
    },
    isSubmitting
  }),
  [`${doubtTypes.START_RECORDING}_SUCCESS`]: (state, action, isSubmitting) => ({
    objectUID: action.result.object_uid,
    objectType: action.result.object_type,
    newSolution: action.result.uid,
    showTimer: action.result?.show_timer,
    isSubmitting
  }),
  [`${doubtTypes.FETCH_SOLUTION_DETAILS}_SUCCESS`]: (state, action) => ({
    currentSolution: action.result
  }),
  [`${doubtTypes.SET_HOME_TOPOLOGY}`]: (state, action) => ({
    selectedHomeTopology: action.topology
  }),
  [`${doubtTypes.FETCH_TOPOLOGY_LIST}_SUCCESS`]: fetchTopologyListSuccess,
  [`${doubtTypes.FETCH_HOME_TOPOLOGY}_SUCCESS`]: (state, action) => ({
    homeTopology: action.result
  }),
  [`${doubtTypes.FETCH_STATS}_SUCCESS`]: (state, action) => ({
    doubtStats: action.result
  }),
  [`${doubtTypes.FETCH_CHOICES}_SUCCESS`]: (state, action) => ({
    modalChoices: {
      ...state.modalChoices,
      [action.modelType === 1 ? 'skipOptions' : 'noIssues']: action.result
    }
  }),
  [`${doubtTypes.ASSIGN_DOUBT}_FAILURE`]: (state, action, isSubmitting) => ({
    noDoubt: true,
    isSubmitting,
    error: action.error?.message,
    assignedDoubt: {},
    showTimer: false
  }),
  [`${doubtTypes.RECORDING_STARTED}_REQUEST`]: () => ({
    showTimer: false
  }),
  [`${doubtTypes.RECORDING_STARTED}_SUCCESS`]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      [action.doubtUID]: {
        ...state.data[action.doubtUID],
        recordingStartedAt: action.result?.recording_started_at,
        unassignRecordingTimerDuration:
          action.result?.unassign_recording_timer_duration
      }
    }
  }),
  [doubtTypes.UPDATE_SOLUTION_DETAILS]: (state, action) => ({
    objectUID: null,
    objectType: null,
    newSolution: null
  }),
  [doubtTypes.UPDATE_SUBMIT_TIMER]: (state, action) => ({
    ...state,
    submitSolutionTimer: {
      ...state.submitSolutionTimer,
      [action.doubtUID]: action.timerState
    }
  }),
  [`${doubtTypes.ASSIGN_DOUBT}_SUCCESS`]: (state, action, isSubmitting) => {
    if (action.rawResponse?.success_code === 'E16015') {
      return {
        noDoubt: true,
        isSubmitting,
        error: action.rawResponse?.message,
        assignedDoubt: {},
        showTimer: false
      };
    }
    return {
      assignedDoubt: action.result,
      noDoubt: false,
      isSubmitting,
      showTimer: true
    };
  },

  [`${doubtTypes.ASSIGN_DOUBT}_REQUEST`]: (state, action, isSubmitting) => ({
    noDoubt: false,
    isSubmitting
  }),
  [`${doubtTypes.FEEDBACK_TO_LEARNER}_SUCCESS`]: resetTopology,
  [`${doubtTypes.SUBMIT_SOLUTION}_SUCCESS`]: resetTopology,
  [`${doubtTypes.SKIP_DOUBT}_SUCCESS`]: resetTopology,
  [`${doubtTypes.FETCH_INCENTIVE_RULES}_REQUEST`]: setIsSubmitting,
  [`${doubtTypes.FETCH_CAMPAIGN_RULES}_REQUEST`]: setIsSubmitting,
  [`${doubtTypes.START_RECORDING}_REQUEST`]: setIsSubmitting,
  [`${doubtTypes.FEEDBACK_TO_LEARNER}_REQUEST`]: setIsSubmitting,
  [`${doubtTypes.SUBMIT_SOLUTION}_REQUEST`]: setIsSubmitting,
  [`${doubtTypes.SKIP_DOUBT}_REQUEST`]: setIsSubmitting,
  [`${doubtTypes.FETCH_INCENTIVE_RULES}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.START_RECORDING}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.FEEDBACK_TO_LEARNER}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.SUBMIT_SOLUTION}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.SKIP_DOUBT}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.FETCH_CAMPAIGN_RULES}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.UNASSIGN_DOUBT}_SUCCESS`]: setIsSubmitting,
  [`${doubtTypes.UNASSIGN_DOUBT}_FAILURE`]: setIsSubmitting,
  [`${doubtTypes.UNASSIGN_DOUBT}_REQUEST`]: setIsSubmitting
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  const type = action.type.split('_')[action.type.split('_').length - 1];
  const isSubmitting = type === 'REQUEST';
  return actionHandler
    ? { ...state, ...actionHandler(state, action, isSubmitting) }
    : state;
};

export default reducer;
