import mergeDeep from '@utils/mergeDeep';
import { quizTypes, savedTypes, questionTypes } from '../constants';

const initialState = {
  data: {},
  currentQuestion: {},
  isSubmitting: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case quizTypes.UPDATE_QUESTIONS:
      return {
        ...state,
        data: mergeDeep(action.data, state.data)
      };
    case `${savedTypes.REMOVE_SAVED_ENTITY}_SUCCESS`: {
      const { objectUid } = action;
      if (!state.data[objectUid]) return state;
      return {
        ...state,
        data: {
          ...state.data,
          [objectUid]: {
            ...state.data[objectUid],
            is_saved: false
          }
        }
      };
    }

    case `${savedTypes.SAVE_ENTITY}_SUCCESS`: {
      const { objectUid } = action;
      if (!state.data[objectUid]) return state;
      return {
        ...state,
        data: {
          ...state.data,
          [objectUid]: {
            ...state.data[objectUid],
            is_saved: true
          }
        }
      };
    }

    case `${questionTypes.FETCH_QUESTION_DATA_V2}_SUCCESS`:
      return { ...state, currentQuestion: action.result };

    case `${questionTypes.SAVE_QUESTION}_REQUEST`:
    case `${questionTypes.SUBMIT_SOLUTION_RECORDING}_REQUEST`:
    case `${questionTypes.SUBMIT_QUESTION_DETAILS}_REQUEST`:
      return { ...state, isSubmitting: true };

    case `${questionTypes.SUBMIT_QUESTION_DETAILS}_SUCCESS`:
      return {
        ...state,
        isSubmitting: false,
        currentQuestion: {
          ...state.currentQuestion,
          question: {
            ...state.currentQuestion.question,
            question: {
              ...state.currentQuestion.question.question,
              difficulty_level: action.difficulty_level,
              expected_time: action.expected_time
            }
          }
        }
      };

    case `${questionTypes.SUBMIT_SOLUTION_RECORDING}_FAILURE`:
    case `${questionTypes.SUBMIT_QUESTION_DETAILS}_FAILURE`:
    case `${questionTypes.SUBMIT_SOLUTION_RECORDING}_SUCCESS`:
    case `${questionTypes.SAVE_QUESTION}_SUCCESS`:
    case `${questionTypes.SAVE_QUESTION}_FAILURE`:
      return { ...state, isSubmitting: false };

    default:
      return state;
  }
};

export default reducer;
