import { createReducer } from '@utils/reducerHelper';
import { doubtsOnWeb2 } from '@actions-constants/index';
import { apiStatus } from '@constants/index';
import { blueDotFeature } from '@cont/Doubts20/constants';

const initialState = {
  myDoubts: {},
  doubtConfigs: {},
  doubtDetails: {},
  filters: {},
  selectedFilters: {},
  submitDoubtDetails: {},
  limitFlag: false,
  feedbackDrawerFlag: false,
  feedbackChoices: {},
  selectedfeedbackChoice: [],
  selectedDoubtSolution: '',
  submittedFeedback: {},
  isInstantMatch: false,
  isDoubtsDataFetched: false,
  isSubmitting: false,
  subjectSelectionFlow: {},
  blueDotDetails: {}
};

const setIsSubmitting = (state, isSubmitting) => ({
  ...state,
  isSubmitting
});

const reducerMap = {
  [`${doubtsOnWeb2.GET_MY_DOUBTS}_${apiStatus.SUCCESS}`]: (action, state) => {
    return {
      ...state,
      myDoubts: { ...state.myDoubts, [action.goalUID]: [...action.result] }
    };
  },
  [`${doubtsOnWeb2.FETCH_ALL_FILTERS}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    return {
      ...state,
      filters: { ...state.filters, [action.goalUID]: { ...action.result } }
    };
  },
  [`${doubtsOnWeb2.GET_DOUBT_CONFIGS}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    return {
      ...state,
      doubtConfigs: {
        ...state.doubtConfigs,
        [action.goalUID]: {
          ...action.result
        }
      },
      isDoubtsDataFetched: true
    };
  },
  [`${doubtsOnWeb2.GET_DOUBT_DETAILS}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    const selectedSoution = action.result.isSelectedReset
      ? action.result?.solutions?.[0]?.uid
      : state.selectedDoubtSolution;

    return {
      ...state,
      doubtDetails: {
        ...state.doubtDetails,
        [action.goalUID]: {
          ...state.doubtDetails[action.goalUID],
          [action.doubtUID]: { ...action.result }
        }
      },
      selectedDoubtSolution: selectedSoution
    };
  },
  [doubtsOnWeb2.SET_SELECTED_FILTER]: (action, state) => {
    return {
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        [action.goalUID]: {
          ...state.selectedFilters[action.goalUID],
          [action.filterType]: action.data
        }
      }
    };
  },
  [doubtsOnWeb2.SET_SUBMIT_DOUBT_DETAILS]: (action, state) => {
    return {
      ...state,
      submitDoubtDetails: action.isReset
        ? {}
        : {
            ...state.submitDoubtDetails,
            ...action.data
          }
    };
  },
  [`${doubtsOnWeb2.FETCH_SUBJECTS_AND_LANGUAGES}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    const { languages, topologies } = action.result;
    return {
      ...state,
      submitDoubtDetails: {
        ...state.submitDoubtDetails,
        subjectsAndLanguages: { ...action.result },
        selectedDoubtLanguage: languages[1].id,
        selectedDoubtTopology: topologies[1]
      }
    };
  },
  [doubtsOnWeb2.SET_LIMIT_FLAG]: (action, state) => {
    return {
      ...state,
      limitFlag: action.flag
    };
  },
  [doubtsOnWeb2.SET_FEEDBACK_DRAWER_FLAG]: (action, state) => {
    return {
      ...state,
      feedbackDrawerFlag: action.flag
    };
  },
  [`${doubtsOnWeb2.GET_FEEDBACK_CHOICES}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    return {
      ...state,
      feedbackChoices: {
        ...state.feedbackChoices,
        [action.goalUID]: { ...action.result }
      }
    };
  },
  [doubtsOnWeb2.SET_SELECTED_FEEDBACK_CHOICE]: (action, state) => {
    return {
      ...state,
      selectedfeedbackChoice: action.uids
    };
  },
  [doubtsOnWeb2.SET_SELECTED_DOUBT_SOLUTION]: (action, state) => {
    return {
      ...state,
      selectedDoubtSolution: action.uid
    };
  },
  [`${doubtsOnWeb2.SUBMIT_FEEDBACK}_${apiStatus.SUCCESS}`]: (action, state) => {
    return {
      ...state,
      submittedFeedback: {
        ...state.submittedFeedback,
        [action.doubtUID]: {
          ...state.submittedFeedback[action.doubtUID],
          [action.solutionUID]: action.feebbackType
        }
      }
    };
  },
  [`${doubtsOnWeb2.FETCH_BLUE_DOT_FOR_NAV_ITEMS}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    const key = `${action.goalUID}-${blueDotFeature.DOUBTS}`;
    return {
      ...state,
      blueDotDetails: {
        ...state.blueDotDetails,
        [key]: {
          ...state.blueDotDetails[key],
          ...action.result
        }
      }
    };
  },
  [doubtsOnWeb2.SET_INSTANT_MATCH_FLAG]: (action, state) => {
    return {
      ...state,
      isInstantMatch: action.instantMatchflag
    };
  },
  [`${doubtsOnWeb2.SUBMIT_DOUBT}_${apiStatus.REQUEST}`]: (state) =>
    setIsSubmitting(state, true),
  [`${doubtsOnWeb2.SUBMIT_DOUBT}_${apiStatus.SUCCESS}`]: (state) =>
    setIsSubmitting(state, false),
  [`${doubtsOnWeb2.SUBMIT_DOUBT}_${apiStatus.FAILURE}`]: (state) =>
    setIsSubmitting(state, false),
  [`${doubtsOnWeb2.SUBMIT_FOR_REVIEW}_${apiStatus.REQUEST}`]: (state) =>
    setIsSubmitting(state, true),
  [`${doubtsOnWeb2.SUBMIT_FOR_REVIEW}_${apiStatus.SUCCESS}`]: (state) =>
    setIsSubmitting(state, false),
  [`${doubtsOnWeb2.SUBMIT_FOR_REVIEW}_${apiStatus.FAILURE}`]: (state) =>
    setIsSubmitting(state, false),
  [doubtsOnWeb2.SET_DOUBT_CONFIG]: (action, state) => {
    return {
      ...state,
      doubtConfigs: {
        ...state.doubtConfigs,
        [action.goalUID]: {
          ...state.doubtConfigs[action.goalUID],
          ...action.data
        }
      },
      isDoubtsDataFetched: true
    };
  },
  [doubtsOnWeb2.UPDATE_SUBJECT_SELECTED_FLOW]: (action, state) => {
    return {
      ...state,
      subjectSelectionFlow: {
        ...state.subjectSelectionFlow,
        [action.goalUID]: {
          ...state.subjectSelectionFlow[action.goalUID],
          [action.doubtUID]: action.data
        }
      }
    };
  }
};

const doubtsOnWeb2Reducer = createReducer(reducerMap, initialState);

export default doubtsOnWeb2Reducer;
