import isEmpty from '@utils/isEmpty';
import { evaluateQuizTypes } from '../constants';

const initialState = {
  // used to denote pending api/action
  isPendingAction: false,
  data: {},
  criterias: [],
  performanceStats: {},
  feedbackChoices: [],
  questions: [],
  evaluatedResults: {},
  evaluatedCount: {},
  criteriaScores: {},
  adviceToLearner: ''
};

const modifyPendingState = (value) => (state) => ({
  ...state,
  isPendingAction: value
});

const reducerMap = {
  [`${evaluateQuizTypes.EVALUATE_QUIZ_FETCH_DETAILS}_SUCCESS`](state, action) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.evaluationUID]: action.result
      }
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_FETCH_QUESTIONS}_SUCCESS`](
    state,
    action
  ) {
    const evaluatedResults = {};
    const evaluatedCount = {};
    const questions = action.result.results;

    questions?.forEach((question) => {
      if (
        !evaluatedResults[question.section?.uid || action.defaultSectionUID]
      ) {
        evaluatedResults[question.section?.uid || action.defaultSectionUID] =
          {};

        evaluatedCount[question.section?.uid || action.defaultSectionUID] = 0;
      }

      evaluatedResults[question.section?.uid || action.defaultSectionUID][
        question.uid
      ] = {
        score: question.evaluator_skipped ? null : question.evaluator_score,
        skipped: question.evaluator_skipped || false
      };

      if (!isEmpty(question.evaluator_score) && !question.evaluator_skipped) {
        evaluatedCount[question.section?.uid || action.defaultSectionUID] += 1;
      }
    });

    return {
      ...state,
      questions,
      evaluatedCount,
      evaluatedResults
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_FETCH_CRITERIA}_REQUEST`]:
    modifyPendingState(true),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_FETCH_CRITERIA}_SUCCESS`](state, action) {
    return {
      ...state,
      isPendingAction: false,
      criterias: action.result.evaluation_criteria,
      performanceStats: action.result.performance_statistics
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_FETCH_CRITERIA}_FAILURE`]:
    modifyPendingState(false),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_FETCH_FEEDBACK_CHOICES}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      feedbackChoices: action.result
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_ADD_ADVICE_TO_LEARNER}`](state, action) {
    const { data } = action;

    return {
      ...state,
      adviceToLearner: data
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_ADD_CRITERIA_SCORE}`](state, action) {
    const { data } = action;

    return {
      ...state,
      criteriaScores: {
        ...state.criteriaScores,
        [data.uid]: data.value
      }
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_ADD_MARKS_TO_QUESTION}`](state, action) {
    const {
      data: { sectionUID, questionUID, value }
    } = action;

    const previousScore = state.evaluatedResults[sectionUID][questionUID].score;
    let count = state.evaluatedCount[sectionUID];

    if (isEmpty(previousScore) && !isEmpty(value)) {
      count += 1;
    } else if (!isEmpty(previousScore) && isEmpty(value)) {
      count -= 1;
    }

    return {
      ...state,
      evaluatedResults: {
        ...state.evaluatedResults,
        [sectionUID]: {
          ...state.evaluatedResults[sectionUID],
          [questionUID]: {
            ...state.evaluatedResults[sectionUID][questionUID],
            score: value
          }
        }
      },
      evaluatedCount: {
        ...state.evaluatedCount,
        [sectionUID]: count
      }
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_MARK_QUESTION_AS_SKIPPED}`](
    state,
    action
  ) {
    const {
      data: { sectionUID, questionUID, value }
    } = action;

    return {
      ...state,
      evaluatedResults: {
        ...state.evaluatedResults,
        [sectionUID]: {
          ...state.evaluatedResults[sectionUID],
          [questionUID]: {
            ...state.evaluatedResults[sectionUID][questionUID],
            score: null,
            skipped: value
          }
        }
      },
      evaluatedCount: {
        ...state.evaluatedCount,
        [sectionUID]:
          value &&
          !isEmpty(state.evaluatedResults[sectionUID][questionUID].score)
            ? state.evaluatedCount[sectionUID] - 1
            : state.evaluatedCount[sectionUID]
      }
    };
  },
  [`${evaluateQuizTypes.EVALUATE_QUIZ_SUBMIT_SCORE}_REQUEST`]:
    modifyPendingState(true),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_SUBMIT_SCORE}_SUCCESS`]:
    modifyPendingState(false),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_SUBMIT_SCORE}_FAILURE`]:
    modifyPendingState(false),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_REMOVE_EVALUATION}_REQUEST`]:
    modifyPendingState(true),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_REMOVE_EVALUATION}_SUCCESS`]:
    modifyPendingState(false),
  [`${evaluateQuizTypes.EVALUATE_QUIZ_REMOVE_EVALUATION}_FAILURE`]:
    modifyPendingState(false)
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
