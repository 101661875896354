const namespace = 'COMBAT_TAKING';

const types = {
  COMBAT_MODAL_TYPE: `${namespace}:MODAL_TYPE`,
  COMBAT_JOIN: `${namespace}:COMBAT_JOIN`,
  COMBAT_FETCH_INFO: `${namespace}:COMBAT_FETCH_INFO`,
  COMBAT_FETCH_STATUS: `${namespace}:COMBAT_FETCH_STATUS`,
  COMBAT_FETCH_CURRENT_QUESTION: `${namespace}:COMBAT_FETCH_CURRENT_QUESTION`,
  COMBAT_SUBMIT_ANSWER: `${namespace}:COMBAT_SUBMIT_ANSWER`,
  COMBAT_FETCH_USER_QUESTION_STATS: `${namespace}:COMBAT_FETCH_USER_QUESTION_STATS`,
  COMBAT_FETCH_USER_ANSWER_STATS: `${namespace}:COMBAT_FETCH_USER_ANSWER_STATS`,
  COMBAT_FETCH_GLOBAL_ANSWER_STATS: `${namespace}:COMBAT_FETCH_GLOBAL_ANSWER_STATS`,
  COMBAT_FETCH_LEADERBOARD: `${namespace}:COMBAT_FETCH_LEADERBOARD`,
  COMBAT_FETCH_USER_RANK: `${namespace}:COMBAT_FETCH_USER_RANK`,
  COMBAT_FETCH_BATCHES_AND_RATING: `${namespace}:COMBAT_FETCH_BATCHES_AND_RATING`,
  COMBAT_SET_ANSWERS: `${namespace}:COMBAT_SET_ANSWERS`,
  COMBAT_SET_LANGUAGE: `${namespace}:COMBAT_SET_LANGUAGE`,
  COMBAT_FETCH_SUMMARY: `${namespace}:COMBAT_FETCH_SUMMARY`,
  COMBAT_FETCH_FULL_SUMMARY: `${namespace}:COMBAT_FETCH_FULL_SUMMARY`,
  COMBAT_FETCH_FEEDBACK_OPTIONS: `${namespace}:COMBAT_FETCH_FEEDBACK_OPTIONS`,
  COMBAT_SUBMIT_FEEDBACK: `${namespace}:COMBAT_SUBMIT_FEEDBACK`,
  COMBAT_FETCH_SOLUTION: `${namespace}:COMBAT_FETCH_SOLUTION`
};

export default types;
