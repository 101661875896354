import { QUESTION_TYPE_MAP } from '@constants/quiz';

export const NAV_MENU_IDS = {
  settings: 'settings',
  issues: 'reported_issues'
};

export const DEFAULT_MENU = {
  id: NAV_MENU_IDS.settings,
  label: 'Settings'
};

export const questionTypeTitleMap = {
  [QUESTION_TYPE_MAP.SINGLE]: 'Single choice',
  [QUESTION_TYPE_MAP.MCQ]: 'Multiple choice',
  [QUESTION_TYPE_MAP.INTEGER]: 'Integer type'
};

export const ALL_QUESTION_TYPES = -1;

export const MODAL_TYPES = {
  CONCEPT_SELECTOR: 'CONCEPT_SELECTOR',
  SUB_CONCEPT_SELECTOR: 'SUB_CONCEPT_SELECTOR',
  QUESTION_CONFIG_ADD: 'QUESTION_CONFIG_ADD',
  QUESTION_CONFIG_EDIT: 'QUESTION_CONFIG_EDIT',
  QUESTION_CONFIG_DELETE: 'QUESTION_CONFIG_DELETE',
  QUESTION_DELETE: 'QUESTION_DELETE',
  QUESTION_REPLACE: 'QUESTION_REPLACE',
  QUESTION_REPORT: 'QUESTION_REPORT',
  VIEW_TEMPLATE_DETAILS: 'VIEW_TEMPLATE_DETAILS',
  CHANGE_TEMPLATE_CONFIRMATION: 'CHANGE_TEMPLATE_CONFIRMATION',
  UPLOAD_PDF_CONFIRMATION: 'UPLOAD_PDF_CONFIRMATION',
  DELETE_PDF_CONFIRMATION: 'DELETE_PDF_CONFIRMATION',
  REPLACE_PDF_CONFIRMATION: 'REPLACE_PDF_CONFIRMATION'
};

export const IMPORT_TEST_DRAWER = 'IMPORT_TEST_DRAWER';

export const IMPORT_TYPES = {
  TEST: 'Test',
  QUESTION: 'Question'
};

export const CREATE_QUIZ_TYPES = {
  IMPORT_EXISTING_QUIZ: 'IMPORT_EXISTING_QUIZ',
  FETCH_QUESTION_COUNT: 'FETCH_QUESTION_COUNT',
  CREATE_QUIZ_UPDATE_QUESTION_COUNT: 'CREATE_QUIZ_UPDATE_QUESTION_COUNT',
  CREATE_QUIZ_UPDATE_SELECTED_SUB_CONCEPTS:
    'CREATE_QUIZ_UPDATE_SELECTED_SUB_CONCEPTS',
  SET_PREVIOUS_SELECTED_STATE: 'SET_PREVIOUS_SELECTED_STATE',
  ADD_DIFFICULTY_LEVELS_TO_CONCEPT: 'ADD_DIFFICULTY_LEVELS_TO_CONCEPT',
  SET_QUESTION_COUNT_FILTER: 'SET_QUESTION_COUNT_FILTER'
};

export const IMPORT_EVENTS = {
  TEST_IMPORTED: 'Test Creation - Import Details'
};

export const DIFFICULTY_CODES = [-1, 1, 2, 3, 4, 5];

export const difficulties = [
  {
    label: 'All',
    code: -1
  },
  {
    label: 'Very Easy',
    code: 1
  },
  {
    label: 'Easy',
    code: 2
  },
  {
    label: 'Moderate',
    code: 3
  },
  {
    label: 'Hard',
    code: 4
  },
  {
    label: 'Very hard',
    code: 5
  }
];
