import merge from 'lodash/merge';
import mergeDeep from '@utils/mergeDeep';
import { compareDates } from '@utils/dates';
import {
  courseTypes,
  plusCoursesTypes,
  itemTypes,
  searchTypes
} from '../constants';

const initialState = {
  data: {},
  courseDetails: {},
  items: {},
  seeAllFeedEBInfo: {},
  relevantCourses: {},
  onboardingCourses: {},
  moreCoursesFilters: {},
  checkEnrollResults: [],
  enrolledCoursesFilters: {},
  similarPlusCourses: { type: `${plusCoursesTypes.FETCH_SIMILAR}_REQUEST` }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case courseTypes.UPDATE_COURSES:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    case `${courseTypes.FETCH_COURSE_INFO}_SUCCESS`:
      return {
        ...state,
        courseDetails: {
          ...state.courseDetails,
          [action.courseUid]: action.result
        }
      };

    case `${courseTypes.TOGGLE_COURSE_ENROLLED}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.courseUID]: merge(state.data[action.courseUID], {
            isEnrolled: action.enroll
          })
        }
      };

    case `${courseTypes.ADD_COMPLETED_COURSE_TO_PLANNER}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.courseUID]: {
            ...state.data[action.courseUID],
            plannerSchedule: action.plannerSchedule
          }
        }
      };

    case `${courseTypes.REMOVE_COURSE_FROM_PLANNER}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.courseUID]: {
            ...state.data[action.courseUID],
            plannerSchedule: { is_scheduled: false }
          }
        }
      };

    case `${courseTypes.GET_PLANNER_SCHEDULE}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.courseUID]: merge(state.data[action.courseUID], {
            plannerSchedule: action.result.planner_schedule,
            isCompleted: compareDates(action.result.ends_at, new Date()),
            totalPracticeDPPCount: action.result?.practice_dpp_count || 0,
            totalPracticeQuestionCount:
              action.result?.total_practice_dpp_question_count || 0
          })
        }
      };

    case `${plusCoursesTypes.FETCH_SEE_ALL_EBINFO}_SUCCESS`:
      return {
        ...state,
        seeAllFeedEBInfo: {
          ...state.seeAllFeedEBInfo,
          [`${action.seeAllType}-${action.topologyUid}-${action.blockType}`]:
            action.result
        }
      };

    case `${plusCoursesTypes.FETCH_SEE_ALL_EBINFO}_FAILURE`:
      return {
        ...state,
        seeAllFeedEBInfo: {
          ...state.seeAllFeedEBInfo,
          [`${action.seeAllType}-${action.topologyUid}-${action.blockType}`]: {
            error: action.error
          }
        }
      };

    case `${courseTypes.FETCH_RELEVANT_COURSES}_SUCCESS`:
      return {
        ...state,
        relevantCourses: {
          ...state.relevantCourses,
          [action.courseUID]: action.result
        }
      };

    case `${searchTypes.FETCH_SEARCH_LIST}_SUCCESS`:
      return {
        ...state,
        searchFilters: action.result.filters
      };

    case `${itemTypes.BOOKMARK_ITEM}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uid]: merge(state.data[action.uid], {
            isBookmarked: action.isBookmarked
          })
        }
      };

    case `${courseTypes.FETCH_ONBOARDING_RECOMMENDED_COURSES}_SUCCESS`:
      return {
        ...state,
        onboardingCourses: {
          ...state.onboardingCourses,
          [action.goalUid]: action.result
        }
      };

    case `${plusCoursesTypes.FETCH_SIMILAR}_REQUEST`:
      return {
        ...state,
        similarPlusCourses: {
          ...state.similarPlusCourses,
          type: `${plusCoursesTypes.FETCH_SIMILAR}_REQUEST`
        }
      };

    case `${plusCoursesTypes.FETCH_SIMILAR}_SUCCESS`:
      return {
        ...state,
        similarPlusCourses: {
          ...state.similarPlusCourses,
          type: `${plusCoursesTypes.FETCH_SIMILAR}_SUCCESS`,
          [action.key]: action.result
        }
      };

    case `${plusCoursesTypes.FETCH_NEXT_TRACK}_SUCCESS`:
      return {
        ...state,
        nextTrack: {
          ...state.nextTrack,
          [action.key]: action.result
        }
      };
    case `${courseTypes.FETCH_MORE_COURSES_FILTERS}_SUCCESS`: {
      return {
        ...state,
        moreCoursesFilters: {
          ...state.moreCoursesFilters,
          [action.goalUID]: action.result
        }
      };
    }
    case `${courseTypes.CHECK_COURSE_ENROLL}_SUCCESS`:
      return {
        ...state,
        checkEnrollResults: action.result
      };

    case `${courseTypes.FETCH_ENROLLED_COURSES_FILTERS}_SUCCESS`:
      return {
        ...state,
        enrolledCoursesFilters: action.result
      };

    case `${courseTypes.GET_ALL_POPULAR_SUBJECTS}_SUCCESS`:
      return {
        ...state,
        allPopularSubjects: action.result
      };

    default:
      return state;
  }
};

export default reducer;
