/* eslint-disable max-lines */
import Queue from '@utils/queue';
import { calculatorType, testTypes } from '@constants/index';
import { emptyObject } from '@constants/empty';
import { ATTEMPT_SUMMARY_TYPE, WIDTH_MAX, WIDTH_MIN } from './constants';

export const getScreenWidth = () => {
  if (typeof window !== 'undefined') {
    const screenWidth = window.screen.width;

    if (screenWidth >= WIDTH_MAX) {
      return WIDTH_MAX;
    }
  }

  return WIDTH_MIN;
};

export const zeroPad = (number) => {
  return `0${number}`.slice(-2);
};

export const removeProperty = (obj, property) => {
  if (!obj) {
    return;
  }
  const { [property]: _, ...rest } = obj;

  return rest;
};

export const failedSubmitAPIQueue = new Queue();

export const getSectionInfo = (sections = [], currentQuestionRank) => {
  let currentSectionIndex = 0;
  let currentSectionStartingRank = 1;

  for (let i = 1; i <= sections.length; i += 1) {
    const sum = currentSectionStartingRank + sections[i - 1].questions_count;

    if (sum > currentQuestionRank) {
      break;
    }

    currentSectionStartingRank = sum;
    currentSectionIndex = i;
  }

  return {
    startingRank: currentSectionStartingRank,
    sectionIndex: currentSectionIndex
  };
};

export const setupInitialQuestionsData = (sections) => {
  const answersBySection = {};
  const markedQuestionsBySection = {};
  const savedQuestionsBySection = {};
  const unansweredQuestionsBySection = {};

  sections.forEach((section) => {
    answersBySection[section.uid] = {};
    markedQuestionsBySection[section.uid] = {};
    savedQuestionsBySection[section.uid] = {};
    unansweredQuestionsBySection[section.uid] = {};
  });

  return {
    answersBySection,
    markedQuestionsBySection,
    savedQuestionsBySection,
    unansweredQuestionsBySection
  };
};

export const preProcessQuestionsAdditionalData = (
  sections,
  questions,
  isSubjective = false
) => {
  const {
    answersBySection,
    markedQuestionsBySection,
    savedQuestionsBySection,
    unansweredQuestionsBySection
  } = setupInitialQuestionsData(sections);

  questions.forEach((question) => {
    const { user_attempt_list, is_marked, is_saved } = question;

    const isQuestionSubjectiveAndAttempted =
      isSubjective && user_attempt_list?.[0] === '1';

    const isQuestionSubjectiveAndNotAttempted =
      isSubjective && user_attempt_list?.[0] === '0';

    if (question.section) {
      if (
        user_attempt_list?.length &&
        (isQuestionSubjectiveAndAttempted || !isSubjective)
      ) {
        answersBySection[question.section.uid][question.uid] =
          question.user_attempt_list;
      }

      if (
        (!user_attempt_list?.length && !is_marked) ||
        isQuestionSubjectiveAndNotAttempted
      ) {
        unansweredQuestionsBySection[question?.section?.uid][
          question?.uid
        ] = true;
      }

      if (is_marked) {
        markedQuestionsBySection[question.section.uid][question.uid] = true;
      }

      if (is_saved) {
        savedQuestionsBySection[question.section.uid][question.uid] = true;
      }
    } else {
      if (
        user_attempt_list?.length &&
        (isQuestionSubjectiveAndAttempted || !isSubjective)
      ) {
        answersBySection[sections[0].uid][question.uid] =
          question.user_attempt_list;
      }
      if (
        (!user_attempt_list?.length && !is_marked) ||
        isQuestionSubjectiveAndNotAttempted
      ) {
        unansweredQuestionsBySection[sections[0]?.uid][question?.uid] = true;
      }

      if (is_marked) {
        markedQuestionsBySection[sections[0].uid][question.uid] = true;
      }

      if (is_saved) {
        savedQuestionsBySection[sections[0].uid][question.uid] = true;
      }
    }
  });

  return {
    answersBySection,
    markedQuestionsBySection,
    savedQuestionsBySection,
    unansweredQuestionsBySection
  };
};

export const preProcessFetchedQuestions = (sections, questions) => {
  const questionsBySection = {};

  sections.forEach((section) => {
    questionsBySection[section.uid] = {
      allIds: [],
      byId: {}
    };
  });

  questions.forEach((question) => {
    const { user_attempt_list, is_marked, is_saved, ...others } = question;

    if (question.section) {
      questionsBySection[question.section.uid].allIds.push(question.uid);
      questionsBySection[question.section.uid].byId[question.uid] = others;
    } else {
      questionsBySection[sections[0].uid].allIds.push(question.uid);
      questionsBySection[sections[0].uid].byId[question.uid] = others;
    }
  });

  return questionsBySection;
};

export const getCurrentQuestionInfo = (
  sections,
  questionUID,
  questionsBySection
) => {
  const defaultSectionUID = sections[0]?.uid;
  const defaultInfo = {
    sectionUID: defaultSectionUID,
    questionUID: questionsBySection?.[defaultSectionUID]?.allIds[0]
  };

  if (!questionUID) {
    return defaultInfo;
  }

  const sectionsLength = sections.length;

  for (let i = 0; i < sectionsLength; i += 1) {
    const currentSectionUID = sections[i].uid;

    const hasQuestion = questionsBySection[currentSectionUID].byId[questionUID];

    if (hasQuestion) {
      return {
        sectionUID: currentSectionUID,
        questionUID
      };
    }
  }

  return defaultInfo;
};

export const setSubmittedQuestionResponse = ({
  answersBySection,
  questionUID,
  attemptList,
  isSubjective
}) => {
  const isQuestionSubjectiveAndNotAttempted =
    isSubjective && attemptList?.[0] !== '0';

  if (
    isQuestionSubjectiveAndNotAttempted ||
    (!isSubjective && attemptList?.length)
  ) {
    return {
      ...answersBySection,
      [questionUID]: attemptList
    };
  }
  return removeProperty(answersBySection, questionUID);
};

export const setUnansweredQuestionList = ({
  unansweredQuestionsBySection = {},
  attemptList,
  isSubjective,
  questionUID,
  isMarked
}) => {
  if (isMarked) {
    return { ...unansweredQuestionsBySection };
  }
  if (
    (isSubjective && attemptList?.[0] === '0') ||
    (!isSubjective && !attemptList?.length)
  ) {
    return { ...unansweredQuestionsBySection, [questionUID]: true };
  }
  return removeProperty(unansweredQuestionsBySection, questionUID);
};

export const setUnansweredQuestionListOnMarktoggle = ({
  unansweredQuestionsBySection = {},
  questionUID,
  isMarked,
  isAnswered
}) => {
  if (isAnswered) {
    return { ...unansweredQuestionsBySection };
  }
  if (!isMarked) {
    return { ...unansweredQuestionsBySection, [questionUID]: true };
  }
  return removeProperty(unansweredQuestionsBySection, questionUID);
};

export const preProcessFetchedSolutionFilters = (filters) => {
  const solutionFirstLevelFilters = [];
  const solutionSecondLevelFiltersByUID = {};

  filters.forEach(({ uid, title, topics }) => {
    solutionSecondLevelFiltersByUID[uid] = topics.length
      ? topics.map((topic) => ({
          value: topic.uid,
          label: topic.title
        }))
      : [];

    solutionFirstLevelFilters.push({
      value: uid,
      label: title
    });
  });

  return {
    solutionFirstLevelFilters,
    solutionSecondLevelFiltersByUID
  };
};

export const getQuizType = (quizType) => {
  const isSubjective = quizType === testTypes.SUBJCETIVE;
  const isObjective = quizType === testTypes.OBJECTIVE;
  const isContent = quizType === testTypes.CONTEST;
  const isDummy = quizType === testTypes.DUMMY_ENTRY;

  return {
    isSubjective,
    isObjective,
    isContent,
    isDummy
  };
};

export const checkIfSessionExists = (sessions = [], sessionUIDToCheck) => {
  if (!sessionUIDToCheck) {
    return false;
  }

  return !!sessions.find(({ uid }) => uid === sessionUIDToCheck);
};

export const hasCalculator = (type) =>
  type ? type !== calculatorType.CALCULATOR_NOT_SELECTED : false;

export const scrollIntoViewIfNeeded = ({ target, paddingInPixel = 0 }) => {
  if (
    target.getBoundingClientRect().bottom + paddingInPixel >
    window.innerHeight
  ) {
    return target.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }
};

export const getAttemptValueMap = (attemptStates = {}) => {
  const valueMap = {};
  const {
    totalQuestions,
    marked,
    unanswered,
    answered,
    markedAndAttempted,
    notVisitedQuestions
  } = attemptStates;

  valueMap.TOTAL_QUESTIONS = totalQuestions;
  valueMap.ANSWERED = answered;
  valueMap.UNANSWERED = unanswered;
  valueMap.MARKED = marked;
  valueMap.MARKED_AND_ANSWERED = markedAndAttempted;
  valueMap.NOT_VISITED = notVisitedQuestions;

  return valueMap;
};

export const getMaxPossibleUnansweredQuestionCount = (
  unansweredQuestionsBySection,
  sectionUID,
  maxAnswerableQuestionBySection,
  answersBySection
) => {
  const totalCount = Object.keys(
    unansweredQuestionsBySection?.[sectionUID] ?? emptyObject
  ).length;

  const maxQuestionToBeAnswered = maxAnswerableQuestionBySection[sectionUID];

  const answeredQuestions = Object.keys(answersBySection?.[sectionUID]).length;

  if (maxQuestionToBeAnswered - answeredQuestions < totalCount) {
    return maxQuestionToBeAnswered - answeredQuestions;
  }

  return totalCount;
};

export const getUnansweredQuestionCount = ({
  unansweredQuestionsBySection,
  maxAnswerableQuestionBySection,
  answersBySection,
  onGoingSectionUID,
  summaryType
}) => {
  if (
    onGoingSectionUID &&
    unansweredQuestionsBySection[onGoingSectionUID] &&
    summaryType === ATTEMPT_SUMMARY_TYPE.SECTION
  ) {
    return getMaxPossibleUnansweredQuestionCount(
      unansweredQuestionsBySection,
      onGoingSectionUID,
      maxAnswerableQuestionBySection,
      answersBySection
    );
  }
  return Object.keys(unansweredQuestionsBySection).reduce(
    (sum, currentSectionUID) =>
      sum +
      getMaxPossibleUnansweredQuestionCount(
        unansweredQuestionsBySection,
        currentSectionUID,
        maxAnswerableQuestionBySection,
        answersBySection
      ),
    0
  );
};

export const getMarkedAndAttemptedQuestionCountForSection = (
  sectionalUID,
  markedData = {},
  attemptedData = {}
) => {
  return Object.keys(markedData[sectionalUID]).reduce((sum, questionUid) => {
    if (attemptedData[sectionalUID][questionUid]) {
      return sum + 1;
    }
    return sum;
  }, 0);
};

export const getMaxPossibleMarkedCount = (
  markedQuestionsBySection,
  sectionUID,
  maxAnswerableQuestionBySection,
  answersBySection
) => {
  const markedAndAttempted = getMarkedAndAttemptedQuestionCountForSection(
    sectionUID,
    markedQuestionsBySection,
    answersBySection
  );

  const totalCount =
    Object.keys(markedQuestionsBySection?.[sectionUID]).length -
    markedAndAttempted;

  const maxQuestionToBeAnswered = maxAnswerableQuestionBySection[sectionUID];

  const answeredQuestions = Object.keys(answersBySection?.[sectionUID]).length;

  if (maxQuestionToBeAnswered - answeredQuestions < totalCount) {
    return maxQuestionToBeAnswered - answeredQuestions;
  }
  return totalCount;
};

export const getMarkedQuestionCount = ({
  markedQuestionsBySection,
  maxAnswerableQuestionBySection = {},
  answersBySection = {},
  onGoingSectionUID,
  summaryType
}) => {
  if (
    onGoingSectionUID &&
    markedQuestionsBySection[onGoingSectionUID] &&
    summaryType === ATTEMPT_SUMMARY_TYPE.SECTION
  ) {
    return getMaxPossibleMarkedCount(
      markedQuestionsBySection,
      onGoingSectionUID,
      maxAnswerableQuestionBySection,
      answersBySection
    );
  }
  return Object.keys(markedQuestionsBySection).reduce(
    (sum, currentSectionUID) =>
      sum +
      getMaxPossibleMarkedCount(
        markedQuestionsBySection,
        currentSectionUID,
        maxAnswerableQuestionBySection,
        answersBySection
      ),
    0
  );
};

export const getAnsweredQuestionCount = ({
  answersBySection,
  onGoingSectionUID,
  summaryType
}) => {
  if (
    onGoingSectionUID &&
    answersBySection[onGoingSectionUID] &&
    summaryType === ATTEMPT_SUMMARY_TYPE.SECTION
  ) {
    return Object.keys(answersBySection?.[onGoingSectionUID]).length;
  }
  return Object.keys(answersBySection).reduce(
    (sum, currentSectionUID) =>
      sum + Object.keys(answersBySection?.[currentSectionUID]).length,
    0
  );
};

export const getMarkedAndAttemptedQuestionCountForTest = (
  markedData = {},
  attemptedData = {}
) => {
  return Object.keys(markedData).reduce((totalSum, sectionalUID) => {
    return (
      totalSum +
      getMarkedAndAttemptedQuestionCountForSection(
        sectionalUID,
        markedData,
        attemptedData
      )
    );
  }, 0);
};

export const getMarkedAndAttemptedQuestionCount = ({
  markedQuestionsBySection: markedData,
  answersBySection: attemptedData,
  onGoingSectionUID,
  summaryType
}) =>
  summaryType === ATTEMPT_SUMMARY_TYPE.SECTION
    ? getMarkedAndAttemptedQuestionCountForSection(
        onGoingSectionUID,
        markedData,
        attemptedData
      )
    : getMarkedAndAttemptedQuestionCountForTest(markedData, attemptedData);

export const getMaxAnswerableQuestions = ({
  maxAnswerableQuestionBySection,
  onGoingSectionUID,
  summaryType
}) => {
  if (onGoingSectionUID && summaryType === ATTEMPT_SUMMARY_TYPE.SECTION) {
    return maxAnswerableQuestionBySection[onGoingSectionUID];
  }
  if (summaryType === ATTEMPT_SUMMARY_TYPE.TEST) {
    return Object.keys(maxAnswerableQuestionBySection).reduce(
      (sum, currentSectionUID) =>
        sum + maxAnswerableQuestionBySection[currentSectionUID],
      0
    );
  }
};

const notVisitedQuestionsBySection = ({
  maxAnswerableQuestionBySection,
  markedQuestionsBySection,
  unansweredQuestionsBySection,
  answersBySection,
  sectionUID
}) => {
  const markedCount = getMaxPossibleMarkedCount(
    markedQuestionsBySection,
    sectionUID,
    maxAnswerableQuestionBySection,
    answersBySection
  );

  const unansweredCount = getMaxPossibleUnansweredQuestionCount(
    unansweredQuestionsBySection,
    sectionUID,
    maxAnswerableQuestionBySection,
    answersBySection
  );

  const markedAndAttemptedCount = getMarkedAndAttemptedQuestionCountForSection(
    sectionUID,
    markedQuestionsBySection,
    answersBySection
  );

  const answeredQuestions =
    Object.keys(answersBySection?.[sectionUID] ?? emptyObject).length -
    markedAndAttemptedCount;

  const stateCount =
    markedCount + unansweredCount + answeredQuestions + markedAndAttemptedCount;

  const maxQuestionToBeAnswered = maxAnswerableQuestionBySection?.[sectionUID];

  const totalNotVisitedQuestions = maxQuestionToBeAnswered - stateCount;

  return Math.max(0, totalNotVisitedQuestions);
};

export const getNotVisitedQuestions = ({
  maxAnswerableQuestionBySection,
  answersBySection,
  markedQuestionsBySection,
  unansweredQuestionsBySection,
  onGoingSectionUID,
  summaryType
}) => {
  if (onGoingSectionUID && summaryType === ATTEMPT_SUMMARY_TYPE.SECTION) {
    return notVisitedQuestionsBySection({
      maxAnswerableQuestionBySection,
      markedQuestionsBySection,
      unansweredQuestionsBySection,
      answersBySection,
      sectionUID: onGoingSectionUID
    });
  }
  if (summaryType === ATTEMPT_SUMMARY_TYPE.TEST) {
    return Object.keys(maxAnswerableQuestionBySection ?? emptyObject).reduce(
      (sum, currentSectionUID) =>
        sum +
        notVisitedQuestionsBySection({
          maxAnswerableQuestionBySection,
          markedQuestionsBySection,
          unansweredQuestionsBySection,
          answersBySection,
          sectionUID: currentSectionUID
        }),
      0
    );
  }
};

export const isRevampedTestVersion2 = (goalUID, constants = {}) => {
  const testRevampGoalsList = constants?.TEST_EXPERIENCE_V2_GOALS;
  const isTestRevamp = testRevampGoalsList?.includes(goalUID);
  return isTestRevamp;
};

export const getCaclculatorWidth = (isScientific) => {
  if (isScientific) {
    return '640px';
  }
  return '264px';
};

export const getCaclculatorHeight = (isScientific) => {
  if (isScientific) {
    return '412px;';
  }

  return '410px';
};

export const getPostionX = (isTestRevampFlow, isScientific) => {
  if (isTestRevampFlow) {
    if (isScientific) return 'calc(-150% + 40px)';
    return 'calc(-50% + 140px)';
  }

  if (isScientific) {
    return 'calc(50% - 40px)';
  }
  return '66%';
};

export const modeDetails = {
  1: 'Paper-based test',
  2: 'Computer-based test'
};
