import notificationHelper from '@utils/notificationHelper';
import { notificationTypes } from '../constants';

const initialState = {
  isNotificationsFetching: false,
  results: [],
  nTimesFetchedNotifications: 0,
  nextPageToken: null
};

export default function notificationState(state = initialState, action) {
  switch (action.type) {
    case `${notificationTypes.FETCH_NOTIFICATIONS}_REQUEST`: {
      return {
        ...state,
        isNotificationsFetching: true
      };
    }
    case `${notificationTypes.FETCH_NOTIFICATIONS}_SUCCESS`: {
      /* Manipulating notifications */
      const notifications = action.result.notifications.map((item) => {
        const { det } = item;
        const { url } = det || {};
        let href;
        let urlPath = url ? url.replace('https://unacademy.com', '') : '';
        const type = item.type ? item.type.toLowerCase() : '';

        if (type === 'testresultsready') {
          urlPath = urlPath.replace(/\/$/, '');
          const sessionuid = urlPath.split(/\/results\/(.*)/)[1];
          href = {
            pathname: notificationHelper.getHref(type),
            query: { sessionuid }
          };
          urlPath = urlPath.replace('/results/', '?sessionuid=');
        } else if (type === 'dailypracticepractice') {
          // Need to fix this url from backend
          // For now replace with the new url and redirect to home page

          href = '/goal/[goalSlug]/[goalUID]/practice';
          urlPath = `/goal/${action.selectedGoal.goalSlug}/${action.selectedGoal.uid}/practice`;
        } else if (urlPath && urlPath.endsWith('/planner')) {
          // handling href for platform batch groups notifications
          href = '/goal/[goalSlug]/[goalUID]/planner';
        } else {
          href = notificationHelper.getHref(type) || urlPath || '';
        }
        const as = urlPath;

        return { ...item, type, det: { ...det, href, as, urlPath } };
      });

      return {
        ...state,
        isNotificationsFetching: false,
        results: !action.currentPageToken
          ? notifications
          : [...state.results, ...notifications],
        nextPageToken: action.result.next_page_token,
        notificationsFetchedOnce: true
      };
    }
    case `${notificationTypes.FETCH_NOTIFICATIONS}_FAILURE`: {
      return {
        ...state,
        isNotificationsFetching: false
      };
    }
    case `${notificationTypes.MARK_NOTIFICATION_READ}_SUCCESS`: {
      return {
        ...state,
        results: state.results.map((notification) => {
          return notification.nid === action.nid
            ? { ...notification, rat: true }
            : { ...notification };
        })
      };
    }
    default: {
      return { ...state };
    }
  }
}
