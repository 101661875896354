import { salesTypes } from '../constants';

const compare = (obj1, obj2) => {
  if (obj1.referral.discount_percent > obj2.referral.discount_percent)
    return -1;
  if (obj1.referral.discount_percent < obj2.referral.discount_percent) return 1;
  return 0;
};

const initialState = {
  callAssistFeed: [],
  relatedComboPlans: {},
  goalPricings: {},
  avlPaymentOptions: {},
  leadDetails: {},
  pitchAssistStats: {},
  crmEligibleGoals: [],
  latestSubscriptions: {},
  favEducators: {},
  suggestedRefferals: {},
  educatorsRecommended: {},
  batchesRecommended: {},
  coursesRecommended: {},
  testseriesRecommended: {},
  activationRecommended: {},
  avlOffers: {},
  heroData: {},
  avlLangFilters: [],
  searchedEducators: [],
  mentorDetails: {},
  fetchingMentorDetails: true,
  fetchingLmpToggleAvl: false,
  lmpSessionDetails: {},
  lmpFeedbackOptions: {}
};

const fetchCallAssistFeedSuccess = (_, { result }) => ({
  callAssistFeed: result
});

const fetchCRMGoalsSuccess = (_, { result }) => ({
  crmEligibleGoals: result?.goals
});

const fetchComboPlansSuccess = (state, { result, key }) => ({
  relatedComboPlans: {
    ...state.relatedComboPlans,
    [key]: result
  }
});

const fetchPricingsSuccess = (state, { result, uid }) => ({
  goalPricings: {
    ...state.goalPricings,
    [uid]: result
  }
});

const fetchAVLPaymentOptsSuccess = (state, { result, key }) => ({
  avlPaymentOptions: {
    ...state.avlPaymentOptions,
    [key]: result
  }
});

const fetchLeadDetailsRequest = (state, { pageLoad }) => {
  if (pageLoad) {
    return {
      leadDetailsFetchStat: {
        isLoading: true,
        fetched: false
      }
    };
  }
  return {};
};

const fetchLeadDetailsSuccess = (state, { result, key }) => ({
  leadDetailsFetchStat: {
    isLoading: false,
    fetched: true
  },
  leadDetails: {
    ...state.leadDetails,
    [key]: result
  }
});

const fetchLeadDetailsFailure = () => ({
  leadDetailsFetchStat: {
    isLoading: false,
    fetched: false
  }
});

const fetchSubscribedSubscriptionsSuccess = (state, { result, key }) => ({
  latestSubscriptions: {
    ...state.latestSubscriptions,
    [key]: result
  }
});

const fetchFavEducatorSuccess = (state, { result, key }) => ({
  favEducators: {
    ...state.favEducators,
    [key]: result
  }
});

const fetchRecommendedEduSuccess = (state, { result, key }) => ({
  educatorsRecommended: {
    ...state.educatorsRecommended,
    [key]: result
  }
});

const fetchRecommendedBatchesSuccess = (state, { result, key }) => ({
  batchesRecommended: {
    ...state.batchesRecommended,
    [key]: result
  }
});

const fetchRecommendedCoursesSuccess = (state, { result, key }) => ({
  coursesRecommended: {
    ...state.coursesRecommended,
    [key]: result
  }
});

const fetchRecommendedTestSeriesSuccess = (state, { result, key }) => ({
  testseriesRecommended: {
    ...state.testseriesRecommended,
    [key]: result
  }
});

const fetchRecActivationPitchSuccess = (state, { result, key }) => ({
  activationRecommended: {
    ...state.activationRecommended,
    [key]: result
  }
});

const fetchHeroDataSuccess = (state, { result, key }) => ({
  heroData: {
    ...state.heroData,
    [key]: result
  }
});

const fetchStatsSuccess = (state, { result, key }) => ({
  pitchAssistStats: {
    ...state.pitchAssistStats,
    [key]: result
  }
});

const fetchSuggestedReferralSuccess = (state, { result, key }) => ({
  suggestedRefferals: {
    ...state.suggestedRefferals,
    [key]: result.sort(compare)
  }
});

const fetchLanguageFiltersSuccess = (state, { result, key }) => ({
  avlLangFilters: {
    ...state.avlLangFilters,
    [key]: result
  }
});

const searchEducators = (_, { result }) => ({
  searchedEducators: result.results
});

const clearSearchEducators = () => ({
  searchedEducators: []
});

const fetchAvlOffersSuccess = (state, { result, key }) => ({
  avlOffers: {
    ...state.avlOffers,
    [key]: result
  }
});

const fetchMentorDetailsRequest = () => ({
  fetchingMentorDetails: true
});

const fetchMentorDetailsSuccess = (_, { result }) => ({
  fetchingMentorDetails: false,
  mentorDetails: result
});

const fetchMentorDetailsFailure = () => ({
  fetchingMentorDetails: false
});

const toggleLmpAvlRequest = () => ({
  fetchingLmpToggleAvl: true
});

const toggleLmpAvlFailure = () => ({
  fetchingLmpToggleAvl: false
});

const toggleLmpAvlSucess = (state, { result }) => ({
  fetchingLmpToggleAvl: false,
  mentorDetails: {
    ...state.mentorDetails,
    ...result
  }
});

const fetchLmpSessionDetailsSucess = (_, { result }) => ({
  lmpSessionDetails: result
});

const clearLmpSessionDetails = () => ({
  lmpSessionDetails: {}
});

const fetchLoanEligibleGoals = (_, { result }) => ({
  loan_eligible_goals: result?.eligible_goals,
  loan_gateways: result?.loan_gateways_info
});

const fetchLmpFeedbackOptions = (_, { result }) => ({
  lmpFeedbackOptions: result
});

const reducerMap = {
  [`${salesTypes.FETCH_CALL_ASSIST_FEED}_SUCCESS`]: fetchCallAssistFeedSuccess,
  [`${salesTypes.FETCH_CRM_GOALS}_SUCCESS`]: fetchCRMGoalsSuccess,
  [`${salesTypes.FETCH_COMBO_PLANS}_SUCCESS`]: fetchComboPlansSuccess,
  [`${salesTypes.FETCH_PRICINGS}_SUCCESS`]: fetchPricingsSuccess,
  [`${salesTypes.FETCH_AVL_PAYMENT_OPTS}_SUCCESS`]: fetchAVLPaymentOptsSuccess,
  [`${salesTypes.FETCH_LEAD_DETAILS}_REQUEST`]: fetchLeadDetailsRequest,
  [`${salesTypes.FETCH_LEAD_DETAILS}_SUCCESS`]: fetchLeadDetailsSuccess,
  [`${salesTypes.FETCH_LEAD_DETAILS}_FAILURE`]: fetchLeadDetailsFailure,
  [`${salesTypes.FETCH_SUBSCRIBED_SUBSCRIPTIONS}_SUCCESS`]:
    fetchSubscribedSubscriptionsSuccess,
  [`${salesTypes.FETCH_FAV_EDUCATOR}_SUCCESS`]: fetchFavEducatorSuccess,
  [`${salesTypes.FETCH_RECOMMENDED_EDU}_SUCCESS`]: fetchRecommendedEduSuccess,
  [`${salesTypes.FETCH_RECOMMENDED_BATCHES}_SUCCESS`]:
    fetchRecommendedBatchesSuccess,
  [`${salesTypes.FETCH_RECOMMENDED_COURSES}_SUCCESS`]:
    fetchRecommendedCoursesSuccess,
  [`${salesTypes.FETCH_RECOMMENDED_TEST_SERIES}_SUCCESS`]:
    fetchRecommendedTestSeriesSuccess,
  [`${salesTypes.FETCH_REC_ACTIVATION_PITCH}_SUCCESS`]:
    fetchRecActivationPitchSuccess,
  [`${salesTypes.SEARCH_EDUCATORS}_SUCCESS`]: searchEducators,
  [salesTypes.CLEAR_SEARCH_EDUCATORS]: clearSearchEducators,
  [`${salesTypes.FETCH_STATS}_SUCCESS`]: fetchStatsSuccess,
  [`${salesTypes.FETCH_HERO_DATA}_SUCCESS`]: fetchHeroDataSuccess,
  [`${salesTypes.FETCH_SUGGESTED_REFFERAL}_SUCCESS`]:
    fetchSuggestedReferralSuccess,
  [`${salesTypes.FETCH_AVL_OFFERS}_SUCCESS`]: fetchAvlOffersSuccess,
  [`${salesTypes.FETCH_ELIGIBLE_GOALS}_SUCCESS`]: fetchLoanEligibleGoals,
  [`${salesTypes.FETCH_LANG_FILTERS}_SUCCESS`]: fetchLanguageFiltersSuccess,
  [`${salesTypes.FETCH_MENTOR_DETAILS}_REQUEST`]: fetchMentorDetailsRequest,
  [`${salesTypes.FETCH_MENTOR_DETAILS}_SUCCESS`]: fetchMentorDetailsSuccess,
  [`${salesTypes.FETCH_MENTOR_DETAILS}_FAILURE`]: fetchMentorDetailsFailure,
  [`${salesTypes.TOGGLE_LMP_AVAILABILITY}_REQUEST`]: toggleLmpAvlRequest,
  [`${salesTypes.TOGGLE_LMP_AVAILABILITY}_SUCCESS`]: toggleLmpAvlSucess,
  [`${salesTypes.TOGGLE_LMP_AVAILABILITY}_FAILURE`]: toggleLmpAvlFailure,
  [`${salesTypes.FETCH_LMP_SESSION_DETAILS}_SUCCESS`]:
    fetchLmpSessionDetailsSucess,
  [`${salesTypes.CLEAR_LMP_SESSION_DETAILS}`]: clearLmpSessionDetails,
  [`${salesTypes.FETCH_LMP_FEEDBACK_OPTIONS}_SUCCESS`]: fetchLmpFeedbackOptions,
  [`${salesTypes.FETCH_AVL_OFFERS}_SUCCESS`]: fetchAvlOffersSuccess
};

const salesReducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler
    ? {
        ...state,
        ...actionHandler(state, action)
      }
    : state;
};

export default salesReducer;
