// import merge from 'lodash/merge';
import merge from 'lodash/merge';
import mergeDeep from '@utils/mergeDeep';
import camelcaseKeys from 'camelcase-keys';
import { batchTypes } from '../constants';

const initialState = {
  data: {},
  batchDetails: {},
  topicGroups: {},
  testSeries: {},
  topicsWithConcepts: {},
  itemsInCourse: {},
  similarBatches: {},
  calendarData: {},
  scheduledClasses: {},
  trialClasses: {},
  batchFaqs: {},
  feedbacks: {},
  recordedBatchData: {},
  isLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case batchTypes.UPDATE_BATCHES:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };
    case `${batchTypes.FETCH_BATCH_INFO}_SUCCESS`:
    case `${batchTypes.FETCH_BATCH_GROUP_INFO}_SUCCESS`: {
      return {
        ...state,
        batchDetails: {
          ...state.batchDetails,
          [action.key]: action.result
        }
      };
    }

    case `${batchTypes.FETCH_TOPICS_COVERED}_SUCCESS`: {
      return {
        ...state,
        topicGroups: {
          ...state.topicGroups,
          [action.key]: action.result
        }
      };
    }

    case `${batchTypes.FETCH_TOPICS_WITH_CHILDREN}_SUCCESS`: {
      return {
        ...state,
        topicsWithConcepts: {
          ...state.topicsWithConcepts,
          [action.key]: action.result
        }
      };
    }

    case `${batchTypes.FETCH_ITEMS_IN_COURSE}_SUCCESS`: {
      return {
        ...state,
        itemsInCourse: {
          ...state.itemsInCourse,
          [action.key]: action.result
        }
      };
    }

    case `${batchTypes.ENROLL_IN_BATCH}_SUCCESS`: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.batchUID]: merge(state.data[action.batchUID], {
            isEnrolled: action.enroll
          })
        }
      };
    }

    case `${batchTypes.FETCH_CALENDAR_DATA}_SUCCESS`: {
      return {
        ...state,
        calendarData: {
          ...state.calendarData,
          [`${action.batchUID}_${action.month}`]: action.result
        }
      };
    }

    case `${batchTypes.FETCH_SCHEDULED_CLASSES}_REQUEST`:
      return {
        ...state,
        isFetchingSchedule: true,
        scheduledClasses: action.resetWithoutEmpty
          ? null
          : state.scheduledClasses
      };

    case `${batchTypes.FETCH_SCHEDULED_CLASSES}_SUCCESS`:
      return {
        ...state,
        isFetchingSchedule: false,
        scheduledClasses: action.resetWithoutEmpty
          ? {
              [action.key]: action.result
            }
          : {
              ...state.scheduledClasses,
              [action.key]:
                state.scheduledClasses[action.key] && action.result
                  ? {
                      previous:
                        action.direction === 'down'
                          ? state.scheduledClasses[action.key].previous
                          : action.result.previous,
                      next:
                        action.direction === 'up'
                          ? state.scheduledClasses[action.key].next
                          : action.result.next,
                      results:
                        action.direction === 'down'
                          ? [
                              ...state.scheduledClasses[action.key].results,
                              ...action.result.results
                            ]
                          : [
                              ...action.result.results,
                              ...state.scheduledClasses[action.key].results
                            ]
                    }
                  : action.result
            }
      };

    case `${batchTypes.FETCH_SIMILAR_BATCHES}_SUCCESS`:
      return {
        ...state,
        similarBatches: {
          ...state.similarBatches,
          [action.batchUID]: action.result
        }
      };

    case `${batchTypes.FETCH_TRIAL_CLASSES}_SUCCESS`:
      return {
        ...state,
        trialClasses: {
          ...state.trialClasses,
          [action.key]: action.result
        }
      };

    case `${batchTypes.FETCH_FAQS}_SUCCESS`:
      return {
        ...state,
        batchFaqs: action.result
      };

    case `${batchTypes.FETCH_FEEDBACKS}_SUCCESS`:
      return {
        ...state,
        feedbacks: {
          ...state.feedbacks,
          [action.key]: action.result
        }
      };

    case `${batchTypes.ENROLL_TRIAL_CLASSES}_SUCCESS`:
      return {
        ...state
      };

    case `${batchTypes.FETCH_BATCH_SCHEDULE_WEEKS}_REQUEST`:
      return {
        ...state,
        isLoading: true
      };

    case `${batchTypes.FETCH_BATCH_SCHEDULE_WEEKS}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        recordedBatchData: camelcaseKeys(action.result, { deep: true })
      };

    case `${batchTypes.UPDATE_BATCH_SCHEDULE_WEEKS}_REQUEST`:
      return {
        ...state,
        isLoading: true
      };

    case `${batchTypes.UPDATE_BATCH_SCHEDULE_WEEKS}_SUCCESS`: {
      const response = camelcaseKeys(action.result, { deep: true });
      const selectedWeek = action.key;
      const selectedWeekItems = response?.results?.find(
        (week) => week.weekNumber === selectedWeek
      )?.items;

      const updatedWeeklyDetails = state.recordedBatchData.results?.map(
        (week) => {
          if (week.weekNumber === selectedWeek) {
            return { ...week, items: selectedWeekItems, isLoaded: true };
          }
          return week;
        }
      );

      return {
        ...state,
        isLoading: false,
        recordedBatchData: {
          ...state.recordedBatchData,
          results: updatedWeeklyDetails
        }
      };
    }

    case `${batchTypes.UPDATE_BATCH_LESSON_SAVED}`: {
      const updatedResults = state.recordedBatchData.results?.map((week) => {
        if (week.weekNumber === action.selectedWeek) {
          return {
            ...week,
            items: week.items.map((lesson) =>
              lesson.uid === action.selectedLesson
                ? { ...lesson, isSaved: action.isSaved }
                : lesson
            )
          };
        }
        return week;
      });

      return {
        ...state,
        results: updatedResults
      };
    }

    default:
      return state;
  }
};

export default reducer;
