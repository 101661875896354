import { helpCenterTypes } from '../constants';

const initialState = {
  categories: {},
  article: {},
  me: {}
};

const reducerMap = {
  [`${helpCenterTypes.FETCH_CATEGORIES}_SUCCESS`](state, { result }) {
    return {
      ...state,
      categories: {
        ...result,
        isLoaded: true
      }
    };
  },
  [`${helpCenterTypes.FETCH_ARTICLE_INFO}_SUCCESS`](state, { result }) {
    return {
      ...state,
      article: result || {}
    };
  },
  [`${helpCenterTypes.FETCH_ME_HELP_CENTER}_SUCCESS`](state, { result }) {
    return {
      ...state,
      me: {
        ...result,
        isLoaded: true
      }
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
