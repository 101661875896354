import { apiStatus } from '@constants/index';

export const createReducer =
  (reducerMap, initialState = {}) =>
  (state = initialState, action) => {
    const actionHandler = reducerMap[action.type];
    return actionHandler
      ? {
          ...state,
          ...actionHandler(action, state)
        }
      : state;
  };

const noOp = () => ({});
export const getApiStatusHandlers = ({
  actionConstant,
  onRequest = noOp,
  onSuccess = noOp,
  onError = noOp
}) => ({
  [`${actionConstant}_${apiStatus.REQUEST}`]: (action, state) => {
    const newState = {
      apiStatus: {
        ...state.apiStatus,
        [actionConstant]: apiStatus.REQUEST
      }
    };
    return {
      ...newState,
      ...onRequest(action, newState)
    };
  },
  [`${actionConstant}_${apiStatus.SUCCESS}`]: (action, state) => {
    const newState = {
      apiStatus: {
        ...state.apiStatus,
        [actionConstant]: apiStatus.SUCCESS
      }
    };
    return {
      ...newState,
      ...onSuccess(action, newState)
    };
  },
  [`${actionConstant}_${apiStatus.FAILURE}`]: (action, state) => {
    const newState = {
      apiStatus: {
        ...state.apiStatus,
        [actionConstant]: apiStatus.FAILURE
      }
    };
    return {
      ...newState,
      ...onError(action, newState)
    };
  }
});
