import { courseTypes, subscriptionTypes, savedTypes } from '../constants';

const initialState = {
  filters: [],
  educatorFilters: [],
  selectedFilter: { label: 'All updates', value: 'all' },
  selectedEducatorFilter: { label: 'All educators', value: 'all' }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${courseTypes.UPDATE_FILTER}`: {
      if (action.reset) {
        return {
          ...state,
          selectedFilter: { label: 'All updates', value: 'all' },
          selectedEducatorFilter: { label: 'All educators', value: 'all' }
        };
      }

      if (action.kind === 'other') {
        return { ...state, selectedFilter: action.filter };
      }

      if (action.kind === 'educator') {
        return { ...state, selectedEducatorFilter: action.filter };
      }

      return state;
    }

    case `${courseTypes.FETCH_FILTERS}_SUCCESS`: {
      const filters = action.result
        .filter((filter) => filter.filter_type !== 'Educator')
        .map((filter) => ({
          label: filter.filter_type,
          value: filter.filter_value
        }));
      const educatorFilter = action.result.find(
        (filter) => filter.filter_type === 'Educator'
      );
      const educatorFilters = educatorFilter?.filter_value.map((educator) => ({
        label: `${educator.first_name} ${educator.last_name}`,
        value: educator.username
      }));
      educatorFilters.unshift({ label: 'All educators', value: 'all' });
      return { ...state, filters, educatorFilters };
    }

    default:
      return state;
  }
};

export default reducer;

export const updateCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case `${courseTypes.TOGGLE_LIKE}_SUCCESS`: {
      const value = action.isLiked ? -1 : 1;
      const itemIndex = state.results.findIndex(
        (result) => result.uid === action.id
      );
      const updatedResults = [...state.results];
      updatedResults[itemIndex].is_liked = !updatedResults[itemIndex]?.is_liked;
      updatedResults[itemIndex].reactions_count += value;
      return { ...state, results: [...updatedResults] };
    }

    case courseTypes.INCREASE_COMMENT: {
      const itemIndex = state.results.findIndex(
        (result) => result.uid === action.id
      );
      const updatedResults = [...state.results];
      updatedResults[itemIndex].comments_count += 1;

      return { ...state, results: [...updatedResults] };
    }

    default:
      return state;
  }
};

export const updateCommentReducer = (state = {}, action) => {
  switch (action.type) {
    case `${courseTypes.ADD_COMMENT}_SUCCESS`: {
      const updatedResults = [action.result, ...state.results];
      return { ...state, results: [...updatedResults] };
    }

    case `${courseTypes.ADD_REPLY}_SUCCESS`: {
      const itemIndex = state.results.findIndex(
        (result) => result.id === action.id
      );
      const updatedResults = [...state.results];
      updatedResults[itemIndex].comments.count += 1;

      updatedResults[itemIndex].comments.results = [
        ...updatedResults[itemIndex].comments.results,
        action.result
      ];
      return { ...state, results: [...updatedResults] };
    }

    case `${courseTypes.TOGGLE_COMMENT_LIKE}_SUCCESS`: {
      const value = action.isLiked ? -1 : 1;
      const itemIndex = state.results.findIndex(
        (result) => result.id === action.id
      );
      const updatedResults = [...state.results];
      updatedResults[itemIndex].upvoted = !updatedResults[itemIndex].upvoted;
      updatedResults[itemIndex].total_upvotes += value;
      return { ...state, results: [...updatedResults] };
    }

    default:
      return state;
  }
};

export const updatePlusHomeFeedReducer = (state = {}, action) => {
  switch (action.type) {
    case `${subscriptionTypes.ACTIVATE_EXTERNAL_APP}_SUCCESS`: {
      const results = state.results.filter((result) => {
        return !result.feedrow.match(/external_subscription_preactivation/gi);
      });
      return { ...state, results };
    }
    default:
      return state;
  }
};

export const updateSavedReducer = (state = {}, action) => {
  switch (action.type) {
    case `${savedTypes.SAVE_ENTITY}_SUCCESS`: {
      return null;
    }
    case `${savedTypes.REMOVE_SAVED_ENTITY}_SUCCESS`: {
      const objectProp = action.entity === 3 ? 'question' : 'item';
      const results = state.results?.filter((object) => {
        return object[objectProp] !== action.objectUid;
      });
      return { ...state, results };
    }
    default:
      return state;
  }
};
