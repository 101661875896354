import { classroomTypes } from '../constants';

const initialState = {
  classes: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${classroomTypes.GET_CLASS}_SUCCESS`:
      return {
        ...state,
        classes: action.result
      };
    default:
      return state;
  }
};

export default reducer;
