import { combineReducers } from 'redux';
import courses from './courses';
import careers from './careers';
import users from './users';
import paginator from './paginator';
import items from './items';
import feedrows from './feedrows';
import auth from './auth';
import topologies from './topologies';
import notifications from './notifications';
import quizzes from './quizzes';
import questions from './questions';
import checkout from './checkout';
import others from './others';
import sales from './sales';
import player from './player';
import batches from './batches';
import classroom from './classroom';
import educator from './educator';
import educatorOnboarding from './educatorOnboarding';
import createQuestion from './createQuestion';
import dashboard from './dashboard';
import summit from './summit';
import updates from './updateReducers';
import doubts from './doubts';
import doubtSolutions from './doubtSolutions';
import helpCenter from './helpCenter';
import templates from './templates';
import combats from './combats';
import drive from './drive';
import practiceSession from './practiceSession';
import educatorLiveMentoring from './educatorLiveMentoring';
import question from './question';
import learnerDoubtsAndSolutions from './learnerDoubtsAndSolutions';
import freePracticeSession from './freePracticeSession';
import createQuiz from './createQuiz';
import evaluateQuiz from './evaluateQuiz';
import quizTaking from './quizTaking';
import combat from './combat';
import combatTaking from './combatTaking';
import iconicOnboarding from './iconicOnboarding';
import oneToOneLiveMentorShip from './oneToOneLiveMentorShip';
import doubtsOnWeb2 from './doubtsOnWeb2';
import batchGroups from './batchGroups';
import rankPredictor from './rankPredictor';
import store from './store';

export default combineReducers({
  courses,
  careers,
  combats,
  users,
  paginator,
  topologies,
  items,
  auth,
  feedrows,
  notifications,
  quizzes,
  questions,
  checkout,
  others,
  sales,
  player,
  batches,
  classroom,
  educator,
  educatorOnboarding,
  educatorLiveMentoring,
  createQuestion,
  dashboard,
  updates,
  doubts,
  doubtSolutions,
  learnerDoubtsAndSolutions,
  summit,
  helpCenter,
  templates,
  drive,
  practiceSession,
  freePracticeSession,
  question,
  createQuiz,
  evaluateQuiz,
  quizTaking,
  combat,
  iconicOnboarding,
  oneToOneLiveMentorShip,
  combatTaking,
  doubtsOnWeb2,
  batchGroups,
  rankPredictor,
  store
});
