import {
  educatorLiveMentoringTypes,
  combatTypes
} from '@actions-constants/index';
import { apiStatus } from '@constants/index';
import mergeDeep from '@utils/mergeDeep';

const initialState = {
  data: {},
  apiStatus: {},
  slotPreferences: {},
  sessionPreferences: {},
  sessionDetails: {},
  feedbackSummary: {}
};

const noOp = () => ({});

const getApiStatusHandlers = (
  actionConstant,
  successHandler = noOp,
  requestHandler = noOp,
  errorHandler = noOp
) => ({
  [`${actionConstant}_${apiStatus.REQUEST}`]: (action, state) => {
    const newState = {
      apiStatus: {
        ...state.apiStatus,
        [actionConstant]: apiStatus.REQUEST
      }
    };
    return {
      ...newState,
      ...requestHandler(action, newState)
    };
  },
  [`${actionConstant}_${apiStatus.SUCCESS}`]: (action, state) => {
    const newState = {
      apiStatus: {
        ...state.apiStatus,
        [actionConstant]: apiStatus.SUCCESS
      }
    };
    return {
      ...newState,
      ...successHandler(action, newState)
    };
  },
  [`${actionConstant}_${apiStatus.FAILURE}`]: (action, state) => {
    const newState = {
      apiStatus: {
        ...state.apiStatus,
        [actionConstant]: apiStatus.FAILURE
      }
    };
    return {
      ...newState,
      ...errorHandler(action, newState)
    };
  }
});

const reducerMap = {
  [combatTypes.UPDATE_COMBAT]: (action, state) => {
    return {
      ...state,
      data: mergeDeep(action.data, state.data, 2)
    };
  },
  ...getApiStatusHandlers(
    educatorLiveMentoringTypes.SLOT_PREFERENCES,
    ({ result, saveResponse }) =>
      saveResponse
        ? {
            slotPreferences: result || {}
          }
        : {}
  ),
  ...getApiStatusHandlers(
    educatorLiveMentoringTypes.FETCH_PREFERENCES,
    ({ result }) => ({
      sessionPreferences: result || {}
    })
  )
};

const combatsReducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];

  return actionHandler
    ? {
        ...state,
        ...actionHandler(action, state)
      }
    : state;
};

export default combatsReducer;
