import isEmptyUtil from '@utils/isEmpty';
import { NO_PREFRENCE } from '@constants/quiz';
import combatTakingTypes from '../constants/combatTaking';

const initialState = {
  isPendingAction: false,
  data: {},
  additionalData: {},
  currentSection: {},
  nextSection: {},
  selectedLanguage: NO_PREFRENCE,
  availableLanguages: [NO_PREFRENCE],
  rankInfoByUID: {},
  currentQuestionSubmitted: false,
  currentQuestion: {},
  currentSelectedAnswer: [],
  currentAnswersStats: {
    user: {},
    global: {}
  },
  totalLearnersByUID: {},
  badgesByUID: {},
  ratingByUID: {},
  leaderboardByUID: {},
  summaryByUID: {},
  feedbackOptionsByUID: {},
  solutionByUID: {},
  modal: {
    type: null,
    data: {}
  }
};

const modifyPendingState = (value) => (state) => ({
  ...state,
  isPendingAction: value
});

const reducerMap = {
  [`${combatTakingTypes.COMBAT_FETCH_INFO}_SUCCESS`](state, action) {
    return {
      ...state,
      data: {
        ...state.data,
        [action.combatUID]: action.result
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_STATUS}_SUCCESS`](state, action) {
    const {
      availableLanguages,
      currentSection,
      nextSection,
      numberOfLearnersJoined,
      ...rest
    } = action.result;

    return {
      ...state,
      currentSection,
      nextSection,
      availableLanguages,
      totalLearnersByUID: {
        ...state.totalLearnersByUID,
        [action.combatUID]: numberOfLearnersJoined
      },
      additionalData: {
        numberOfLearnersJoined,
        ...rest
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_CURRENT_QUESTION}_REQUEST`](
    state,
    action
  ) {
    return {
      ...state,
      currentQuestion: {},
      currentQuestionSubmitted: false,
      currentSelectedAnswer: [],
      leaderboardByUID: {
        ...state.leaderboardByUID,
        [action.combatUID]: []
      },
      currentAnswersStats: {
        user: {},
        global: {}
      },
      rankInfoByUID: {
        ...state.rankInfoByUID,
        [action.combatUID]: {}
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_CURRENT_QUESTION}_SUCCESS`](
    state,
    action
  ) {
    const { currentQuestion, currentSection, nextSection } = action.result;

    return {
      ...state,
      currentQuestion,
      currentSection,
      nextSection
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_USER_RANK}_SUCCESS`](state, action) {
    return {
      ...state,
      rankInfoByUID: {
        ...state.rankInfoByUID,
        [action.combatUID]: action.result
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_USER_QUESTION_STATS}_SUCCESS`](
    state,
    action
  ) {
    const { attempted, answer } = action.result;
    const value = Array.isArray(answer) ? answer : [answer];

    return {
      ...state,
      currentQuestionSubmitted: attempted,
      currentSelectedAnswer: attempted ? value : []
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_USER_ANSWER_STATS}_SUCCESS`](
    state,
    action
  ) {
    const { rankInfo, ...rest } = action.result;

    return {
      ...state,
      currentAnswersStats: {
        ...state.currentAnswersStats,
        user: rest
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_GLOBAL_ANSWER_STATS}_SUCCESS`](
    state,
    action
  ) {
    const { currentSection, nextSection, ...rest } = action.result;

    return {
      ...state,
      currentSection,
      nextSection,
      currentAnswersStats: {
        ...state.currentAnswersStats,
        global: rest
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_LEADERBOARD}_SUCCESS`](state, action) {
    const { leaderboard, currentSection, nextSection } = action.result;

    return {
      ...state,
      leaderboardByUID: {
        ...state.leaderboardByUID,
        [action.combatUID]: leaderboard
      },
      currentSection,
      nextSection
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_BATCHES_AND_RATING}_SUCCESS`](
    state,
    action
  ) {
    const { badges, rating } = action.result;

    return {
      ...state,
      badgesByUID: {
        [action.combatUID]: badges
      },
      ratingByUID: {
        [action.combatUID]: rating
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_SUMMARY}_SUCCESS`](state, action) {
    return {
      ...state,
      summaryByUID: {
        ...state.summaryByUID,
        [action.combatUID]: action.result
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_FULL_SUMMARY}_SUCCESS`](state, action) {
    const {
      badges,
      leaderboard,
      sectional_data,
      total_learners,
      exam_preference_uid,
      user_summary = {}
    } = action.result;
    const { rank, score, ...rest } = user_summary;

    return {
      ...state,
      examPreferenceUid: exam_preference_uid,
      totalLearnersByUID: {
        ...state.totalLearnersByUID,
        [action.combatUID]: total_learners
      },
      badgesByUID: {
        ...state.badgesByUID,
        [action.combatUID]: badges
      },
      leaderboardByUID: {
        ...state.leaderboardByUID,
        [action.combatUID]: leaderboard
      },
      rankInfoByUID: {
        ...state.rankInfoByUID,
        [action.combatUID]: {
          rank,
          score
        }
      },
      summaryByUID: {
        ...state.summary,
        [action.combatUID]: {
          ...rest,
          roundSummary: sectional_data
        }
      }
    };
  },
  [`${combatTakingTypes.COMBAT_SUBMIT_ANSWER}_REQUEST`]:
    modifyPendingState(true),
  [`${combatTakingTypes.COMBAT_SUBMIT_ANSWER}_FAILURE`]:
    modifyPendingState(false),
  [`${combatTakingTypes.COMBAT_SUBMIT_ANSWER}_SUCCESS`](state) {
    return {
      ...state,
      isPendingAction: false,
      currentQuestionSubmitted: true
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_FEEDBACK_OPTIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      feedbackOptionsByUID: {
        ...state.feedbackOptionsByUID,
        [action.combatUID]: action.result
      }
    };
  },
  [`${combatTakingTypes.COMBAT_FETCH_SOLUTION}_SUCCESS`](state, action) {
    return {
      ...state,
      solutionByUID: {
        ...state.solutionByUID,
        [action.combatUID]: action.result
      }
    };
  },
  [`${combatTakingTypes.COMBAT_SUBMIT_FEEDBACK}_SUCCESS`](state, action) {
    return {
      ...state,
      feedbackOptionsByUID: {
        ...state.feedbackOptionsByUID,
        [action.combatUID]: {
          ...state.feedbackOptionsByUID[action.combatUID],
          last_submitted_at: new Date()
        }
      }
    };
  },
  [`${combatTakingTypes.COMBAT_MODAL_TYPE}`](state, action) {
    const {
      data: { type, data }
    } = action;

    return {
      ...state,
      modal: {
        type,
        data
      }
    };
  },
  [`${combatTakingTypes.COMBAT_SET_LANGUAGE}`](state, action) {
    const {
      data: { selectedLanguage }
    } = action;

    const languageObject = state.availableLanguages.find(
      ({ code }) => code === selectedLanguage
    );

    return {
      ...state,
      selectedLanguage: isEmptyUtil(languageObject)
        ? state.availableLanguages[0]
        : languageObject
    };
  },
  [`${combatTakingTypes.COMBAT_SET_ANSWERS}`](state, action) {
    return {
      ...state,
      currentSelectedAnswer: action.data
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
