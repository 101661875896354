/* eslint-disable max-lines */
import { NO_PREFRENCE } from '@constants/quiz';
import {
  setupInitialQuestionsData,
  preProcessFetchedQuestions,
  preProcessQuestionsAdditionalData,
  getCurrentQuestionInfo,
  preProcessFetchedSolutionFilters,
  hasCalculator,
  getQuizType,
  setSubmittedQuestionResponse,
  removeProperty,
  setUnansweredQuestionList,
  setUnansweredQuestionListOnMarktoggle
} from '@cont/Quiz/utils';
import { emptyObject } from '@constants/empty';
import { savedTypes } from '../constants';
import quizTakingTypes from '../constants/quizTaking';

const initialState = {
  // used to denote pending api/action
  isPendingAction: false,
  isQuizLoading: true,
  data: {},
  currentQuizType: {
    isSubjective: false,
    isObjective: true,
    isContent: false,
    isDummy: true
  },
  currentSession: {},
  ongoingSectionDetails: {},
  selectedLanguage: NO_PREFRENCE,
  availableLanguages: [NO_PREFRENCE],
  questionReportOptions: {},
  answersBySection: {},
  questionsBySection: {},
  savedQuestionsBySection: {},
  markedQuestionsBySection: {},
  calculatorTypesBySection: {},
  minRankAPIDelay: null,
  maxAnswerableQuestionBySection: {},
  sessionDetailsByUID: {},
  currentQuestionInfo: {
    sectionUID: null,
    questionUID: null
  },
  currentSelectedAnswer: [],
  modal: {
    type: null,
    data: {}
  },
  solutionSavedQuestions: {},
  solutionFeedbackOptions: {},
  videoSolutionFeedbackOptions: {},
  solutionFilterBySession: {},
  analyticsReportByUID: {},
  rankByQuiz: {},
  evaluatedSheetsBySesssion: {},
  feedbackOptionsByUID: {}
};

const modifyPendingState = (value) => (state) => ({
  ...state,
  isPendingAction: value
});

const reducerMap = {
  [`${quizTakingTypes.QUIZ_FETCH_DETAILS}_REQUEST`]: modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_FETCH_DETAILS}_SUCCESS`](state, action) {
    const calculatorTypesBySection = {};
    const maxAnswerableQuestionBySection = {};
    const { sections, quiz_type } = action.result;

    const hasDefaultSection = sections.length === 1 && sections[0].is_default;

    if (hasDefaultSection) {
      const calculatorType = sections[0].calculator_type;

      if (hasCalculator(calculatorType)) {
        calculatorTypesBySection[sections[0].uid] = calculatorType;
      }

      maxAnswerableQuestionBySection[sections[0].uid] =
        sections[0]?.max_answerable_question || sections[0].questions_count;
    } else {
      action.result.sections.forEach((section) => {
        const calculatorType = section.calculator_type;

        if (hasCalculator(calculatorType)) {
          calculatorTypesBySection[section.uid] = calculatorType;
        }

        maxAnswerableQuestionBySection[section.uid] =
          section?.max_answerable_question || section.questions_count;
      });
    }

    const { isSubjective, isObjective, isContent, isDummy } =
      getQuizType(quiz_type);

    return {
      ...state,
      isPendingAction: false,
      calculatorTypesBySection,
      maxAnswerableQuestionBySection,
      currentQuizType: {
        isSubjective,
        isObjective,
        isContent,
        isDummy
      },
      minRankAPIDelay: action.result.min_rank_api_delay ?? 2,
      data: {
        ...state.data,
        [action.quizUID]: {
          ...state.data[action.quizUID],
          ...action.result
        }
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_DETAILS}_FAILURE`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_FETCH_ATTEMPTS}_REQUEST`]: modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_FETCH_ATTEMPTS}_SUCCESS`](state, action) {
    return {
      ...state,
      isPendingAction: false,
      data: {
        ...state.data,
        [action.quizUID]: {
          ...state.data[action.quizUID],
          ...action.result
        }
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_ATTEMPTS}_FAILURE`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_FETCH_QUESTION_REPORT_OPTIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      questionReportOptions: action.result
    };
  },
  [`${quizTakingTypes.QUIZ_ENROLL}_REQUEST`]: modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_ENROLL}_SUCCESS`](state, action) {
    const {
      available_language,
      language,
      ongoing_section_details,
      question_with_user_choices,
      ...rest
    } = action.result;

    const data = {
      ...state,
      isPendingAction: false,
      currentSession: rest,
      ongoingSectionDetails: ongoing_section_details,
      availableLanguages: available_language,
      selectedLanguage: available_language.find(({ code }) => code === language)
    };

    if (question_with_user_choices) {
      const { sections } = state.data[action.quizUID];

      const questionsBySection = preProcessFetchedQuestions(
        sections,
        question_with_user_choices
      );

      const {
        answersBySection,
        markedQuestionsBySection,
        savedQuestionsBySection
      } = setupInitialQuestionsData(sections);

      let currentQuestionInfo = { sectionUID: null, questionUID: null };

      if (action?.isRevampedTestVersion2) {
        currentQuestionInfo = getCurrentQuestionInfo(
          sections,
          null,
          questionsBySection
        );
      }
      return {
        ...data,
        answersBySection,
        markedQuestionsBySection,
        savedQuestionsBySection,
        questionsBySection,
        currentQuestionInfo
      };
    }

    return data;
  },
  [`${quizTakingTypes.QUIZ_ENROLL}_FAILURE`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_FETCH_STATIC_QUESTIONS}_REQUEST`]:
    modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_FETCH_STATIC_QUESTIONS}_SUCCESS`](state, action) {
    const questions = action.result;
    const { sections } = state.data[action.quizUID];

    const questionsBySection = preProcessFetchedQuestions(sections, questions);

    const {
      answersBySection,
      markedQuestionsBySection,
      savedQuestionsBySection
    } = setupInitialQuestionsData(sections);

    const { sectionUID, questionUID } = getCurrentQuestionInfo(
      sections,
      null,
      questionsBySection
    );

    return {
      ...state,
      answersBySection,
      markedQuestionsBySection,
      savedQuestionsBySection,
      questionsBySection,
      isPendingAction: false,
      currentSelectedAnswer: [],
      currentQuestionInfo: {
        sectionUID,
        questionUID
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_STATIC_QUESTIONS}_FAILURE`]:
    modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_FETCH_QUESTIONS_WITH_ATTEMPTS}_SUCCESS`](
    state,
    action
  ) {
    const {
      available_language,
      language,
      question_with_user_choices,
      ongoing_section_details,
      latest_viewed_question,
      ...rest
    } = action.result;
    const { sections } = state.data[action.quizUID];

    const questionsBySection = preProcessFetchedQuestions(
      sections,
      question_with_user_choices
    );

    const {
      answersBySection,
      markedQuestionsBySection,
      savedQuestionsBySection
    } = preProcessQuestionsAdditionalData(
      sections,
      question_with_user_choices,
      true
    );

    const { sectionUID, questionUID } = getCurrentQuestionInfo(
      sections,
      latest_viewed_question,
      questionsBySection
    );

    return {
      ...state,
      answersBySection,
      markedQuestionsBySection,
      savedQuestionsBySection,
      questionsBySection,
      isPendingAction: false,
      currentSession: rest,
      currentSelectedAnswer: [],
      currentQuestionInfo: {
        sectionUID,
        questionUID
      },
      ongoingSectionDetails: ongoing_section_details,
      availableLanguages: available_language,
      selectedLanguage: available_language.find(({ code }) => code === language)
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_SUBMITTED_QUESTIONS}_SUCCESS`](state, action) {
    const {
      available_language,
      language,
      question_with_user_choices,
      ongoing_section_details,
      latest_viewed_question,
      ...rest
    } = action.result;
    const { sections } = state.data[action.quizUID];
    const { questionsBySection } = state;

    const {
      answersBySection,
      markedQuestionsBySection,
      savedQuestionsBySection,
      unansweredQuestionsBySection
    } = preProcessQuestionsAdditionalData(
      sections,
      question_with_user_choices,
      action.isSubjective
    );

    const { sectionUID, questionUID } = getCurrentQuestionInfo(
      sections,
      latest_viewed_question,
      questionsBySection
    );

    return {
      ...state,
      answersBySection,
      markedQuestionsBySection,
      unansweredQuestionsBySection,
      savedQuestionsBySection,
      isPendingAction: false,
      currentSession: rest,
      currentQuestionInfo: {
        sectionUID,
        questionUID
      },
      ongoingSectionDetails: ongoing_section_details,
      availableLanguages: available_language,
      selectedLanguage: available_language.find(({ code }) => code === language)
    };
  },

  [`${quizTakingTypes.QUIZ_SEND_VIEWED_QUESTION}_SUCCESS`](state, action) {
    const { sectionUID, questionUID, isRevampedTestVersion2 } =
      action?.questionData;

    const isQuestionMarked =
      !!state.markedQuestionsBySection?.[sectionUID]?.[questionUID];
    const isQuestionAnswered =
      !!state.answersBySection?.[sectionUID]?.[questionUID];
    const isAlreadyInUnansweredList =
      state.unansweredQuestionsBySection?.[sectionUID]?.[questionUID];

    if (
      !isRevampedTestVersion2 ||
      isAlreadyInUnansweredList ||
      isQuestionMarked ||
      isQuestionAnswered
    ) {
      return;
    }

    return {
      ...state,
      unansweredQuestionsBySection: {
        ...state.unansweredQuestionsBySection,
        [sectionUID]: {
          ...state.unansweredQuestionsBySection?.[sectionUID],
          [questionUID]: true
        }
      }
    };
  },

  [`${quizTakingTypes.QUIZ_MODAL_TYPE}`](state, action) {
    const {
      data: { type, data }
    } = action;

    return {
      ...state,
      modal: {
        type,
        data
      }
    };
  },
  [`${quizTakingTypes.QUIZ_SET_CURRENT_QUESTION_TYPE}`](state, action) {
    return {
      ...state,
      currentQuestionInfo: action.data,
      currentSelectedAnswer: []
    };
  },
  [`${quizTakingTypes.QUIZ_SET_CURRENT_SELECTED_ANSWER}`](state, action) {
    return {
      ...state,
      currentSelectedAnswer: action.data
    };
  },
  [`${quizTakingTypes.QUIZ_MARK_QUESTION}_REQUEST`]: modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_MARK_QUESTION}_SUCCESS`](state, action) {
    const isQuestionAnswered =
      state.answersBySection[action.sectionUID]?.[action.questionUID];

    return {
      ...state,
      isPendingAction: false,
      markedQuestionsBySection: {
        ...state.markedQuestionsBySection,
        [action.sectionUID]: {
          ...(action.isMarked
            ? {
                ...state.markedQuestionsBySection[action.sectionUID],
                [action.questionUID]: action.isMarked
              }
            : removeProperty(
                state.markedQuestionsBySection[action.sectionUID],
                action.questionUID
              ))
        }
      },
      unansweredQuestionsBySection: {
        ...state.unansweredQuestionsBySection,
        [action.sectionUID]: setUnansweredQuestionListOnMarktoggle({
          unansweredQuestionsBySection:
            state.unansweredQuestionsBySection?.[action.sectionUID],
          questionUID: action.questionUID,
          isMarked: action.isMarked,
          isAnswered: isQuestionAnswered
        })
      }
    };
  },
  [`${quizTakingTypes.QUIZ_MARK_QUESTION}_FAILURE`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_SUBMIT_QUESTION_RESPONSE}_REQUEST`](state, action) {
    const { sectionUID, questionUID, attemptList, isSubjective } = action;
    const isMarked = state.markedQuestionsBySection[sectionUID]?.[questionUID];
    return {
      ...state,
      answersBySection: {
        ...state.answersBySection,
        [sectionUID]: setSubmittedQuestionResponse({
          answersBySection: state.answersBySection?.[sectionUID],
          questionUID,
          attemptList,
          isSubjective
        })
      },
      unansweredQuestionsBySection: {
        ...state.unansweredQuestionsBySection,
        [sectionUID]: setUnansweredQuestionList({
          unansweredQuestionsBySection:
            state.unansweredQuestionsBySection?.[sectionUID],
          questionUID,
          attemptList,
          isSubjective,
          isMarked
        })
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FINISH_SECTION}_REQUEST`]: modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_FINISH_SECTION}_SUCCESS`](state, action) {
    return {
      ...state,
      isPendingAction: false,
      currentSelectedAnswer: [],
      currentQuestionInfo: {
        sectionUID: action.result.section.uid,
        questionUID:
          state.questionsBySection[action.result.section.uid].allIds[0]
      },
      ongoingSectionDetails: action.result
    };
  },
  [`${quizTakingTypes.QUIZ_FINISH_SECTION}_FAILURE`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_CLEAN_UP_AFTER_QUIZ_END}`](state) {
    return {
      ...initialState,
      data: state.data,
      sessionDetailsByUID: state.sessionDetailsByUID,
      solutionSavedQuestions: state.solutionSavedQuestions,
      solutionFeedbackOptions: state.solutionFeedbackOptions,
      videoSolutionFeedbackOptions: state.videoSolutionFeedbackOptions,
      solutionFilterBySession: state.solutionFilterBySession,
      analyticsReportByUID: state.analyticsReportByUID,
      rankByQuiz: state.rankByQuiz,
      evaluatedSheetsBySesssion: state.evaluatedSheetsBySesssion,
      feedbackOptionsByUID: state.feedbackOptionsByUID,
      maxAnswerableQuestionBySection: state.maxAnswerableQuestionBySection,
      calculatorTypesBySection: state.calculatorTypesBySection,
      currentQuizType: state.currentQuizType
    };
  },
  [`${quizTakingTypes.QUIZ_FINISH_QUIZ}_REQUEST`]: modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_FINISH_QUIZ}_SUCCESS`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_FINISH_QUIZ}_FAILURE`]: modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_FETCH_SESSION_DETAILS}_SUCCESS`](state, action) {
    return {
      ...state,
      sessionDetailsByUID: {
        ...state.sessionDetailsByUID,
        [action.sessionUID]: action.result
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_SOLUTION_FILTER}_SUCCESS`](state, action) {
    const { solutionFirstLevelFilters, solutionSecondLevelFiltersByUID } =
      preProcessFetchedSolutionFilters(action.result);
    return {
      ...state,
      solutionFilterBySession: {
        ...state.solutionFilterBySession,
        [action.sessionUID]: {
          solutionFirstLevelFilters,
          solutionSecondLevelFiltersByUID
        }
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_SOLUTION_FEEDBACK_OPTIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      solutionFeedbackOptions: action.result
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_VIDEO_SOLUTION_FEEDBACK_OPTIONS}_SUCCESS`](
    state,
    action
  ) {
    return {
      ...state,
      videoSolutionFeedbackOptions: action.result
    };
  },
  [`${savedTypes.SAVE_ENTITY}_SUCCESS`](state, action) {
    const { objectUid } = action;
    if (!objectUid) return state;

    return {
      ...state,
      solutionSavedQuestions: {
        ...state.solutionSavedQuestions,
        [objectUid]: true
      }
    };
  },
  [`${savedTypes.REMOVE_SAVED_ENTITY}_SUCCESS`](state, action) {
    const { objectUid } = action;
    if (!objectUid) return state;

    return {
      ...state,
      solutionSavedQuestions: {
        ...state.solutionSavedQuestions,
        [objectUid]: false
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_ANALYTICS_REPORT}_SUCCESS`](state, action) {
    return {
      ...state,
      analyticsReportByUID: {
        ...state.analyticsReportByUID,
        [action.sessionUID]: {
          ...(state.analyticsReportByUID?.[action.sessionUID] || emptyObject),
          ...action.result
        }
      }
    };
  },
  [`${quizTakingTypes.QUIZ_LOADING}`](state, action) {
    return {
      ...state,
      isQuizLoading: action.value
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_COMMON_REPORT}_SUCCESS`](state, action) {
    return {
      ...state,
      analyticsReportByUID: {
        ...state.analyticsReportByUID,
        [action.sessionUID]: {
          ...(state.analyticsReportByUID?.[action.sessionUID] || emptyObject),
          ...action.result
        }
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_USER_RANK}_SUCCESS`](state, action) {
    return {
      ...state,
      rankByQuiz: {
        ...state.rankByQuiz,
        [action.quizUID]: {
          userRank: action.result.rank ?? null,
          totalSessionCount: action.result.sessions_count ?? null
        }
      },
      ...(action.isWhitelisted && {
        analyticsReportByUID: {
          ...state.analyticsReportByUID,
          [action.sessionUID]: {
            ...state.analyticsReportByUID[action.sessionUID],
            bell_curve_data: action.result.bell_curve_data,
            session_percentile: action.result.session_percentile
          }
        }
      })
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_EVALUATED_SHEET}_SUCCESS`](state, action) {
    return {
      ...state,
      evaluatedSheetsBySesssion: {
        ...state.evaluatedSheetsBySesssion,
        [action.sessionUID]: action.result
      }
    };
  },
  [`${quizTakingTypes.QUIZ_FETCH_FEEDBACK_OPTIONS}_SUCCESS`](state, action) {
    return {
      ...state,
      feedbackOptionsByUID: {
        ...state.feedbackOptionsByUID,
        [action.quizUID]: action.result
      }
    };
  },
  [`${quizTakingTypes.QUIZ_SET_SELECTED_LANGUAGE}`](state, action) {
    return {
      ...state,
      selectedLanguage: action.data
    };
  },
  [`${quizTakingTypes.QUIZ_REJECT_SUBJECTIVE_ANSWER_UPLOAD}_REQUEST`]:
    modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_REJECT_SUBJECTIVE_ANSWER_UPLOAD}_SUCCESS`]:
    modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_REJECT_SUBJECTIVE_ANSWER_UPLOAD}_FAILURE`]:
    modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_UPLOAD_SUBJECTIVE_ANSWER}_REQUEST`]:
    modifyPendingState(true),
  [`${quizTakingTypes.QUIZ_UPLOAD_SUBJECTIVE_ANSWER}_SUCCESS`]:
    modifyPendingState(false),
  [`${quizTakingTypes.QUIZ_UPLOAD_SUBJECTIVE_ANSWER}_FAILURE`]:
    modifyPendingState(false)
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
