import { educatorTypes } from '../constants';

const initialState = {
  stats: { loading: false },
  monthlyStats: { loading: true },
  hats_achieved: [],
  dedications: [],
  nlMetaData: {},
  feedbackOptionsForEdu: null,
  isSubmittingFeedback: false
};

export default function dashboardState(state = initialState, action) {
  switch (action.type) {
    case `${educatorTypes.FETCH_EDUCATOR_DEDICATIONS}_SUCCESS`:
      return {
        ...state,
        ...action.result
      };
    case `${educatorTypes.FETCH_EDUCATOR_LIVE_HOURS}_REQUEST`:
      return { ...state, monthlyStats: { loading: true } };
    case `${educatorTypes.FETCH_EDUCATOR_LIVE_HOURS}_SUCCESS`:
      return {
        ...state,
        monthlyStats: {
          ...state.monthlyStats,
          ...action.result,
          loading: false
        }
      };
    case `${educatorTypes.FETCH_EDUCATOR_ALERTS}_SUCCESS`:
      return { ...state, alerts: action.result };
    case `${educatorTypes.FETCH_NSCLL_DATA}_SUCCESS`:
      return {
        ...state,
        NSCLLCount: action.result.total_incentive.total_activated_learners,
        nlMetaData: action.result.meta_data
      };
    case `${educatorTypes.FETCH_EDUCATOR_REFERRALS}_SUCCESS`:
      return { ...state, referrals: { ...action.result } };
    case `${educatorTypes.FETCH_EDUCATOR_STATISTICS}_REQUEST`:
      return { ...state, stats: { ...state.stats, loading: true } };
    case `${educatorTypes.FETCH_EDUCATOR_STATISTICS}_SUCCESS`:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          [action.timePeriod]: { ...action.result }
        }
      };
    case `${educatorTypes.GET_FEEDBACK_FOR_EDUCATOR_DASHBOARD}_SUCCESS`:
      return { ...state, feedbackOptionsForEdu: action.result };
    default:
      return state;
  }
}
