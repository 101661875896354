/* eslint-disable max-lines */
import { goalLevelOnboardingStatus } from '@constants/index';
import {
  PLATFORM_SUBSCRIPTION,
  PLUS_SUBSCRIPTION
} from '@cont/Planner/planner-constants';
import { authTypes, combatTypes, topologyTypes } from '../constants';

const initialState = {
  accessToken: null,
  refreshToken: null,
  me: {},
  fetchingUserData: false,
  failedToFetchUserData: false,
  credentials: null,
  bioRecommendation: [],
  loggingIn: false,
  loginResponse: null,
  followedGoals: [],
  hasFetchedFollowedGoals: false,
  dailyTask: {},
  countryStates: [],
  states: [],
  selectedGoal: null,
  otpOrEmailData: null,
  accessControl: {},
  verifyOtpResponse: {
    type: 'phone',
    submitting: false,
    success: false,
    error: false,
    errorMsg: ''
  },
  isLoggedIn: false,
  sendEmailOtpStatus: {
    isLoading: false,
    errorMsg: ''
  },
  verifyEmailOtpStatus: {
    isLoading: false,
    errorMsg: ''
  },
  sendMobileOtpStatus: {
    isLoading: false,
    errorMsg: ''
  },
  verifyMobileOtpStatus: {
    isLoading: false,
    errorMsg: ''
  },
  isSubmittingFeedback: false,
  submitFeedbackFailed: false,
  latestStreak: {},
  renewalData: {},
  manageUpdates: [],
  geoLocation: {},
  referralStats: {},
  purchasedItems: {}
};

const updateCouponData = ({ result }) => ({ couponData: result });

const reducerMap = {
  [`${authTypes.GENERATE_CUSTOM_CODE}_SUCCESS`]: updateCouponData,
  [`${authTypes.GET_CUSTOM_CODE_DATA}_SUCCESS`]: updateCouponData,
  [`${authTypes.LOGIN}_REQUEST`]: () => ({ loggingIn: true }),
  [`${authTypes.LOGIN}_SUCCESS`]: ({ result }) => ({
    loginResponse: result,
    loggingIn: false
  }),
  [`${authTypes.LOGIN}_FAILURE`]: ({ error }) => ({
    loginResponse: error,
    loggingIn: false
  })
};

export default function authState(state = initialState, action) {
  switch (action.type) {
    case authTypes.AUTHENTICATE:
      return {
        ...state,
        ...action.payload
      };

    case authTypes.DEAUTHENTICATE:
      return {
        ...initialState
      };

    case `${authTypes.ME}_REQUEST`:
      return { ...state, fetchingUserData: true, failedToFetchUserData: false };
    case `${authTypes.ME}_FAILURE`:
      return { ...state, fetchingUserData: false, failedToFetchUserData: true };
    case `${authTypes.ME}_SUCCESS`:
      return { ...state, fetchingUserData: false };

    case `UPDATE_${authTypes.ME}`: {
      const me = action.data && Object.values(action.data)[0];
      return {
        ...state,
        me: {
          ...state.me,
          ...me
        },
        isLoggedIn: true
      };
    }

    case `${authTypes.FOLLOWED_GOALS}_REQUEST`:
      return {
        ...state,
        hasFetchedFollowedGoals: false
      };

    case `${authTypes.FOLLOWED_GOALS}_SUCCESS`:
      return {
        ...state,
        followedGoals: action.result,
        hasFetchedFollowedGoals: true
      };

    case `${authTypes.FETCH_STATES}_SUCCESS`:
      return {
        ...state,
        states: action.result || {} // gives Indian states with districts details
      };

    case `${authTypes.FETCH_COUNTRY_STATES}_SUCCESS`:
      return {
        ...state,
        countryStates: action.result || [] // gives states according to countries
      };

    case `${authTypes.FETCH_CREDENTIALS}_SUCCESS`:
      return {
        ...state,
        credentials: action.result || {}
      };

    case `${authTypes.FETCH_BIO_RECOMMENDATION}_SUCCESS`:
      return {
        ...state,
        bioRecommendation: action.result || {}
      };

    case authTypes.SELECTED_GOAL: {
      const { selectedGoal } = action;
      const status =
        selectedGoal?.goalLevelOnboarding?.status ||
        selectedGoal?.user_onboarding_status;
      return {
        ...state,
        selectedGoal,
        accessControl: {
          ...state.accessControl,
          user_onboarding_status: status
        }
      };
    }

    case `${topologyTypes.SAVE_PREFERENCE_FETCH_NEXT}_SUCCESS`: {
      const { isEditing } = action;
      return {
        ...state,
        accessControl: {
          ...state.accessControl,
          user_onboarding_status: isEditing
            ? state.accessControl?.user_onboarding_status
            : goalLevelOnboardingStatus.INCOMPLETE
        }
      };
    }

    case authTypes.SET_LOCAL_PHONE:
      return {
        ...state,
        localPhoneNumber: action.phone,
        localSelectedCountry: action.country
      };

    case authTypes.SET_LOCAL_EMAIL_NAME:
      return {
        ...state,
        localEmail: action.email,
        localName: action.name
      };

    case `${authTypes.RESEND_OTP}_SUCCESS`:
      return {
        ...state,
        otpOrEmailData: action.result
      };

    case authTypes.RESET_OTP_RESPONSE:
      return {
        ...state,
        otpOrEmailData: null
      };

    case authTypes.RESET_VERIFY_OTP_RESPONSE:
      return {
        ...state,
        verifyOtpResponse: {
          type: 'phone',
          submitting: false,
          success: false,
          error: false,
          errorMsg: ''
        }
      };

    case authTypes.RESET_NETWORK_CALLS_ERROR:
      return {
        ...state,
        sendEmailOtpStatus: initialState.sendEmailOtpStatus,
        verifyEmailOtpStatus: initialState.verifyEmailOtpStatus,
        sendMobileOtpStatus: initialState.sendMobileOtpStatus,
        verifyMobileOtpStatus: initialState.verifyMobileOtpStatus
      };

    case `${authTypes.SEND_MAIL_OTP}_REQUEST`:
      return {
        ...state,
        sendEmailOtpStatus: {
          isLoading: true,
          errorMsg: ''
        }
      };

    case `${authTypes.SEND_MAIL_OTP}_SUCCESS`:
      return {
        ...state,
        sendEmailOtpStatus: {
          isLoading: false,
          errorMsg: ''
        }
      };

    case `${authTypes.SEND_MAIL_OTP}_FAILURE`:
      return {
        ...state,
        sendEmailOtpStatus: {
          isLoading: false,
          errorMsg: action.error?.message
        }
      };

    case `${authTypes.VERIFY_MAIL_OTP}_REQUEST`:
      return {
        ...state,
        verifyEmailOtpStatus: {
          isLoading: true,
          errorMsg: ''
        }
      };

    case `${authTypes.VERIFY_MAIL_OTP}_SUCCESS`:
      return {
        ...state,
        verifyEmailOtpStatus: {
          isLoading: false,
          errorMsg: ''
        }
      };

    case `${authTypes.VERIFY_MAIL_OTP}_FAILURE`:
      return {
        ...state,
        verifyEmailOtpStatus: {
          isLoading: false,
          errorMsg: action.error?.message
        }
      };

    case `${authTypes.SEND_MOBILE_OTP}_REQUEST`:
      return {
        ...state,
        sendMobileOtpStatus: {
          isLoading: true,
          errorMsg: ''
        }
      };

    case `${authTypes.SEND_MOBILE_OTP}_SUCCESS`:
      return {
        ...state,
        sendMobileOtpStatus: {
          isLoading: false,
          errorMsg: ''
        }
      };

    case `${authTypes.SEND_MOBILE_OTP}_FAILURE`:
      return {
        ...state,
        sendMobileOtpStatus: {
          isLoading: false,
          errorMsg: action.error?.message
        }
      };

    case `${authTypes.VERIFY_MOBILE_OTP}_REQUEST`:
      return {
        ...state,
        verifyMobileOtpStatus: {
          isLoading: true,
          errorMsg: ''
        }
      };

    case `${authTypes.VERIFY_MOBILE_OTP}_SUCCESS`:
      return {
        ...state,
        verifyMobileOtpStatus: {
          isLoading: false,
          errorMsg: ''
        }
      };

    case `${authTypes.VERIFY_MOBILE_OTP}_FAILURE`:
      return {
        ...state,
        verifyMobileOtpStatus: {
          isLoading: false,
          errorMsg: action.error?.message
        }
      };

    case `${authTypes.SUBMIT_FEEDBACK}_REQUEST`:
      return {
        ...state,
        isSubmittingFeedback: true,
        submitFeedbackFailed: false
      };

    case `${authTypes.SUBMIT_FEEDBACK}_SUCCESS`: {
      const feedbackType = action?.feedbackType || PLUS_SUBSCRIPTION;
      // feedbackType could be plus_subscription or platform_subscription. in case of platform_subscription we are not updating plusSubscriptions array.
      if (feedbackType === PLATFORM_SUBSCRIPTION) {
        return {
          ...state,
          isSubmittingFeedback: false,
          submitFeedbackFailed: false
        };
      }
      return {
        ...state,
        isSubmittingFeedback: false,
        submitFeedbackFailed: false,
        me: {
          ...state.me,
          plusSubscriptions: [
            ...state.me.plusSubscriptions
              .slice()
              .filter(
                (subscription) =>
                  subscription.subscription?.value.uid !== action.goalUID
              ),
            {
              ...state.me.plusSubscriptions.find(
                ({ subscription }) => subscription?.value.uid === action.goalUID
              ),
              take_feedback: false
            }
          ]
        }
      };
    }

    case `${authTypes.SUBMIT_FEEDBACK}_FAILURE`:
      return {
        ...state,
        isSubmittingFeedback: false,
        submitFeedbackFailed: true
      };

    case `${authTypes.LATEST_STREAK}_SUCCESS`:
      return {
        ...state,
        latestStreak: action.result || {}
      };

    case `${authTypes.UPDATE_AVATAR}_SUCCESS`:
      return {
        ...state,
        me: {
          ...state.me,
          thumbnail: action.result.avatar
        }
      };

    case `${authTypes.FETCH_RENEWAL_TOUCHPOINT_DATA}_SUCCESS`:
      return {
        ...state,
        renewalData: {
          ...state.renewalData,
          [action.goalUID]: action.result
        }
      };

    case `${authTypes.FETCH_SETTINGS_MANAGE_UPDATES}_SUCCESS`:
      return {
        ...state,
        manageUpdates: action.result
      };

    case `${authTypes.CHANGE_MANAGE_UPDATE_PREFERENCES}_REQUEST`: {
      const updatesReq = { ...state.manageUpdates };
      updatesReq[action.blockType].preference[action.key] = action.flag;
      return {
        ...state,
        manageUpdates: updatesReq
      };
    }

    case `${authTypes.FETCH_REFERRAL_DETAILS}_SUCCESS`:
      return {
        ...state,
        referralStats: action.result
      };

    case `${authTypes.CHANGE_MANAGE_UPDATE_PREFERENCES}_SUCCESS`: {
      const updatesSuc = { ...state.manageUpdates };
      updatesSuc[action.blockType].preference[action.key] = action.flag;
      return {
        ...state,
        manageUpdates: updatesSuc
      };
    }

    case `${combatTypes.UPDATE_CONTEST_ONBOARDING_PREFERENCES}_SUCCESS`: {
      const { selectedClass, preferenceType } = action;
      return {
        ...state,
        accessControl: {
          ...state.accessControl,
          onboarding_pref_list: [
            {
              ...selectedClass,
              type: preferenceType,
              pref_type: preferenceType
            }
          ]
        }
      };
    }

    case topologyTypes.UPDATE_GLO_STATUS: {
      const { status } = action;
      return {
        ...state,
        accessControl: {
          ...state.accessControl,
          user_onboarding_status: status
        }
      };
    }

    case authTypes.PRE_PAYMENT_PLUS_SUBSCRIPTION_UIDS: {
      const { uids } = action;
      return {
        ...state,
        me: {
          ...state.me,
          prePaymentPlusSubscriptionUids: uids
        }
      };
    }

    case `${authTypes.FETCH_PURCHASED_ITEMS}_SUCCESS`: {
      return {
        ...state,
        purchasedItems: {
          ...state.purchasedItems,
          ...action.result
        }
      };
    }

    default: {
      const actionHandler = reducerMap[action.type];
      return actionHandler
        ? {
            ...state,
            ...actionHandler(action, state)
          }
        : state;
    }
  }
}
