/* eslint-disable import/prefer-default-export */
import { ENGLISH_PREFRENCE, NO_PREFRENCE } from '@constants/quiz';

export const DIFFICULTY_LEVEL = {
  VERY_EASY: {
    value: 1,
    text: 'VERY EASY',
    textColor: '#3fc5ff',
    bgColor: '#e8f8ff'
  },
  EASY: { value: 2, text: 'EASY', textColor: '#1fc0b6', bgColor: '#e0fffb' },
  MEDIUM: {
    value: 3,
    text: 'MEDIUM',
    textColor: '#fbac2d',
    bgColor: '#fff2d7'
  },
  DIFFICULT: {
    value: 4,
    text: 'DIFFICULT',
    textColor: '#ff9473',
    bgColor: '#ffeae1'
  },
  VERY_DIFFICULT: {
    value: 5,
    text: 'VERY DIFFICULT',
    textColor: '#ff6767',
    bgColor: '#ffe2e2'
  }
};

export const MODAL_TYPES = {
  FEEDBACK_MODAL: 'FEEDBACK_MODAL',
  FEEDBACK_OPTIONS_MODAL: 'FEEDBACK_OPTIONS_MODAL',
  REPORT_QUESTION: 'REPORT_QUESTION',
  DISCARD_CHANGES: 'DISCARD_CHANGES',
  PUBLISH_ERROR_MODAL: 'PUBLISH_ERROR_MODAL'
};

export const languageMap = {
  [ENGLISH_PREFRENCE.code]: ENGLISH_PREFRENCE.display_name,
  [NO_PREFRENCE.code]: NO_PREFRENCE.display_name,
  0: 'All'
};

export const autoDPPURLs = [
  '/educator',
  '/educator/schedule',
  '/educator/courses/[type]',
  '/educator/courses/[type]'
];

export const BREADCRUMBS_MAP = {
  title: 'Plus courses',
  as: '/educator/courses/plus-courses',
  href: '/educator/courses/[type]'
};

export const difficultyValues = [0, 1, 2, 3, 4, 5];

export const FILTER_ACTION = {
  SELECT_SUB_CONCEPT: 'SELECT_SUB_CONCEPT',
  SELECT_ALL_DIFFICULTY_LEVEL: 'SELECT_ALL_DIFFICULTY_LEVEL',
  SELECT_INDIVIDUAL_DIFFICULTY_LEVEL: 'SELECT_INDIVIDUAL_DIFFICULTY_LEVEL',
  SELECT_QUESTION_COUNT: 'SELECT_QUESTION_COUNT',
  RESET_FILTER_STATE: 'RESET_FILTER_STATE'
};

export const DIFFICULTY_TEXT = {
  1: 'Very easy',
  2: 'Easy',
  3: 'Medium',
  4: 'Difficult',
  5: 'Very Difficult'
};

export const DPP_STATS_MAP = {
  MINS: {
    icon: '/educator/auto-dpp/statistics/minutes-dpp.svg',
    color: '#EAF3FF',
    text: 'mins'
  },
  LIKED: {
    icon: '/educator/auto-dpp/statistics/like-dpp.svg',
    color: '#E9F9F4',
    text: 'liked'
  },
  PRACTICE_ATTEMPTS: {
    icon: '/educator/auto-dpp/statistics/practice-dpp.svg',
    color: '#F5F4FD',
    text: 'practiced'
  },
  ACCURACY: {
    icon: '/educator/auto-dpp/statistics/accuracy-dpp.svg',
    color: '#FFF7EB',
    text: 'accuracy'
  },
  DEFAULT: {
    icon: '/educator/auto-dpp/statistics/placeholder-dpp.svg',
    color: '#F2F5F7',
    text: 'Class statistics will appear here'
  }
};

export const DPP_BANNER_STATS_MAP = {
  PRACTICE_COUNT: {
    icon: '/educator/auto-dpp/statistics/practice-banner.svg',
    color: '#F5F4FD',
    text: 'Practice went live',
    iconWidth: 9.13,
    iconHeight: 11.14
  },
  ACCURACY: {
    icon: '/educator/auto-dpp/statistics/accuracy-dpp.svg',
    color: '#FFF7EB',
    text: 'Avg accuracy',
    iconWidth: 11,
    iconHeight: 13
  },
  LEARNERS_ATTEMPTED: {
    icon: '/educator/auto-dpp/statistics/attempts-dpp.svg',
    color: '#F0F7FF',
    text: 'Learners attempted',
    iconWidth: 13,
    iconHeight: 13
  }
};

export const SUB_CONCEPT_LEVEL = 23;

export const DPP_STATE = {
  NOT_AVAILABLE: -1,
  CREATED: 10,
  UNDER_REVIEW: 30,
  PUBLISHED: 40
};

export const AUTO_DPP_QUIZ_SECONDARY_TYPE = 6;

export const DPP_CARD_VISIBILITY_STATUS = {
  NOT_KNOWN: 'NOT_KNOWN',
  VISIBLE: true,
  NOT_VISIBLE: false
};

export const DROP_DOWN_MAX_HEIGHT = 650;

export const DROP_DOWN_BOTTOM_MARGIN = 30;

export const DROP_DOWN_STLYED_LIST_MARGIN = 20;

export const ALL_DIFFICULTY_VALUE = 0;

export const TOTAL_DIFFICULTIES_COUNT = 6;

export const MAX_DIFFICULTY_PLACEHOLDER_LENGTH = 20;

export const FILTER_ELE_TYPE = {
  CONCEPT: 'CONCEPT',
  SUB_CONCEPT: 'SUB_CONCEPT',
  SELECT_ALL: 'SELECT_ALL'
};

export const DIFFICULTY_DROPDOWN_MENU_WIDTH = 220;

export const SUB_CONCEPT_DROPDOWN_MENU_WIDTH = 418;

export const FILTER_CONTAINER_HEIGHT_MARGIN = 30;

export const SUB_CONCEPT_VIRTUAL_LIST_MARGIN = 100;

export const SUB_CONCEPT_NOT_FOUND_MARGIN = 120;

export const HEIGHT_FOR_CONCEPT = 45;

export const HEIGHT_FOR_SUB_CONCEPT = 35;

export const MAX_WIDTH_FOR_LIST = 295;
