export const STEPS_MAP = {
  QUESTION_TYPE: 1,
  SYLLABUS: 2
};

export const STEPS = [
  {
    step: STEPS_MAP.QUESTION_TYPE,
    name: 'Question type'
  },
  {
    step: STEPS_MAP.SYLLABUS,
    name: 'Syllabus'
  }
];

export const OPTIONS = [2, 3, 4, 5];

export const SELECTION_KEY = {
  SELECTED_SUB_CONCEPTS: 'selectedSubConcepts',
  SELECTED_CONCEPTS: 'selectedConcepts',
  SELECTED_TOPICS: 'selectedTopics'
};

export const QUESTION_COUNT_FILTER_OPTIONS = [
  {
    id: 'ALL',
    key: 'questionCountsPerDifficulty',
    name: 'All questions'
  },
  {
    id: 'PYQ',
    key: 'PYQQuestionCountsPerDifficulty',
    name: 'PYQ only'
  }
];

export const QUESTION_COUNT_FILTER_KEY = {
  ALL: 'ALL',
  PYQ: 'PYQ'
};
