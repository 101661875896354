import { educatorLiveMentoringTypes } from '@actions-constants/index';
import { getApiStatusHandlers, createReducer } from '@utils/reducerHelper';

const initialState = {
  apiStatus: {},
  slotPreferences: {},
  sessionPreferences: {},
  sessionDetails: {},
  feedbackSummary: {
    rating: 0,
    completed: 0,
    dislikes: 0,
    likes: 0,
    cancelled: 0,
    missed: 0,
    detailed_stats: {
      '': {
        '': {
          reasons: {},
          completed: 0,
          dislikes: 0,
          total_feedbacks: 0,
          educator_no_show: 0,
          learner_no_show: 0,
          likes: 0,
          total_hours_enabled: 0,
          educator_cancelled: 0,
          negative_feedback_count: 0,
          learner_cancelled: 0,
          no_show: 0,
          cancelled: 0
        }
      }
    }
  },
  callStatus: {
    calling_enabled: false,
    last_call_timestamp: null
  },
  learnerCallList: [],
  watchTimeInfo: {},
  practiceInfo: {},
  testInfo: {},
  liveClassInfo: {},
  previousNotes: {},
  addableNotesInfo: {},
  mentorNotesDetails: {}
};

const reducerMap = {
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.SLOT_PREFERENCES,
    onSuccess: ({ result, saveResponse }) =>
      saveResponse
        ? {
            slotPreferences: result || {}
          }
        : {}
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_PREFERENCES,
    onSuccess: ({ result }) => ({
      sessionPreferences: result || {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_SESSION_DETAILS,
    onSuccess: ({ result, sessionUID }, state) => ({
      sessionDetails: {
        ...state.sessionDetails,
        [sessionUID]: result
      }
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_FEEDBACK_SUMMARY,
    onSuccess: ({ result }) => ({
      feedbackSummary: result || {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_CALL_STATUS,
    onSuccess: ({ result }) => ({
      callStatus: result || {}
    }),
    onError: () => ({
      callStatus: {
        calling_enabled: false,
        last_call_timestamp: null
      }
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_LEARNER_CALL_LIST,
    onSuccess: ({ result }) => ({
      learnerCallList: result || {}
    }),
    onError: () => ({
      learnerCallList: []
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_LEARNER_WATCH_TIME,
    onSuccess: ({ result }) => ({
      watchTimeInfo: result?.results || {}
    }),
    onError: () => ({
      watchTimeInfo: {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_LEARNER_LIVE_CLASS_INFO,
    onSuccess: ({ result }) => ({
      liveClassInfo: result?.results || {}
    }),
    onError: () => ({
      liveClassInfo: {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_LEARNER_PRACTICE_INFO,
    onSuccess: ({ result }) => ({
      practiceInfo: result?.results || {}
    }),
    onError: () => ({
      practiceInfo: {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_LEARNER_TEST_INFO,
    onSuccess: ({ result }) => ({
      testInfo: result?.results || {}
    }),
    onError: () => ({
      testInfo: {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_PREVIOUS_NOTES,
    onSuccess: ({ result }) => ({
      previousNotes: result || {}
    }),
    onError: () => ({
      previousNotes: {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_ADDABLE_NOTES_INFO,
    onSuccess: ({ result }) => ({
      addableNotesInfo: result || {}
    }),
    onError: () => ({
      addableNotesInfo: {}
    })
  }),
  ...getApiStatusHandlers({
    actionConstant: educatorLiveMentoringTypes.FETCH_MENTOR_NOTES,
    onSuccess: ({ result, sessionUID }, state) => ({
      mentorNotesDetails: {
        ...state.mentorNotesDetails,
        [sessionUID]: result
      }
    })
  })
};

const educatorLiveMentoringReducer = createReducer(reducerMap, initialState);

export default educatorLiveMentoringReducer;
