class Queue {
  constructor() {
    this.startIndex = 0;
    this.list = [];
  }

  add(data) {
    if (Array.isArray(data)) {
      this.list.push(...data);
      return;
    }

    this.list.push(data);
  }

  count() {
    return this.list.length - this.startIndex;
  }

  get({ items = 1 }) {
    const data = [];
    const { startIndex } = this;
    const finalIndex = this.startIndex + items;

    for (let i = startIndex; i < finalIndex; i += 1) {
      data.push(this.list[i]);
      delete this.list[i];
    }

    this.startIndex = finalIndex;
    return data;
  }
}

export default Queue;
