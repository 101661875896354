import mergeDeep from '@utils/mergeDeep';
import { courseTypes } from '../constants';

const initialState = {
  data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case courseTypes.UPDATE_FEEDROWS:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    default:
      return state;
  }
};

export default reducer;
