import merge from 'lodash/merge';
import mergeDeep from '@utils/mergeDeep';
import { createReducer } from '@utils/reducerHelper';
import {
  itemTypes,
  classTypes,
  savedTypes,
  courseTypes,
  educatorTypes
} from '../constants';

const initialState = {
  data: {},
  isFetchingSchedule: false,
  scheduledClasses: {},
  calendarData: {},
  classes: {},
  freeRelatedLessons: {},
  isLessonsFetched: false,
  relatedCourses: {}
};

const reducerMap = {
  [`${classTypes.FETCH_CALENDAR_DATA}_SUCCESS`]: (
    { key, result, goalUid },
    state
  ) => {
    return {
      calendarData: {
        ...state.calendarData,
        [goalUid]: {
          ...state[goalUid],
          [key]: result
        }
      }
    };
  }
};

function sortScheduledClassItems(a, b) {
  return a.date - b.date;
}

const mapReducer = createReducer(reducerMap);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case itemTypes.UPDATE_ITEMS:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    case `${courseTypes.FETCH_FREE_COURSE_ITEM}_SUCCESS`:
      return {
        ...state,
        isLessonsFetched: true
      };

    case `${courseTypes.FETCH_FREE_COURSE_ITEM}_REQUEST`:
      return {
        ...state,
        isLessonsFetched: false
      };

    case `${classTypes.FETCH_CLASS}_SUCCESS`:
      return {
        ...state,
        classes: {
          ...state.classes,
          [action.uid]: action.result
        }
      };

    case `${itemTypes.FETCH_FREE_LESSON_INFO}_SUCCESS`:
      return {
        ...state,
        classes: {
          ...state.classes,
          [action.uid]: action.result
        }
      };

    case `${itemTypes.FETCH_FREE_LESSON_LIST}_SUCCESS`:
      return {
        ...state,
        freeRelatedLessons: {
          ...state.freeRelatedLessons,
          [action.uid]: action.result?.results
        }
      };

    case `${itemTypes.BOOKMARK_ITEM}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uid]: merge(state.data[action.uid], {
            isBookmarked: action.isBookmarked
          })
        }
      };

    case `${classTypes.FETCH_SIMILAR_AND_OTHER_COURSES}_SUCCESS`:
      return {
        ...state,
        similarAndOtherCourses: {
          ...state.similarAndOtherCourses,
          [action.uid]: action.result
        }
      };

    case `${classTypes.FETCH_SCHEDULED_CLASSES}_REQUEST`:
      return {
        ...state,
        isFetchingSchedule: true,
        // why are we not using action.key (goalUID) here but in success? Need to fix it - TODO
        scheduledClasses: action.resetWithoutEmpty ? {} : state.scheduledClasses
      };

    case `${classTypes.FETCH_SCHEDULED_CLASSES}_SUCCESS`: {
      let sortedClassResults = [...action.result.results];
      sortedClassResults = sortedClassResults.sort(sortScheduledClassItems);
      return {
        ...state,
        isFetchingSchedule: false,
        scheduledClasses: action.resetWithoutEmpty
          ? {
              [action.key]: action.result
            }
          : {
              ...state.scheduledClasses,
              [action.key]:
                state.scheduledClasses[action.key] && action.result
                  ? {
                      previous:
                        action.direction === 'down'
                          ? state.scheduledClasses[action.key].previous
                          : action.result.previous,
                      next:
                        action.direction === 'up'
                          ? state.scheduledClasses[action.key].next
                          : action.result.next,
                      results:
                        action.direction === 'down'
                          ? [
                              ...state.scheduledClasses[action.key].results,
                              ...sortedClassResults
                            ]
                          : [
                              ...sortedClassResults,
                              ...state.scheduledClasses[action.key].results
                            ]
                    }
                  : action.result
            }
      };
    }

    case `${classTypes.SEND_WATCHED}_SUCCESS`:
      return {
        ...state,
        data: {
          ...state.data,
          [action.uid]: mergeDeep(state.data[action.uid], {
            watched: true
          })
        }
      };

    case `${savedTypes.REMOVE_SAVED_ENTITY}_SUCCESS`: {
      const { objectUid } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [objectUid]: {
            ...(state.data[objectUid] || {}),
            isSaved: false
          }
        }
      };
    }

    case `${savedTypes.SAVE_ENTITY}_SUCCESS`: {
      const { objectUid } = action;
      return {
        ...state,
        data: {
          ...state.data,
          [objectUid]: {
            ...(state.data[objectUid] || {}),
            isSaved: true
          }
        }
      };
    }

    case `${educatorTypes.SET_PRACTICE_CTA}`: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.classUID]: {
            ...state.data[action.classUID],
            practiceDpp: { cta: 'Add practice' }
          }
        }
      };
    }
    default:
      return mapReducer(state, action);
  }
};

export default reducer;
