import { driveTypes } from '../constants';

const initialState = {
  content: [],
  ancestors: [],
  currentItem: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${driveTypes.CREATE_FOLDER}_REQUEST`:
      return {
        ...state
      };

    case `${driveTypes.CREATE_FOLDER}_SUCCESS`:
      return {
        ...state
      };

    case `${driveTypes.CREATE_FOLDER}_FAILURE`:
      return {
        ...state
      };

    case `${driveTypes.FETCH_ANCESTERS}_REQUEST`:
      return {
        ...state,
        ancestors: []
      };

    case `${driveTypes.FETCH_ANCESTERS}_SUCCESS`: {
      const result = action?.result;
      if (!result || !Array.isArray(result)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        ancestors: action.result.sort((a, b) => a.level - b.level)
      };
    }

    case `${driveTypes.FETCH_ANCESTERS}_FAILURE`:
      return {
        ...state,
        ancestors: action?.error
      };

    case `${driveTypes.FETCH_DRIVE_ITEM_DETAILS}_REQUEST`:
      return {
        ...state,
        currentItem: {}
      };

    case `${driveTypes.FETCH_DRIVE_ITEM_DETAILS}_SUCCESS`: {
      return {
        ...state,
        currentItem: action?.result
      };
    }

    case `${driveTypes.FETCH_DRIVE_ITEM_DETAILS}_FAILURE`:
      return {
        ...state,
        currentItem: action?.error
      };

    default:
      return state;
  }
};

export default reducer;
