import { storeTypes } from '@actions-constants/index';
import mergeDeep from '@utils/mergeDeep';

const initialState = {};

const reducerMap = {
  [storeTypes.UPDATE_STORE]: (action, state) => {
    return {
      ...state,
      data: mergeDeep(action.data, state.data, 2)
    };
  }
};

const storeReducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];

  return actionHandler
    ? {
        ...state,
        ...actionHandler(action, state)
      }
    : state;
};

export default storeReducer;
