const FormSectionsEducator = [
  {
    rank: 1,
    name: 'Academics'
  },
  {
    rank: 2,
    name: 'Experience'
  },
  {
    rank: 3,
    name: 'Complete your KYC'
  },
  {
    rank: 4,
    name: 'Billing information'
  }
];

const FormSectionsVendor = [
  {
    rank: 1,
    name: ''
  },
  {
    rank: 2,
    name: ''
  },
  {
    rank: 3,
    name: 'Complete your KYC'
  },
  {
    rank: 4,
    name: 'Billing information'
  }
];

const AcademicQuestions = {
  display_style: 'Vertical',
  choices: [
    {
      rank: 1,
      content: 'High school diploma',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/highSchoolChoice.png'
    },
    {
      rank: 2,
      content: "Bachelor's degree",
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/batchDegreeChoice.png'
    },
    {
      rank: 3,
      content: "Master's degree",
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/mastersDegreeChoice.png'
    },
    {
      rank: 4,
      content: 'Professional degree',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/professionalDegreeChoice.png'
    },
    {
      rank: 5,
      content: 'Doctorate degree',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/doctorateChoice.png'
    },
    {
      rank: 6,
      content: 'No formal education',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/noFormalEducationChoice.png'
    },
    {
      rank: 7,
      content: 'Other',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/otherChoice.png'
    }
  ]
};

const YearOfExperienceQuestions = {
  display_style: 'Horizontal',
  choices: [
    {
      rank: 1,
      content: 'Teaching for the \\n First time',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/years-of-exp01.svg'
    },
    {
      rank: 2,
      content: 'Less than \\n 5 years',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/years-of-exp02.svg'
    },
    {
      rank: 3,
      content: 'Between \\n 5-10 years',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/years-of-exp03.svg'
    },
    {
      rank: 4,
      content: 'More than \\n 10 years',
      icon: 'https://static.uacdn.net/thumbnail/form-question-choice-icons/years-of-exp04.svg'
    }
  ]
};

const AchievementsQuestions = {
  key: 'achievements',
  display_style: 'Undefined',
  question_type: 'Text Input',
  help_text: null,
  extra_details: {
    placeholder: "Eg. I've achieved...",
    additional_text: null,
    is_multiline: true
  },
  choices: []
};

const KYCImageType = {
  PAN_CARD: 14,
  AADHAR_CARD_FRONT: 15,
  AADHAR_CARD_BACK: 16
};

const KYCQuestions = [
  {
    key: 'first_name',
    title: 'First Name',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter your first name',
      is_multiline: false
    }
  },
  {
    key: 'last_name',
    title: 'Last Name',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter your last name',
      is_multiline: false
    }
  },
  {
    key: 'date_of_birth',
    title: 'DOB',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'dd/mm/yy',
      is_multiline: false
    }
  },
  {
    key: 'pan',
    title: 'PAN Number',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter PAN number',
      is_multiline: false
    }
  },
  {
    key: 'address',
    title: 'Address as per Aadhar',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter your address',
      is_multiline: true
    }
  },
  {
    title: 'Upload your PAN card (front)',
    key: KYCImageType.PAN_CARD,
    display_style: 'Undefined',
    question_type: 'File Upload',
    help_text: null
  },
  {
    title: 'Upload your Aadhaar card (front)',
    key: KYCImageType.AADHAR_CARD_FRONT,
    display_style: 'Undefined',
    question_type: 'File Upload',
    help_text: null
  },
  {
    title: 'Upload your Aadhaar card (back)',
    key: KYCImageType.AADHAR_CARD_BACK,
    display_style: 'Undefined',
    question_type: 'File Upload',
    help_text: null
  }
];

const HAS_GST_TYPES = {
  YES: 1,
  NO: 0
};

const GST_TYPES = {
  REGULAR: 1,
  COMPOSITION: 2
};

const BillingInfoQuestions = [
  {
    key: 'name',
    title: 'Account holder name',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter account holder name',
      is_multiline: false
    }
  },
  {
    key: 'account',
    title: 'Account number',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter account number',
      is_multiline: false
    }
  },
  {
    key: 're-account',
    title: 'Re-enter account number',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please re-enter account number',
      is_multiline: false
    }
  },
  {
    key: 'ifsc',
    title: 'IFSC code',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter IFSC code',
      is_multiline: false
    }
  },
  {
    key: 'gstin',
    title: 'GSTIN number',
    display_style: 'Undefined',
    question_type: 'Text Input',
    extra_details: {
      placeholder: 'Please enter GSTIN number (optional)',
      is_multiline: false
    }
  },
  {
    key: 'has-gstin',
    title: 'Do you have a registered GST number?',
    display_style: 'Undefined',
    question_type: 'Radio',
    extra_details: {
      options: [
        {
          label: 'Yes',
          value: HAS_GST_TYPES.YES
        },
        {
          label: 'No',
          value: HAS_GST_TYPES.NO
        }
      ]
    }
  },
  {
    key: 'gst-type',
    title: 'Select a type of GST',
    display_style: 'Undefined',
    question_type: 'Radio',
    extra_details: {
      options: [
        {
          label: 'Regular tax payer',
          value: 1
        },
        {
          label: 'Composition',
          value: 2
        }
      ]
    }
  }
];

const BankDetailsPayload = [
  {
    key: 'bank',
    content: ''
  },
  {
    key: 'branch',
    content: ''
  },
  {
    key: 'ifsc',
    content: ''
  }
];

const PartnerType = {
  EDUCATOR: 1,
  VENDOR: 2
};

const KYCState = {
  UNDER_REVIEW: 2,
  VERIFIED: 3,
  REJECTED: 4
};

const OnboardingFlow = {
  INVITED: 10,
  KYC_IN_PROCESS: 20,
  KYC_DONE: 30,
  KYC_REJECTED: 40,
  BILLING_SUBMITTED: 50
};

const checkIfOnboardingIsInProgress = (kycState, onboardingState) => {
  return (
    (onboardingState === OnboardingFlow.INVITED ||
      onboardingState === OnboardingFlow.KYC_IN_PROCESS ||
      kycState?.state === KYCState.REJECTED) &&
    onboardingState !== OnboardingFlow.BILLING_SUBMITTED &&
    onboardingState !== OnboardingFlow.KYC_DONE
  );
};

const checkIfKYCIsInProgressOrRejected = (kyc_state, onboardingState) => {
  return (
    kyc_state === null ||
    kyc_state.state === KYCState.REJECTED ||
    (kyc_state.state === KYCState.UNDER_REVIEW &&
      onboardingState !== OnboardingFlow.KYC_DONE)
  );
};

const checkIfKYCIsVerified = (kyc_state, onboardingState) => {
  return (
    kyc_state?.state === KYCState.VERIFIED ||
    onboardingState === OnboardingFlow.KYC_DONE
  );
};

const KYCFormConstants = {
  kycQuestionsLength: KYCQuestions.length,
  textFieldQuestionsLength: KYCQuestions.length / 2
};

export {
  FormSectionsEducator,
  FormSectionsVendor,
  AcademicQuestions,
  YearOfExperienceQuestions,
  AchievementsQuestions,
  KYCQuestions,
  BillingInfoQuestions,
  BankDetailsPayload,
  PartnerType,
  KYCState,
  OnboardingFlow,
  KYCImageType,
  KYCFormConstants,
  checkIfKYCIsInProgressOrRejected,
  checkIfKYCIsVerified,
  checkIfOnboardingIsInProgress,
  HAS_GST_TYPES,
  GST_TYPES
};
