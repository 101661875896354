import { createReducer } from '@utils/reducerHelper';
import { oneToOneLivementoring } from '@actions-constants/index';
import { apiStatus } from '@constants/index';

const initialState = {
  sessionMetaData: {},
  sessionDetails: {},
  onBoardingPreferences: {}
};

const reducerMap = {
  [`${oneToOneLivementoring.FETCH_SESSION_DETAILS}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => {
    return {
      ...state,
      sessionDetails: {
        ...state.sessionDetails,
        [action.sessionUID]: { ...action.result }
      }
    };
  },
  [`${oneToOneLivementoring.FETCH_SESSIONS_META}_${apiStatus.SUCCESS}`]: (
    action,
    state
  ) => ({
    ...state,
    sessionMetaData: {
      ...state.sessionMetaData,
      [action.goalUID]: action.result
    }
  }),
  [`${oneToOneLivementoring.FETCH_LMP_ONBOARDING_PREFERENCES}_${apiStatus.SUCCESS}`]:
    (action, state) => {
      return {
        ...state,
        onBoardingPreferences: {
          ...state.onBoardingPreferences,
          [action.goalUID]: action.result
        },
        selectedOnboardingLanguage: {
          ...state.selectedOnboardingLanguage,
          // code: action.result.selectedLanguages[0]
          code: null
        },
        selectedOnboardingGender: {
          ...state.selectedOnboardingGender,
          // genderCode: action.result.selectedGender
          genderCode: null
        }
      };
    }
};

const oneToOneLiveMentorShipReducer = createReducer(reducerMap, initialState);

export default oneToOneLiveMentorShipReducer;
