import { normalize } from 'normalizr';
import fetch from '@utils/api-helper/fetchWithoutErrorHandling';
import getHeaders from '@utils/api-helper/getHeaders';
import APICallErrorHandler from '@utils/api-helper/fetchErrorHandler';
import { requestStatusStore } from '@stores/requestStatus';
import { analytics } from '../constants';

const getURL = (endpoint, params) => {
  if (params) {
    const queryString = encodeURIComponent(JSON.stringify(params));
    return `${endpoint}?${queryString}`;
  }

  return endpoint;
};

const callAPIMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (action.type === analytics.ANALYTICS && action.entityName) {
      return next(action);
    }

    if (!action || !action.endpoint || !action.method) {
      return next(action);
    }

    const {
      endpoint,
      type,
      method,
      data,
      params,
      schema,
      entityType,
      directPaginatedEntity,
      processData,
      isFormData,
      callback,
      headers: overrideHeaders,
      skipLoader,
      useSearchToken = false,
      useJWTToken,
      useAuthToken = true,
      ...rest
    } = action;

    const url = getURL(endpoint, params);

    const headers = getHeaders({
      url,
      getState,
      headersOptions: {
        useAuthToken,
        useSearchToken,
        useJWTToken,
        isFormData
      },
      headers: overrideHeaders
    });

    if (!skipLoader) {
      requestStatusStore.getState().setFetching(true);
    }

    next({ ...rest, type: `${type}_REQUEST` });

    const actionPromise = fetch(url, {
      requestType: method?.toUpperCase?.() || 'GET',
      isFormData,
      body: data,
      headers
    });

    return actionPromise
      .then((result) => {
        if (callback) callback(result);
        if (!schema)
          return next({
            ...rest,
            result,
            type: `${type}_SUCCESS`
          });
        let formattedResults = result;

        // result preprocessing before normalize
        if (processData) {
          formattedResults = processData(formattedResults);
        }
        if (entityType) {
          if (directPaginatedEntity) {
            formattedResults = {
              ...formattedResults,
              results: formattedResults.results.map((item) => ({
                [entityType]: item
              }))
            };
          } else if (Array.isArray(formattedResults)) {
            formattedResults = formattedResults.map((item) => ({
              [entityType]: item
            }));
          } else {
            formattedResults = {
              [entityType]: formattedResults
            };
          }
        }
        const normalizedData = normalize(formattedResults, schema);
        next({ ...rest, entities: normalizedData.entities });
        return next({
          ...rest,
          result: normalizedData.result,
          type: `${type}_SUCCESS`,
          rawResponse: result
        });
      })
      .catch((error) =>
        APICallErrorHandler({
          error,
          dispatch,
          getState,
          action,
          rest,
          endpoint: url,
          source: 'redux-fetcher',
          headers,
          options: {
            useAuthToken,
            useJWTToken,
            useSearchToken,
            isFormData,
            requestType: method
          }
        })
      )
      .finally(() => {
        if (!skipLoader) {
          requestStatusStore.getState().setFetching(false);
        }
      });
  };

export default callAPIMiddleware;
