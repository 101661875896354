/* eslint-disable max-lines */
import sortBy from 'lodash/sortBy';
import { emptyObject } from '@constants/empty';
import { getDateShort, getTime } from '@utils/dates';
import { QUESTION_TYPES, ENGLISH_PREFRENCE } from '@constants/quiz';
import {
  ALL_DIFFICULTY_VALUE,
  DIFFICULTY_TEXT,
  DROP_DOWN_BOTTOM_MARGIN,
  DROP_DOWN_MAX_HEIGHT,
  DROP_DOWN_STLYED_LIST_MARGIN,
  FILTER_CONTAINER_HEIGHT_MARGIN,
  FILTER_ELE_TYPE,
  SUB_CONCEPT_LEVEL,
  TOTAL_DIFFICULTIES_COUNT
} from './constants';

export const getSubConceptInfo = (topology) => {
  if (!topology?.level) {
    return emptyObject;
  }
  if (topology?.level === SUB_CONCEPT_LEVEL) {
    return topology;
  }
  return getSubConceptInfo(topology?.parent ?? emptyObject);
};

export const getSubConceptUID = (topology) => {
  return getSubConceptInfo(topology)?.uid ?? '';
};

export const compareQuestionOrder = (arr1, arr2) => {
  for (let index = 0; index < arr1.length; index += 1) {
    if (arr1[index].uid !== arr2[index].uid) {
      return true;
    }
  }

  return false;
};

export const beforeUnloadRegistrar = (e) => {
  e.preventDefault();
  e.returnValue = '';
};

export const getQuestionOrderMap = (questionOrder) => {
  const map = [];
  questionOrder?.forEach((question, index) => {
    map.push({
      uid: question.uid,
      rank: index + 1
    });
  });
  return map;
};

export const getTimeString = (startTime) => {
  if (startTime) {
    return `${getDateShort(startTime)}, ${getTime(startTime)}`;
  }
};

export const getInitialselectedSubConceptsStates = (subConcepts = []) => {
  const selectedSubConcepts = {};
  let level = 0;

  subConcepts?.forEach((concept) => {
    concept?.children?.forEach((subConcept) => {
      if (subConcept?.isSelected) {
        selectedSubConcepts[subConcept?.id] = {
          rank: level,
          name: subConcept.name
        };
      }
      level += 1;
    });
  });
  return selectedSubConcepts;
};

export const getInitialSelectedDifficulty = (difficulty = []) => {
  const selectedDifficulty = [];
  let count = 0;

  difficulty?.values?.forEach(({ code, isSelected }) => {
    if (isSelected) {
      selectedDifficulty.push(code);
      count += 1;
    }
  });
  if (count === 5) {
    selectedDifficulty.unshift(0);
  }
  return selectedDifficulty;
};

export const addFilterDifficulty = (selectedCode, difficulty = []) => {
  const difficultyWithSelectedCode = difficulty.concat(selectedCode);
  if (difficultyWithSelectedCode.length === TOTAL_DIFFICULTIES_COUNT - 1) {
    difficultyWithSelectedCode.push(ALL_DIFFICULTY_VALUE);
  }
  return difficultyWithSelectedCode.sort();
};

export const removeSubConcept = (subConcepts, uid) => {
  const { [uid]: value, ...modifiedSubConcepts } = subConcepts;
  return modifiedSubConcepts;
};

export const getTotalSubConceptCount = (concepts = []) => {
  let count = 0;
  concepts?.forEach((concept) => {
    count += concept.children?.length;
  });
  return count;
};

export const getSubConceptsWithRank = (concepts = []) => {
  let level = 0;
  return concepts?.map((concept) => {
    return {
      ...concept,
      children: concept.children?.map((subConcept) => {
        const subConceptWithRank = {
          ...subConcept,
          rank: level
        };
        level += 1;
        return subConceptWithRank;
      })
    };
  });
};

export const getQuestionType = (type) => {
  return QUESTION_TYPES.find((obj) => obj.value === type)?.label ?? 'NA';
};

export const FILTER_TYPE = {
  DIFFICULTY: 'Difficulty',
  SUB_CONCEPTS: 'Sub-concepts',
  NUMBER_OF_QUESTIONS: 'Number of Questions'
};

export const getFilterParams = (classUID, selectedFilters) => {
  const {
    selectedCount = 5,
    selectedSubConcepts = {},
    selectedDifficulty = []
  } = selectedFilters;

  return {
    class_id: classUID,
    difficulty_level: selectedDifficulty?.filter((code) => code !== 0),
    sub_concepts: Object.keys(selectedSubConcepts).map((id) =>
      parseInt(id, 10)
    ),
    language_code: ENGLISH_PREFRENCE.code,
    question_numbers: selectedCount
  };
};

export const updateQuestionOnShuffle = (
  state,
  questionList = [],
  updatedQuestion,
  oldQuestionUID
) => {
  if (updatedQuestion) {
    const indexToReplace = questionList.findIndex(
      (question) => question.uid === oldQuestionUID
    );

    return questionList.map((question, index) => {
      if (index === indexToReplace) {
        return { ...updatedQuestion, disabled: state };
      }
      return { ...question, disabled: state };
    });
  }
  return questionList.map((question) => ({ ...question, disabled: state }));
};

export const getDPPDifficultyStringList = (list = []) =>
  list.map((value) => DIFFICULTY_TEXT?.[value]);

export const getDropDownHeight = (
  target,
  bottomMargin = DROP_DOWN_BOTTOM_MARGIN,
  maxHeight = DROP_DOWN_MAX_HEIGHT
) => {
  const element = target?.getBoundingClientRect();
  if (element) {
    if (window?.innerHeight - element?.bottom >= maxHeight) {
      return maxHeight;
    }
    return window?.innerHeight - element?.bottom - bottomMargin;
  }
  return 0;
};

export const getDropDownStyledListHeight = (dropDownHeight) => {
  return dropDownHeight - DROP_DOWN_STLYED_LIST_MARGIN;
};

export const getSubConceptListNormalized = (list = []) => {
  let conceptList = [];
  const subConceptMap = {};

  list.forEach((concept = {}) => {
    const subConceptList = [];

    concept.children?.forEach((subConcept = {}) => {
      const { id, uid, name, is_selected: isSelected } = subConcept;
      const obj = {
        id,
        uid,
        name,
        isSelected,
        type: FILTER_ELE_TYPE.SUB_CONCEPT,
        count: false,
        conceptUID: concept.uid
      };
      subConceptMap[uid] = obj;
      subConceptList.push(obj);
    });

    const { id, uid, title } = concept;
    const obj = {
      title,
      id,
      uid,
      type: FILTER_ELE_TYPE.CONCEPT,
      subConceptList: sortBy(subConceptList, ['name']).map((sub) => sub?.uid)
    };
    conceptList.push(obj);
  });

  conceptList = sortBy(conceptList, ['title']);

  return {
    conceptList,
    subConceptMap
  };
};

export const toggleIsSelected = (
  subConceptMap = {},
  subConceptUID = '',
  state
) => {
  return {
    ...subConceptMap,
    [subConceptUID]: {
      ...subConceptMap[subConceptUID],
      isSelected: state
    }
  };
};

export const getComputedList = ({
  conceptList = [],
  subConceptMap = {},
  expand = false
}) => {
  const resutlList = [];

  conceptList.forEach((concept) => {
    const subConcepts = [];
    const selectedSubConcepts = [];

    concept?.subConceptList?.forEach((uid) => {
      const subConcept = subConceptMap[uid];
      subConcepts.push(subConcept);
      if (subConcept?.isSelected) {
        selectedSubConcepts.push(subConcept);
      }
    });

    const selectAllObject = {
      key: `${concept.uid}-select-all`,
      label: 'Select All',
      conceptUID: concept.uid,
      subConceptList: concept?.subConceptList,
      isAllSelected:
        selectedSubConcepts.length === concept?.subConceptList?.length
    };

    if (expand) {
      if (selectedSubConcepts.length) {
        if (selectAllObject.isAllSelected) {
          resutlList.push(concept, selectAllObject, ...selectedSubConcepts);
        } else {
          resutlList.push(concept, ...selectedSubConcepts);
        }
      }
    } else {
      resutlList.push(concept, selectAllObject, ...subConcepts);
    }
  });

  return resutlList;
};

export const getUIDAndTypeObject = (ele = {}) => {
  return {
    type: ele.type,
    uid: ele.uid,
    ...(ele.type === FILTER_ELE_TYPE.SUB_CONCEPT && {
      conceptUID: ele.conceptUID
    })
  };
};

const getFilteredListForConcept = ({
  searchList = [],
  expand = false,
  subConceptMap = {},
  ele = {}
}) => {
  const subConcepts = [];
  const filteredListForConcept = searchList.slice(1).filter((element) => {
    let check =
      element.type === FILTER_ELE_TYPE.SUB_CONCEPT &&
      element.conceptUID === ele.uid;

    check = expand ? check && subConceptMap[element.uid].isSelected : check;

    if (check) {
      subConcepts.push(subConceptMap[element.uid]);
      return false;
    }
    return true;
  });

  return { subConcepts, filteredListForConcept };
};

const getFilteredListForSubConcept = ({
  subConceptMap = {},
  ele = {},
  searchList = [],
  expand = false,
  getConcept = () => {}
}) => {
  const subConcepts = [subConceptMap[ele.uid]];
  let concept;

  const filteredListForSubConcept = searchList.slice(1).filter((element) => {
    let check =
      element.type === FILTER_ELE_TYPE.SUB_CONCEPT &&
      element.conceptUID === ele.conceptUID;

    check = expand ? check && subConceptMap[element.uid].isSelected : check;

    if (check) {
      subConcepts.push(subConceptMap[element.uid]);
      return false;
    }
    if (element.uid === ele.conceptUID) {
      concept = getConcept(element.uid);
      return false;
    }
    return true;
  });
  if (!concept) {
    concept = getConcept(ele.conceptUID);
  }
  return { subConcepts, concept, filteredListForSubConcept };
};

const getResultListForConcept = ({
  expand = false,
  subConcepts = [],
  conceptList = [],
  ele = {},
  subConceptMap = {},
  getConcept = () => {}
}) => {
  const result = [];

  if (expand) {
    if (subConcepts.length) {
      result.push(conceptList.find((con) => con.uid === ele.uid));
      result.push(...subConcepts);
      return result;
    }

    const list =
      conceptList.find((con) => con.uid === ele.uid)?.subConceptList || [];

    const selectedSubConcepts = list
      .map((uid) => subConceptMap[uid])
      .filter((sub) => sub.isSelected);

    if (selectedSubConcepts.length) {
      result.push(conceptList.find((con) => con.uid === ele.uid));
      result.push(...selectedSubConcepts);
    }

    return result;
  }

  result.push(getConcept(ele.uid));

  if (subConcepts.length) {
    result.push(...subConcepts);
    return result;
  }

  const list = getConcept(ele.uid)?.subConceptList || [];
  result.push(...list.map((uid) => subConceptMap[uid]));

  return result;
};

export const getComputedListFromSearch = ({
  conceptList = [],
  subConceptMap = {},
  searchList = [],
  expand = false,
  prev = []
}) => {
  const getConcept = (uid) =>
    conceptList.find((concept) => concept.uid === uid);

  if (searchList.length) {
    const result = [...prev];
    const ele = searchList[0];
    let filteredList = [];

    if (ele.type === FILTER_ELE_TYPE.CONCEPT) {
      const { subConcepts, filteredListForConcept } = getFilteredListForConcept(
        {
          searchList,
          expand,
          subConceptMap,
          ele
        }
      );

      filteredList = filteredListForConcept;

      const list = getResultListForConcept({
        expand,
        subConcepts,
        conceptList,
        ele,
        subConceptMap,
        getConcept
      });

      result.push(...list);
    } else {
      const { subConcepts, concept, filteredListForSubConcept } =
        getFilteredListForSubConcept({
          subConceptMap,
          ele,
          searchList,
          expand,
          getConcept
        });

      filteredList = filteredListForSubConcept;

      result.push(concept);
      result.push(...subConcepts);
    }
    return getComputedListFromSearch({
      conceptList,
      subConceptMap,
      searchList: filteredList || [],
      expand,
      prev: result
    });
  }
  return prev;
};

export const getFilterContainerHeight = (height) =>
  height - FILTER_CONTAINER_HEIGHT_MARGIN;

export const patchSubConceptCountInfo = (
  subConceptMap = {},
  countList = []
) => {
  const updatedSubConceptMap = { ...subConceptMap };
  if (countList.length) {
    countList.forEach((countObj) => {
      updatedSubConceptMap[countObj?.topology_uid].count =
        countObj?.question_counts_per_difficulty;
    });
    return updatedSubConceptMap;
  }
  return updatedSubConceptMap;
};

const defaultCount = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

export const getSelectedDifficultyCount = (selectedList = []) => {
  const subConcepts = selectedList.filter(
    (item) => item.type === FILTER_ELE_TYPE.SUB_CONCEPT
  );

  const count = subConcepts.reduce((acc, item) => {
    const keys = Object.keys(item.count);
    if (keys.length) {
      return keys.reduce(
        (prev, key) => {
          if (prev[key]) {
            return { ...prev, [key]: prev[key] + item.count[key] };
          }
          return { ...prev, [key]: item.count[key] };
        },
        { ...acc }
      );
    }
    return acc;
  }, {});

  return { ...defaultCount, ...count };
};

export const getTextWidth = (text = '') => {
  const font = '400 14px AvertaStd,-apple-system,BlinkMacSystemFont,sans-serif';
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const { width } = context.measureText(text);
  return width;
};

export const getExpiryDateForNextDay = (
  hours = 0,
  min = 0,
  sec = 0,
  ms = 0
) => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  today.setHours(hours, min, sec, ms);
  return new Date(today).getTime();
};
