import { programmeTypes } from '@constants/index';

// this is for radio button
export const COURSE_TYPE_ONLINE_SPECIAL_CLASS = 'online';
export const COURSE_TYPE_ONLINE_SPECIAL_CLASS_WITH_EXTERNAL = 'online_external';

export const SPECIAL_CLASS_PROGRAMME_TYPE_MAPPINGS = {
  [COURSE_TYPE_ONLINE_SPECIAL_CLASS]: programmeTypes.COURSE,
  [COURSE_TYPE_ONLINE_SPECIAL_CLASS_WITH_EXTERNAL]:
    programmeTypes.ONLINE_WITH_EXTERNAL_STREAM
};
