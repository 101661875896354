import mergeDeep from '@utils/mergeDeep';
import { doubtTypes } from '../constants';

const initialState = {
  data: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case doubtTypes.UPDATE_DOUBTSOLUTIONS:
      return {
        ...state,
        data: mergeDeep(action.data, state.data, 2)
      };

    default:
      return state;
  }
};

export default reducer;
